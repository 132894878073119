import { createContext, useState, useEffect, useCallback, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "./userContext";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
   const { user } = useContext(UserContext);
   const [products, setProducts] = useState([]);
   const [filteredProducts, setFilteredProducts] = useState([]);
   const [categorySelected, setCategorySelected] = useState({});
   const [subcategoriesSelected, setSubcategoriesSelected] = useState([]);
   const [ingredientsSelected, setIngredientsSelected] = useState([]);
   const [priceMax, setPriceMax] = useState(0);
   const [priceMaxSelected, setPriceMaxSelected] = useState(0);
   const [subcategories, setSubcategories] = useState([]);
   const [ingredients, setIngredients] = useState([]);
   const [productsSelected, setProductsSelected] = useState([]);
   const [sortKey, setSortKey] = useState("highlighted");
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   const loadProductsData = useCallback(async () => {
      try {
         if (!categorySelected.id) {
            return;
         }
         setIsLoading(true);
         const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/categories/${categorySelected.id}/products`,
         );

         if (!response.ok) throw new Error(response.statusText);

         const data = await response.json();
         setIsLoading(false);
         setProducts(data);
         setError(null);
         // Reconstruit les différents filtres
         let subcategoriesArray = [];
         let ingredientsArray = [];

         for (let product of data) {
            if (
               subcategoriesArray.find((subcategory) => {
                  return subcategory.id === product.ProductSubcategory.id;
               }) === undefined
            ) {
               subcategoriesArray.push(product.ProductSubcategory);
            }
            if (!product.enabled && (!user || (user && !user.isAdmin))) {
               continue;
            }
            for (let productIngredient of product.Ingredients) {
               if (
                  ingredientsArray.find((ingredient) => {
                     return ingredient.id === productIngredient.id;
                  }) === undefined
               ) {
                  ingredientsArray.push(productIngredient);
               }
            }
         }
         subcategoriesArray.sort((a, b) => a.title.localeCompare(b.title));
         ingredientsArray.sort((a, b) => a.title.localeCompare(b.title));
         setSubcategories(subcategoriesArray);
         setIngredients(ingredientsArray.filter(ingredient => ingredient.visible));

         // Cherche le prix le plus élevé
         if (data.length > 0) {
            const prices = data.map((item) => item.price);
            const price = Math.ceil(Math.max(...prices));
            setPriceMax(price);
            setPriceMaxSelected(price);
         } else {
            setPriceMax(0);
            setPriceMaxSelected(0);
         }
      } catch (error) {
         setError(`${error} Could not Fetch Data `);
         setIsLoading(false);
      }
   }, [categorySelected, user]);

   const resetFilter = useCallback(() => {
      setProductsSelected([]);
      setSubcategoriesSelected([]);
      setIngredientsSelected([]);
      setPriceMaxSelected(priceMax);
   }, [priceMax]);

   const filterNumber = useMemo(() => {
      return subcategoriesSelected.length + ingredientsSelected.length;
   }, [subcategoriesSelected, ingredientsSelected]);

   // Tri et filtre les données
   useEffect(() => {
      // filtre les produits (si pas de sous catégories et d'ingrédients séléctionnés : on retourne tout / sinon on filtre)
      const filtered = products.filter((product) => {
         const ingredientsIds = product.Ingredients.map((ingredient) => ingredient.id);
         return (
            (subcategoriesSelected.length === 0 ||
               subcategoriesSelected.includes(product.ProductSubcategory.id)) &&
            (ingredientsSelected.length === 0 ||
               ingredientsIds.some((ingredientId) => ingredientsSelected.includes(ingredientId))) &&
            product.price <= priceMaxSelected &&
            (user?.isAdmin || (!user?.isAdmin && product.enabled))
         );
      });

      // trie les produits
      switch (sortKey) {
         case "createdAt": {
            filtered.sort((a, b) => {
               const da = new Date(a.createdAt),
                  db = new Date(b.createdAt);
               return db - da;
            });
            break;
         }
         case "price": {
            filtered.sort((a, b) => {
               if (a.price < b.price) {
                  return -1;
               } else if (a.price > b.price) {
                  return 1;
               }
               return 0;
            });
            break;
         }
         case "title": {
            filtered.sort((a, b) => {
               return a.title.localeCompare(b.title); // localCompare pour gérer les accents
            });
            break;
         }
         case "measurementValue": {
            filtered.sort((a, b) => {
               return a[sortKey] - b[sortKey];
            });
            break;
         }
         case "highlighted":
         case "enabled": {
            filtered.sort((a, b) => {
               return b[sortKey] - a[sortKey] || a.title.localeCompare(b.title);
            });
            break;
         }
         default:
            break;
      }

      setFilteredProducts(filtered);
   }, [user, sortKey, subcategoriesSelected, products, ingredientsSelected, priceMaxSelected]);

   useEffect(() => {
      setSortKey(user?.isAdmin ? "createdAt" : "highlighted");
   }, [user]);

   useEffect(() => {
      resetFilter();
      loadProductsData();
   }, [loadProductsData, resetFilter]);

   const addProductToSelection = useCallback(
      (productId) => {
         const selection = Array.from(productsSelected);
         if (!selection.includes(productId)) {
            selection.push(productId);
            setProductsSelected(selection);
         }
      },
      [productsSelected],
   );

   const removeProductFromSelection = useCallback(
      (productId) => {
         const selection = Array.from(productsSelected);
         const index = selection.indexOf(productId);
         if (index >= 0) {
            selection.splice(index, 1);
            setProductsSelected(selection);
         }
      },
      [productsSelected],
   );

   return (
      <ProductContext.Provider
         value={{
            loadProductsData,
            addProductToSelection,
            removeProductFromSelection,
            filteredProducts,
            categorySelected,
            setCategorySelected,
            setSubcategoriesSelected,
            setIngredientsSelected,
            subcategoriesSelected,
            ingredientsSelected,
            ingredients,
            subcategories,
            priceMax,
            sortKey,
            setSortKey,
            priceMaxSelected,
            setPriceMaxSelected,
            productsSelected,
            setProductsSelected,
            filterNumber,
            error,
            isLoading,
            resetFilter,
         }}
      >
         {children}
      </ProductContext.Provider>
   );
};

export const ProductContextLayout = () => {
   return (
      <ProductProvider>
         <Outlet />
      </ProductProvider>
   );
};
