import { Link } from "react-router-dom";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

/**
 * Menu de navigation hierarchique
 * @return JSX.element
 */

function BreadCrumb({ items }) {
   return (
      <div className="bread-crumb">
         <Link to="/">
            <FontAwesomeIcon icon={faHouse} />
         </Link>
         {items.map((item, index) => (
            <Fragment key={`bread-crumb-link-${index}`}>
               <div className="breadcrumb-arrow">›</div>
               {index === items.length - 1 ? (
                  <div>{item.title}</div>
               ) : (
                  <Link to={item.url}>{item.title}</Link>
               )}
            </Fragment>
         ))}
      </div>
   );
}

BreadCrumb.propTypes = {
   items: PropTypes.array.isRequired,
};

export default BreadCrumb;
