/**
 * Formatage de date JSON
 * @param {string} json Date au format JSON. ex : "2012-04-23T18:25:43.511Z"
 * @returns Date formaté en String
 */
export function formatJsonDate(dateString, short = false, showTime = false, showWeekday = false) {
   // Transforme le format JSON en format timeStamp
   const timeStamp = Date.parse(dateString);
   // Crée une date depuis le timeStamp
   const dateObject = new Date(timeStamp);

   // Formate la date selon le pays(local) et les options
   if (dateObject) {
      return formatDate(dateObject, short, showTime, showWeekday);
   }
   return undefined;
}

export function shortenedJsonDate(dateString, withTime = true) {
   const timeStamp = Date.parse(dateString);
   let dateObject = new Date(timeStamp);
   dateObject = new Date(dateObject.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
   const isoString = dateObject.toISOString();
   return isoString.substring(0, ((isoString.indexOf("T") | 0) + (withTime ? 6 : 0)) | 0);
}

export function formatJsonTime(timeString) {
   const timeObject = new Date(timeString);
   // Formate l'heure selon le pays(local) et les options
   if (timeObject) {
      return formatTime(timeObject);
   }
   return undefined;
}

export function formatDate(
   dateObject,
   short = false,
   showTime = false,
   showWeekday = false,
   showYear = true,
   addDays = 0,
) {
   const options = { day: "numeric" };

   options.month = short ? "short" : "long";
   if (showYear) {
      options.year = "numeric";
   }
   if (showWeekday) {
      options.weekday = "short";
   }

   if (showTime) {
      options.hour = "numeric";
      options.minute = "numeric";
   }

   if (dateObject && addDays > 0) {
      let copyOf = new Date(dateObject.valueOf());
      copyOf.setDate(copyOf.getDate() + addDays);
      return new Intl.DateTimeFormat(undefined, options).format(copyOf);
   }

   return new Intl.DateTimeFormat(undefined, options).format(dateObject);
}

export function formatTime(timeObject) {
   const options = { timeStyle: "short" };
   return new Intl.DateTimeFormat(undefined, options).format(timeObject);
}

export function formatDateToShort(dateObject) {
   return dateObject.toISOString().split("T")[0];
}

export function formatPrice(price) {
   const priceFloat = parseFloat(price);
   const formater = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
   });
   return formater.format(priceFloat);
}

export function formatPricePerUnit(price, measurementValue, measurementUnit) {
   const pricePerUnit = (1000 * price) / measurementValue;
   const units = { g: "kg", ml: "L" };
   return formatPrice(pricePerUnit) + "/" + units[measurementUnit];
}

export function formatMeasurement(value, unit) {
   if (value) {
      const valueInt = parseInt(value);
      if (valueInt >= 1000) {
         const kg = valueInt / 1000;
         const formater = new Intl.NumberFormat("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
         });
         const units = { g: "kg", ml: "L" };
         return `${formater.format(kg)}${units[unit]}`;
      } else {
         return `${valueInt}${unit}`;
      }
   }
   return undefined;
}

export function formatNumberProducts(array, item, suffix) {
   if (!array) {
      return `0 ${item ?? ""} ${suffix ?? ""}`;
   } else if (array.length <= 1) {
      return `${array.length} ${item ?? ""} ${suffix ?? ""}`;
   } else {
      return `${array.length} ${item ? item + "s" : ""} ${suffix ? suffix + "s" : ""}`;
   }
}

// Retire les accents et remplace les espaces, virgules, slash par des "-" et met tous en miniscule
export function normalizeName(name) {
   return name
      .replace(/[\s\\/]/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]|[,.;?:@&=+$*()]/g, "")
      .toLowerCase()
      .replace(/-{2,}/g, "-");
}

export function groupedBy(items, key) {
   return items.reduce(
      (result, item) => ({
         ...result,
         [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
   );
}

export function truncateString(str, num) {
   if (str.length <= num) {
      return str;
   }
   return str.slice(0, num) + "...";
}

export const sendDataAPI = async (url, method, body, isAuth) => {
   try {
      const fullUrl = `${process.env.REACT_APP_API_URL}/api/${url}`;
      const headers = {};
      const options = { method: method, headers: headers };
      if (isAuth) {
         options.credentials = 'include';
      }
      if (body) {
         headers["Content-Type"] = "application/json;charset=utf-8";
         options.body = JSON.stringify(body);
      }
      const response = await fetch(fullUrl, options);
      const data = await response.json();
      return { success: response.ok, code: response.status, data: data };
   } catch (error) {
      return { success: false, code: 500, data: error };
   }
};

export const calculatePasswordStrength = (password) => {
   let strength = 0;
   if (/[0-9]+/.test(password)) {
      strength += 1;
   }
   if (/[A-Z]+/.test(password)) {
      strength += 1;
   }
   if (/[!@£$%^&*()~<>?]+/.test(password)) {
      strength += 1;
   }
   if (password.length >= 1) {
      strength += 1;
   }
   if (password.length >= 8) {
      strength += 1;
   }
   if (password.length >= 20) {
      strength += 1;
   }
   return strength;
};

export const getCookie = (name) => {
   const allCookies = document.cookie.split(";").map((item) => item.trim());
   const eligible = allCookies.find((item) => item.startsWith(name + "="));
   if (eligible) {
      const parts = eligible.split("=");
      return JSON.parse(parts[1]);
   }
   return null;
}