import { Link } from "react-router-dom";
import AlertBox from "../AlertBox";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { sendDataAPI } from "../../helpers";

function UserAccountModal({ forgottenMode }) {
   const [showModal, setShowModal] = useState(false);
   const [success, setSuccess] = useState(false);
   const [errorMessage, setErrorMessage] = useState(null);
   const [email, setEmail] = useState("");
   const submitForm = useRef();

   useEffect(() => {
      if (showModal) {
         setEmail("");
         setErrorMessage(null);
         setSuccess(false);
      }
   }, [showModal]);

   const handleSubmit = useCallback(
      async (e) => {
         e.preventDefault();
         const url = `users/${forgottenMode ? "forgottenpassword" : "resendconfirmation"}`;
         const { code, data } = await sendDataAPI(url, "POST", { email: email });
         if (code === 401) {
            setErrorMessage(data.message);
         }
         setSuccess(true);
      },
      [forgottenMode, email],
   );

   return (
      <div className="user-account-modal">
         <Link
            className="secondary-link"
            onClick={() => {
               setShowModal(true);
            }}
         >
            {forgottenMode
               ? "J'ai oublié mon mot de passe"
               : "Renvoyer l'e-mail de confirmation de compte"}
         </Link>
         <AlertBox
            title={forgottenMode ? "Mot de passe oublié" : "Confirmation de compte"}
            size="sm"
            text={
               success ? (
                  <p>
                     {errorMessage
                        ? errorMessage
                        : "Si l'adresse e-mail renseignée est correcte, vous allez recevoir un message contenant les instructions."}
                  </p>
               ) : (
                  <Form onSubmit={handleSubmit}>
                     <Form.Label>
                        {`Veuillez entrer votre adresse e-mail pour recevoir un lien afin de
                           ${forgottenMode
                              ? "réinitialiser votre mot de passe"
                              : "confirmer votre compte"
                           }`}
                        &nbsp;:
                     </Form.Label>
                     <Form.Control
                        required
                        title="Par exemple : utilisateur@domaine.com"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        type="email"
                        id="confirm-email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                     <button style={{ display: "none" }} type="submit" ref={submitForm}></button>
                  </Form>
               )
            }
            show={showModal}
            onCancel={() => setShowModal(false)}
            onValidate={
               !success
                  ? () => {
                     submitForm.current.click();
                  }
                  : null
            }
         />
      </div>
   );
}

export default UserAccountModal;
