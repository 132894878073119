import { Button, Form } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../../contexts/searchContext";
import Loader from "../../components/Loader";


function Search() {
    const pageTitle = "Rechercher";
    const searchField = useRef();
    const navigate = useNavigate();
    const {  searchResults, searchQuery, processSearch, isLoadingSearch } = useContext(SearchContext);
    const handleSubmit = (e) => {
        e.preventDefault();
        processSearch(searchField.current.value);
    }
    useEffect(() => {
        searchField.current.value = searchQuery;
    }, [searchQuery]);
    useEffect(() => {
        searchField.current.disabled = isLoadingSearch;
        searchField.current.blur();
    }, [isLoadingSearch]);
    return (
        <PageLayout pageTitle={pageTitle} className="search" breadCrumbItems={[{ title: pageTitle }]}>
            <h1 className="page-title">{pageTitle}</h1> 
            <Form onSubmit={handleSubmit}>
                <div className="search__field-container">
                    <Form.Control ref={searchField} className="search__field" type="search" autoCapitalize="off" placeholder="Chercher un produit, une actualité..." />
                    <Button variant="secondary" type="submit"><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                </div>
            </Form>
            {isLoadingSearch && <Loader />}
            {searchResults && searchResults.length === 0 && <h2>Aucun résultat pour "{searchQuery}"</h2>}
            {searchResults && searchResults.length > 0 && (
                <>
                    <h2>{searchResults.length} résultat{searchResults.length > 1 && "s"} pour "{searchQuery}"</h2>
                    <div className="search__results">
                        {searchResults.map( (result, index) => (
                            <div key={`result-${index}`} className="search__result two-columns two-columns--vertical-centered" onClick={() => navigate(result.url)}>
                                <div>
                                    <h3><Link to={result.url}>{result.title}</Link><span>{result.category}</span></h3>
                                    <p>{result.summary}</p>
                                </div>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                            )
                        )}
                    </div>
                </>
            )}
        </PageLayout>
    );

}

export default Search;