import { createContext, useCallback, useEffect, useState, useContext } from "react";
import { UserContext } from "./userContext";
import { sendDataAPI } from "../helpers";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
   const [categories, setCategories] = useState([]);
   const [categoriesNames, setCategoriesNames] = useState({});
   const [ingredients, setIngredients] = useState([]);
   const [allergens, setAllergens] = useState([]);
   const [aboutPages, setAboutPages] = useState([]);
   const [newsCategories, setNewsCategories] = useState([]);
   const [nextMeatSale, setNextMeatSale] = useState();
   const [settings, setSettings] = useState({});
   const [isClosed, setIsClosed] = useState(false);
   const [isErrorSettings, setIsErrorSettings] = useState(false);
   const [isLoadingSettings, setIsLoadingSettings] = useState(true);
   const { user } = useContext(UserContext);

   const loadSettings = useCallback(async (options) => {
      try {
         const { success, data } = await sendDataAPI("settings", "GET");
         if (!success) {
            setIsLoadingSettings(false);
            setIsErrorSettings(true);
         } else {
            if (options.includes("full") || options.includes("categories")) {
               setCategories(data.categories);
               setCategoriesNames(
                  data.categories.reduce((a, v) => ({ ...a, [v.id]: v.title }), {}),
               );
            }
            if (options.includes("full") || options.includes("nextMeatSale")) {
               setNextMeatSale(data.nextMeatSale);
            }
            if (options.includes("full") || options.includes("aboutPages")) {
               setAboutPages(data.aboutPages);
            }
            if (options.includes("full") || options.includes("newsCategories")) {
               setNewsCategories(data.newsCategories);
            }
            if (options.includes("full") || options.includes("settings")) {
               if (data.settings.closingStart) {
                  const timeStamp = Date.parse(data.settings.closingStart);
                  data.settings.closingStart = new Date(timeStamp);
               }
               if (data.settings.closingEnd) {
                  const timeStamp = Date.parse(data.settings.closingEnd);
                  data.settings.closingEnd = new Date(timeStamp);
               }
               if (data.settings.closingStart && data.settings.closingEnd) {
                  const today = new Date();
                  setIsClosed(today <= data.settings.closingEnd);
               } else {
                  setIsClosed(false);
               }
               setSettings(data.settings);
            }
            //Stocke les allergens et les ingrédients uniquement pour les admins
            if (user.role && user.role === 1) {
               if (options.includes("full") || options.includes("allergens")) {
                  setAllergens(data.allergens);
               }
               if (options.includes("full") || options.includes("ingredients")) {
                  setIngredients(data.ingredients);
               }
            }
         }
         setIsLoadingSettings(false);
      } catch (err) {
         setIsLoadingSettings(false);
         setIsErrorSettings(true);
      }

   }, [user]);

   const modifySettings = useCallback(
      async (newValues) => {
         try {
            setIsErrorSettings(false);
            setIsLoadingSettings(true);
            const { success } = await sendDataAPI("settings", "PUT", { user: { id: user.id, role: user.role }, ...newValues }, true);
            if (success) {
               loadSettings(["settings"]);
            }
         } catch (error) {
            setIsErrorSettings(true);
         }
         setIsLoadingSettings(false);
      },
      [user, loadSettings],
   );

   useEffect(() => {
      loadSettings(["full"]);
   }, [loadSettings]);

   return (
      <SettingsContext.Provider
         value={{
            categories,
            categoriesNames,
            ingredients,
            allergens,
            aboutPages,
            newsCategories,
            nextMeatSale,
            settings,
            isClosed,
            isErrorSettings,
            isLoadingSettings,
            loadSettings,
            modifySettings,
         }}
      >
         {children}
      </SettingsContext.Provider>
   );
};
