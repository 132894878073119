import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../contexts/userContext";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import ImageSelector from "../ImageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import { SettingsContext } from "../../contexts/settingsContext";
import MarkdownEditor from "../MarkdownEditor";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const NewValueContainer = (props) => {
   return (
      <components.ValueContainer {...props}>
         <div className="react-select__floating-label">Catégorie *</div>
         {props.children}
      </components.ValueContainer>
   );
};

function EditNewsModal({ show, onClose, onRefresh, news }) {
   const [title, setTitle] = useState("");
   const [text, setText] = useState("");
   const [categorySelected, setCategorySelected] = useState();
   const [selectedFile, setSelectedFile] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState();
   const [successMessage, setSuccessMessage] = useState();
   const eventDateRef = useRef();
   const eventTimeRef = useRef();
   const { newsCategories } = useContext(SettingsContext);

   const { user } = useContext(UserContext);

   function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
   }

   useEffect(() => {
      setCategorySelected(news && news.NewsCategory);
      setTitle(news ? news.title : "");
      setText(news && news.text ? news.text : "");
      if (news && news.eventDate) {
         const timeStamp = Date.parse(news.eventDate);
         const dateObject = new Date(timeStamp);
         const date = [
            dateObject.getFullYear(),
            padTo2Digits(dateObject.getMonth() + 1),
            padTo2Digits(dateObject.getDate()),
         ].join("-");
         if (eventDateRef.current) eventDateRef.current.value = date;
         if (news.showTime) {
            const time = [
               padTo2Digits(dateObject.getHours()),
               padTo2Digits(dateObject.getMinutes()),
            ].join(":");
            if (eventTimeRef.current) eventTimeRef.current.value = time;
         }
      }
      setSelectedFile();
      setErrorMessage();
      setSuccessMessage();
   }, [news, show, eventDateRef]);

   const handleSubmit = (e) => {
      e.preventDefault();

      if (!categorySelected) {
         setErrorMessage("Merci de choisir une catégorie");
         return;
      }

      if (!text || text.length < 50) {
         setErrorMessage("Merci de saisir un texte d'une longueur de plus de 50 caractères");
         return;
      }

      if (!news && !selectedFile) {
         setErrorMessage("Merci de choisir une photo pour l'actualité");
         return;
      }

      if (selectedFile && selectedFile.size > 10485760) {
         setErrorMessage("Merci de choisir une photo d'une taille inférieure à 10 Mo");
         return;
      }

      setIsLoading(true);
      const sendData = async () => {
         if (!user) {
            return;
         }
         try {
            let formData = new FormData();
            const newsData = {
               title: title,
               text: text,
               NewsCategoryId: categorySelected.id,
            };

            if (eventDateRef.current.value.length > 0) {
               if (eventTimeRef.current.value.length > 0) {
                  newsData.eventDate =
                     eventDateRef.current.value + " " + eventTimeRef.current.value;
               } else {
                  newsData.eventDate = eventDateRef.current.value;
               }
            }

            newsData.showTime = eventTimeRef.current.value.length > 0;
            formData.append("news", JSON.stringify(newsData));
            if (selectedFile) {
               formData.append("image", selectedFile);
            }

            const url =
               process.env.REACT_APP_API_URL + (news ? "/api/news/" + news.id : "/api/news");
            const method = news ? "PUT" : "POST";

            const response = await fetch(url, {
               method: method,
               body: formData,
               credentials: 'include',
            });
            if (response.status === 201) {
               setSuccessMessage("Actualité bien créée");
               onRefresh();
            } else if (response.status === 200) {
               setSuccessMessage("Actualité bien modifiée");
               onRefresh();
            } else {
               setErrorMessage(`Erreur, l'actualité ne peut être ${news ? "modifiée" : "créée"}`);
            }
         } catch (error) {
            setErrorMessage(error);
         } finally {
            setIsLoading(false);
         }
      };
      sendData();
   };

   return (
      <Modal
         size="xl"
         fullscreen="sm-down"
         show={show}
         aria-labelledby="edit-modal-title"
         centered
         className="edit-modal edit-news-modal"
      >
         <Modal.Body>
            <h2 id="edit-modal-title">
               {news ? "Modifier une actualité" : "Ajouter une nouvelle actualité"}
            </h2>
            {successMessage ? (
               <div className="edit-modal__success">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {successMessage}
               </div>
            ) : (
               <>
                  <Form onSubmit={handleSubmit} id="edit-modal-form">
                     <div className="edit-modal__columns">
                        <div>
                           <h3>Informations générales</h3>
                           <div className="edit-modal__grid">
                              <FloatingLabel
                                 controlId="title"
                                 label="Titre *"
                                 className="edit-modal__colspan"
                              >
                                 <Form.Control
                                    type="text"
                                    value={title}
                                    onChange={(e) => {
                                       setTitle(e.target.value);
                                       setErrorMessage();
                                    }}
                                    required
                                    disabled={isLoading}
                                 />
                              </FloatingLabel>
                              <Select
                                 id="category"
                                 isDisabled={isLoading}
                                 options={newsCategories}
                                 value={categorySelected}
                                 placeholder=""
                                 onChange={(selectedOption) => {
                                    setCategorySelected(selectedOption);
                                 }}
                                 getOptionLabel={(option) => option.title}
                                 getOptionValue={(option) => option.id}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 noOptionsMessage={() => "Aucune catégorie trouvée"}
                                 components={{ ValueContainer: NewValueContainer }}
                              />

                              <FloatingLabel controlId="eventDate" label="Date évènement">
                                 <Form.Control
                                    type="date"
                                    disabled={isLoading}
                                    ref={eventDateRef}
                                 />
                              </FloatingLabel>
                              <FloatingLabel controlId="eventTime" label="Heure évènement">
                                 <Form.Control
                                    type="time"
                                    disabled={isLoading}
                                    ref={eventTimeRef}
                                 />
                              </FloatingLabel>
                           </div>
                           <MarkdownEditor
                              className="edit-modal__colspan"
                              markdown={text}
                              headings={["h2", "h3"]}
                              onChange={(newText) => setText(newText)}
                           />
                        </div>
                        <div>
                           <h3>Photo</h3>
                           <ImageSelector
                              imageUrl={news && news.imageUrl.find((image) => image.fallback).url}
                              onChange={(newFile) => {
                                 setSelectedFile(newFile);
                                 setErrorMessage();
                              }}
                              selectorId="input1"
                           />
                        </div>
                     </div>
                     {errorMessage && errorMessage.length > 0 && (
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                     )}
                  </Form>
               </>
            )}
         </Modal.Body>
         <Modal.Footer>
            {isLoading ? (
               <Loader />
            ) : (
               <>
                  <Button className="btn-rounded" onClick={onClose} variant="secondary">
                     Fermer
                  </Button>

                  {!successMessage && (
                     <Button
                        className="btn-rounded"
                        variant="primary"
                        form="edit-modal-form"
                        type="submit"
                     >
                        Valider
                     </Button>
                  )}
               </>
            )}
         </Modal.Footer>
      </Modal>
   );
}

export default EditNewsModal;
