import { NewsContext } from "../../contexts/newsContext";
import { useContext, useMemo, useState, useEffect } from "react";
import { Filter } from "../../components/Filter";
import PageLayout from "../../layouts/PageLayout";
import Pagination from "react-bootstrap/Pagination";
import { SettingsContext } from "../../contexts/settingsContext";
import { UserContext } from "../../contexts/userContext";
import ButtonCard from "../../components/ButtonCard";
import EditNewsModal from "../../components/EditNewsModal";
import { normalizeName } from "../../helpers";
import removeMarkdown from "remove-markdown";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import { Button } from "react-bootstrap";
import CloseButton from "../../components/CloseButton";

function News() {
   const pageTitle = "Actualités";
   const { newsCategories, loadSettings } = useContext(SettingsContext);
   const {
      categoriesSelected,
      setCategoriesSelected,
      pagesNumber,
      currentPage,
      setCurrentPage,
      previousPage,
      nextPage,
   } = useContext(NewsContext);
   const { news, error, isLoading, loadNewsData } = useContext(NewsContext);
   const { user } = useContext(UserContext);
   const [showEditModal, setShowEditModal] = useState(false);
   const [editNews, setEditNews] = useState();
   const [showFilters, setShowFilters] = useState(false);

   useEffect(() => {
      document.body.style.overflowY = showFilters ? "hidden" : "auto";
   }, [showFilters]);

   const paginationItems = useMemo(() => {
      let pages = [];
      for (let i = 1; i <= pagesNumber; i++) {
         pages.push(
            <Pagination.Item
               active={currentPage === i}
               key={`page-${i}`}
               onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentPage(i);
               }}
            >
               {i}
            </Pagination.Item>,
         );
      }
      return pages;
   }, [pagesNumber, currentPage, setCurrentPage]);

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="news-page"
         breadCrumbItems={[{ title: "Actualités" }]}
      >
         <h1 className="page-title">{pageTitle}</h1>

         <div
            className={`news-page__filter ${showFilters ? "news-page__filter--visible" : "news-page__filter--hidden"
               }`}
         >
            <CloseButton onClick={() => setShowFilters(false)} />
            <Filter
               title="Filtre"
               buttons={newsCategories}
               selection={categoriesSelected}
               onSelect={(selectedIndex) => {
                  if (!categoriesSelected.includes(selectedIndex)) {
                     setCategoriesSelected((prevState) => [...prevState, selectedIndex]);
                  }
               }}
               onDeselect={(deselectedIndex) => {
                  if (categoriesSelected.includes(deselectedIndex)) {
                     setCategoriesSelected((prevState) =>
                        prevState.filter((item) => item !== deselectedIndex),
                     );
                  }
               }}
            />
         </div>
         <Button
            className="news-page__filters-toggle btn-rounded"
            onClick={() => setShowFilters(true)}
         >
            Filtrer {categoriesSelected.length > 0 ? `(${categoriesSelected.length})` : ""}
         </Button>

         <section className="news-page__list">
            {user?.isAdmin && (
               <ButtonCard
                  title="Ajouter une nouvelle actualité"
                  variant="left"
                  onClick={() => {
                     setEditNews();
                     setShowEditModal(true);
                  }}
               />
            )}
            {isLoading && <Loader variant="full" />}
            {error && (
               <p className="message-text">
                  Impossible de charger les actualités pour le moment
               </p>
            )}
            {!error && news.length === 0 && (
               <p className="message-text">Aucune actualité pour le moment</p>
            )}
            {!error &&
               news.map((oneNew, index) => (
                  <Card
                     variant={index % 2 === 0 ? "left" : "right"}
                     images={oneNew.imageUrl}
                     title={oneNew.title}
                     date={oneNew.createdAt}
                     eventDate={oneNew.eventDate}
                     showTime={oneNew.showTime}
                     text={removeMarkdown(oneNew.text)}
                     tags={oneNew.NewsCategory ? [oneNew.NewsCategory.title] : undefined}
                     buttonTitle="Lire la suite"
                     buttonURL={`/actualites/${normalizeName(oneNew.title)}-${oneNew.id}`}
                     key={`new - ${index}`}
                  />
               ))}
            <EditNewsModal
               show={showEditModal}
               onClose={() => {
                  setShowEditModal(false);
                  loadNewsData();
                  loadSettings(["nextMeatSale"]);
               }}
               onRefresh={() => { }}
               news={editNews}
            />
         </section>

         {paginationItems.length > 0 && (
            <div className="news-page__pagination">
               <Pagination>
                  {currentPage > 1 && (
                     <Pagination.Prev
                        onClick={() => {
                           window.scrollTo(0, 0);
                           previousPage();
                        }}
                     />
                  )}
                  {paginationItems}
                  {currentPage < pagesNumber && (
                     <Pagination.Next
                        onClick={() => {
                           window.scrollTo(0, 0);
                           nextPage();
                        }}
                     />
                  )}
               </Pagination>
            </div>
         )}
      </PageLayout>
   );
}

export default News;
