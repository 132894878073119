import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

/**
 * Boite de dialgue avec deux boutons
 * @return JSX.element
 */

function AlertBox({ show, size, title, text, onCancel, onValidate, validateColor }) {
   return (
      <Modal size={size ?? "md"} show={show} aria-labelledby="alertbox" centered scrollable>
         <Modal.Body>
            {title && <h4>{title}</h4>}
            <div>{text}</div>
         </Modal.Body>
         <Modal.Footer>
            <Button className="btn-rounded" onClick={onCancel} variant="secondary">
               Fermer
            </Button>
            {onValidate && (
               <Button
                  className="btn-rounded"
                  onClick={onValidate}
                  variant={validateColor ?? "primary"}
               >
                  Valider
               </Button>
            )}
         </Modal.Footer>
      </Modal>
   );
}

AlertBox.propTypes = {
   show: PropTypes.bool,
   size: PropTypes.string,
   title: PropTypes.string,
   text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
   onCancel: PropTypes.func,
   onValidate: PropTypes.func,
   validateColor: PropTypes.string,
};

export default AlertBox;
