import { SettingsContext } from "../../contexts/settingsContext";
import { CartContext } from "../../contexts/cartContext";
import { React, useContext, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AlertBox from "../../components/AlertBox";
import Loader from "../../components/Loader";
import {
   formatPrice,
   formatMeasurement,
   formatJsonTime,
   formatJsonDate,
   formatDate,
   groupedBy,
   shortenedJsonDate,
} from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faArrowLeft, faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import PolicyContent from "../../components/PolicyContent";
import { OrderWarning } from "../../components/OrderWarning";
import ErrorMessage from "../../components/ErrorMessage";
import PageLayout from "../../layouts/PageLayout";
import Tag from "../../components/Tag";
import { UserContext } from "../../contexts/userContext";

function Cart() {
   const pageTitle = "Panier de réservation";
   const { nextMeatSale, settings } = useContext(SettingsContext);
   const { user, userData, loadUserData } = useContext(UserContext);
   const {
      cart,
      addToCart,
      removeFromCart,
      cartAmount,
      cartItemsNumber,
      resetCart,
      cartDeliveryDate,
      cartDeliveryHour,
      setCartDeliveryDate,
      setCartDeliveryHour,
      meatCartDeliveryDate,
      setMeatCartDeliveryDate,
      cartUserInfos,
      setCartUserInfos,
   } = useContext(CartContext);
   const [showResetModal, setShowResetModal] = useState(false);
   const [selectedTab, setSelectedTab] = useState(0);
   const [isErrorDeliveryDate, setIsErrorDeliveryDate] = useState(false);
   const [isErrorUserData, setIsErrorUserData] = useState(false);
   const [isErrorCheckboxes, setIsErrorCheckboxes] = useState(false);
   const [reservationSent, setReservationSent] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [showPolicyModal, setShowPolicyModal] = useState(false);
   const navigate = useNavigate();
   const [queryParameters] = useSearchParams();
   const infoCheckBox = useRef();
   const cgvCheckBox = useRef();
   const summaryRef = useRef();

   const someMeatCart = cart.some((product) => product.ProductCategory.type === "meat");
   const onlyMeatCart = cart.every((product) => product.ProductCategory.type === "meat");

   useEffect(() => {
      const step = queryParameters.get("step");
      if (step) {
         setSelectedTab(parseInt(step));
      }
   }, [queryParameters]);

   useEffect(() => {
      if (selectedTab === 1 && user && !userData) {
         loadUserData();
         setIsErrorUserData(false);
      }
   }, [user, selectedTab, userData, loadUserData]);

   useEffect(() => {
      if (userData) {
         setCartUserInfos((prevState) => ({ ...prevState, ...userData }));
      }
   }, [userData, setCartUserInfos]);

   const userMeatDeliveryDateOptions = useMemo(() => {
      if (!nextMeatSale) {
         return [];
      }
      let days = [];
      // Date de départ fixée à la vente de viande
      const slotDays = settings.Hours.filter((slot) => slot.type === 1).map((slot) =>
         slot.weekDay === 7 ? 0 : slot.weekDay,
      );
      const uniqueSlotDays = [...new Set(slotDays)];
      let increment = 0;
      while (days.length < 3) {
         const start = new Date(nextMeatSale.valueOf());
         start.setDate(start.getDate() + increment);
         if (start >= settings.closingStart && start <= settings.closingEnd) {
            increment++;
            continue;
         }
         const day = start.getDay();
         if (uniqueSlotDays.includes(day)) {
            days.push(start);
         }
         increment++;
      }
      return days;
   }, [nextMeatSale, settings]);

   const userDeliveryDateOptions = useMemo(() => {
      if (!settings.Hours) {
         return [];
      }
      let days = [];
      // Date de départ fixée au lendemain
      let today = new Date();
      let delta = 1;
      if (today.getHours() >= 12) {
         //Si réservation après 12h00, proposer le surlendemain
         delta = 2;
      }
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      let tomorrow = today.setDate(today.getDate() + delta);
      // Vérifie que le lendemain est en dehors d'une période de fermeture temporaire
      if (tomorrow >= settings.closingStart && tomorrow <= settings.closingEnd) {
         const closingDate = new Date(settings.closingEnd.valueOf());
         tomorrow = closingDate.setDate(closingDate.getDate() + 1);
      }
      const slotDays = settings.Hours.filter((slot) => slot.type === 1).map((slot) =>
         slot.weekDay === 7 ? 0 : slot.weekDay,
      );
      const uniqueSlotDays = [...new Set(slotDays)];
      let increment = 0;
      while (days.length < 8) {
         const start = new Date(tomorrow.valueOf());
         start.setDate(start.getDate() + increment);
         if (start >= settings.closingStart && start <= settings.closingEnd) {
            increment++;
            continue;
         }
         const day = start.getDay();
         if (uniqueSlotDays.includes(day)) {
            days.push(start);
         }
         increment++;
      }
      return days;
   }, [settings]);

   const userDeliveryHourOptions = useMemo(() => {
      if (!settings || cartDeliveryDate === "") {
         return [];
      }

      const timeStamp = Date.parse(cartDeliveryDate);
      const dateObject = new Date(timeStamp);
      let weekDay = dateObject.getDay();
      if (weekDay === 0) weekDay = 7;
      const choosenDate = shortenedJsonDate(cartDeliveryDate, false);
      const exceptional = settings.Hours.filter(
         (hour) => hour.type === 1 && hour.temporary && hour.temporaryDate === choosenDate,
      );
      return exceptional.length > 0
         ? exceptional
         : settings.Hours.filter((hour) => hour.type === 1 && hour.weekDay === weekDay);
   }, [cartDeliveryDate, settings]);

   const checkDelivery = () => {
      if (
         (onlyMeatCart && meatCartDeliveryDate !== "") ||
         (someMeatCart &&
            cartDeliveryDate !== "" &&
            cartDeliveryHour !== "" &&
            meatCartDeliveryDate !== "") ||
         (!someMeatCart && cartDeliveryDate !== "" && cartDeliveryHour !== "")
      ) {
         setSelectedTab(1);
         scrollTabToTop();
      } else {
         setIsErrorDeliveryDate(true);
      }
   };

   const checkInfo = (e) => {
      e.preventDefault();
      if (
         cartUserInfos.lastName &&
         cartUserInfos.lastName.trim().length > 0 &&
         cartUserInfos.email &&
         cartUserInfos.email.trim().length > 0 &&
         cartUserInfos.phone &&
         cartUserInfos.phone.trim().length > 0
      ) {
         setSelectedTab(2);
         scrollTabToTop();
      } else {
         setIsErrorUserData(true);
      }
   };

   // Vérifie que les checkbox sont coché, envoie la commande à l'API et vide le panier
   const checkReservation = () => {
      // Récupère la réference des 2 checkbox
      if (infoCheckBox.current.checked && cgvCheckBox.current.checked) {
         setIsLoading(true);
         setIsErrorCheckboxes(false);
         sendReservation();
      } else {
         setIsErrorCheckboxes(true);
      }
   };

   async function sendReservation() {
      const order = {
         totalPrice: cartAmount,
         userMessage: cartUserInfos.userMessage,
         lastName: cartUserInfos.lastName,
         firstName: cartUserInfos.firstName,
         email: cartUserInfos.email,
         phone: cartUserInfos.phone,
         address: cartUserInfos.address,
         postalCode: cartUserInfos.postalCode,
         city: cartUserInfos.city,
         products: cart,
      };

      if (!onlyMeatCart && cartDeliveryDate !== "" && cartDeliveryHour !== "") {
         const hourParts = cartDeliveryHour.split(":");
         const timeStamp = Date.parse(cartDeliveryDate);
         const dateObject = new Date(timeStamp);
         dateObject.setHours(hourParts[0]);
         dateObject.setMinutes(hourParts[1]);
         dateObject.setSeconds(hourParts[2]);
         dateObject.setMilliseconds(0);
         order.deliveryDate = dateObject;
      }

      if (someMeatCart) {
         if (nextMeatSale.split("T")[0] === meatCartDeliveryDate.split("T")[0]) {
            order.meatDeliveryDate = nextMeatSale;
            if (onlyMeatCart) {
               order.deliveryDate = nextMeatSale;
            }
         } else {
            const timeStamp = Date.parse(meatCartDeliveryDate);
            const dateObject = new Date(timeStamp);
            dateObject.setHours(9);
            dateObject.setMinutes(0);
            dateObject.setSeconds(0);
            dateObject.setMilliseconds(0);
            order.meatDeliveryDate = dateObject;
            if (onlyMeatCart) {
               order.deliveryDate = dateObject;
            }
         }
      }

      if (user) {
         order.UserId = user.id;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
         method: "POST",
         headers: { "Content-Type": "application/json;charset=utf-8" },
         body: JSON.stringify(order),
      });
      setIsLoading(false);
      if (response.status === 201) {
         resetCart();
         setReservationSent(true);
         window.scroll(0, 0);
      }
   }

   const groupedCart = groupedBy(cart, "ProductCategoryId");

   const scrollTabToTop = useCallback(() => {
      if (summaryRef) {
         const summaryPos = summaryRef.current.getBoundingClientRect().top;
         const delta = window.innerWidth < 601 ? 85 : 158;
         if (summaryPos <= delta || summaryPos >= window.innerHeight) {
            window.scroll(0, summaryRef.current.offsetTop - delta);
         }
      }
   }, []);

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="cart-page"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         <AlertBox
            title="Conditions générales de réservation"
            text={<PolicyContent />}
            size="lg"
            show={showPolicyModal}
            onCancel={() => setShowPolicyModal(false)}
         />
         <h1 className="page-title">{pageTitle}</h1>
         {!reservationSent && cart.length > 0 && (
            <>
               <div className="cart-page__content">
                  <section className="cart-page__products">
                     <OrderWarning />
                     {Object.keys(groupedCart).map((groupKey, index) => (
                        <div
                           className="cart-page__group white-block"
                           key={`category-cart-${index}`}
                        >
                           <div className="two-columns">
                              <h2>{groupedCart[groupKey][0].ProductCategory.title}</h2>
                              {groupedCart[groupKey][0].ProductCategory.type === "meat" &&
                                 nextMeatSale && (
                                    <Tag
                                       variant="danger"
                                       title={`Retrait à partir du ${formatJsonDate(
                                          nextMeatSale,
                                          true,
                                          true,
                                       )}`}
                                    />
                                 )}
                           </div>
                           <div className="cart-page__group-items">
                              {groupedCart[groupKey].map((product, index) => (
                                 <div className="cart-page__item" key={`product-cart-${index}`}>
                                    <p className="cart-page__item-title">
                                       <Link to={`${product.ProductCategory.url}/${product.id}`}>
                                          {product.title}
                                          {(product.showMeasurement ||
                                             product.ProductVariants.length > 0) && (
                                                <>
                                                   <span>
                                                      {" "}
                                                      •{" "}
                                                      {formatMeasurement(
                                                         product.measurementValue,
                                                         product.measurementUnit,
                                                      )}
                                                   </span>
                                                   {product.approximativeMeasurement && (
                                                      <span> environ</span>
                                                   )}
                                                </>
                                             )}
                                       </Link>
                                    </p>
                                    <div className="cart-page__item-actions">
                                       <Button
                                          className="btn-rounded"
                                          onClick={() => {
                                             if (isLoading) {
                                                return;
                                             }
                                             removeFromCart(product, 1, {
                                                price: product.price,
                                                measurementValue: product.measurementValue,
                                                measurementUnit: product.measurementUnit,
                                             });
                                             setSelectedTab(0);
                                          }}
                                          variant="secondary"
                                          size="sm"
                                       >
                                          –
                                       </Button>
                                       <span className="cart-page__item-quantity">
                                          {product.quantity}
                                       </span>
                                       <Button
                                          className="btn-rounded"
                                          onClick={() => {
                                             if (isLoading) {
                                                return;
                                             }
                                             addToCart(product, 1, {
                                                price: product.price,
                                                measurementValue: product.measurementValue,
                                                measurementUnit: product.measurementUnit,
                                             });
                                             setSelectedTab(0);
                                          }}
                                          variant="secondary"
                                          size="sm"
                                       >
                                          +
                                       </Button>
                                    </div>
                                    <p className="cart-page__item-price">
                                       {formatPrice(product.price * product.quantity)}
                                    </p>
                                    <FontAwesomeIcon
                                       className="cart-page__item-trash"
                                       onClick={() => {
                                          if (isLoading) {
                                             return;
                                          }

                                          removeFromCart(product, undefined, {
                                             price: product.price,
                                             measurementValue: product.measurementValue,
                                             measurementUnit: product.measurementUnit,
                                          });
                                          setSelectedTab(0);
                                       }}
                                       icon={faTrash}
                                    />
                                 </div>
                              ))}
                           </div>
                        </div>
                     ))}

                     {cart.length > 0 && (
                        <Button
                           onClick={() => setShowResetModal(true)}
                           className="cart-page__reset btn-rounded"
                           variant="secondary"
                           size="sm"
                           disabled={isLoading}
                        >
                           Vider mon panier
                        </Button>
                     )}
                     <AlertBox
                        title="Vider mon panier"
                        text="Êtes-vous sûr de vouloir vider votre panier ?"
                        show={showResetModal}
                        onCancel={() => setShowResetModal(false)}
                        onValidate={() => {
                           setShowResetModal(false);
                           resetCart();
                           window.scroll(0, 0);
                        }}
                     />
                  </section>

                  <aside ref={summaryRef} className="cart-page__summary white-block">
                     {selectedTab === 0 && (
                        <>
                           <h2 className="cart-page__summary-title">Mon panier</h2>
                           <div className="two-columns cart-page__summary-line">
                              <p>Total</p>
                              <p className="cart-page__summary-amount">{formatPrice(cartAmount)}</p>
                           </div>
                           <div className="two-columns cart-page__summary-line">
                              <p>Nombre de produits</p>
                              <p>{cartItemsNumber}</p>
                           </div>

                           {!onlyMeatCart && someMeatCart && <h3>Viande bovine</h3>}
                           {someMeatCart && (
                              <div className="two-columns cart-page__summary-line">
                                 <p>Jour de retrait</p>
                                 <Form.Select
                                    aria-label="Sélection du jour de retrait'"
                                    value={meatCartDeliveryDate}
                                    onChange={(e) => {
                                       setIsErrorDeliveryDate(false);
                                       setMeatCartDeliveryDate(e.target.value);
                                    }}
                                 >
                                    <option value="">Choisir...</option>
                                    {userMeatDeliveryDateOptions.map((item, index) => (
                                       <option key={`options-${index}`} value={item.toJSON()}>
                                          {formatDate(item, true, false, true, false)}
                                       </option>
                                    ))}
                                 </Form.Select>
                              </div>
                           )}
                           {!onlyMeatCart && someMeatCart && <h3>Hors viande bovine</h3>}

                           {!onlyMeatCart && (
                              <div className="two-columns cart-page__summary-line">
                                 <p>Jour de retrait</p>
                                 <Form.Select
                                    aria-label="Sélection du jour de retrait'"
                                    value={cartDeliveryDate}
                                    onChange={(e) => {
                                       setIsErrorDeliveryDate(false);
                                       setCartDeliveryDate(e.target.value);
                                    }}
                                 >
                                    <option value="">Choisir...</option>
                                    {userDeliveryDateOptions.map((item, index) => (
                                       <option key={`options-${index}`} value={item.toJSON()}>
                                          {formatDate(item, true, false, true, false)}
                                       </option>
                                    ))}
                                    {someMeatCart && userMeatDeliveryDateOptions.map((item, index) => (
                                       <option key={`options-${index}`} value={item.toJSON()}>
                                          {formatDate(item, true, false, true, false)}
                                       </option>
                                    ))}
                                 </Form.Select>
                              </div>
                           )}

                           {!onlyMeatCart && cartDeliveryDate !== "" && (
                              <div className="two-columns cart-page__summary-line">
                                 <p>Heure de retrait</p>
                                 <Form.Select
                                    aria-label="Sélection d'heure de retrait'"
                                    value={cartDeliveryHour}
                                    onChange={(e) => {
                                       setIsErrorDeliveryDate(false);
                                       setCartDeliveryHour(e.target.value);
                                    }}
                                 >
                                    <option value="">Choisir...</option>
                                    {userDeliveryHourOptions.map((item, index) => (
                                       <option key={`option-hour-${index}`} value={item.startTime}>
                                          {formatJsonTime("2023-01-01T" + item.startTime)} -{" "}
                                          {formatJsonTime("2023-01-01T" + item.endTime)}
                                       </option>
                                    ))}
                                 </Form.Select>
                              </div>
                           )}

                           {isErrorDeliveryDate && (
                              <ErrorMessage isBordered={false}>
                                 Veuillez choisir un créneau de retrait
                              </ErrorMessage>
                           )}

                           <Button
                              className="btn-rounded cart-page__summary-btn"
                              onClick={() => checkDelivery()}
                           >
                              Valider mon panier
                           </Button>
                        </>
                     )}
                     {selectedTab === 1 && (
                        <>
                           <div className="cart-page__summary-header">
                              <div className="cart-page__summary-arrow">
                                 <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    onClick={() => {
                                       setSelectedTab(0);
                                       scrollTabToTop();
                                    }}
                                 />
                              </div>
                              <h2 className="cart-page__summary-title">Coordonnées</h2>
                              <div className="cart-page__summary-arrow"></div>
                           </div>
                           {!user && (
                              <div className="cart-page__summary-invited">
                                 <div>
                                    <p>
                                       Pour remplir les champs automatiquement, connectez-vous à votre compte
                                    </p>
                                    <Button
                                       variant="secondary"
                                       className="btn-rounded"
                                       onClick={() =>
                                          navigate("/compte/?redirect=%2Fpanier%2F%3Fstep%3D1")
                                       }
                                    >
                                       Se connecter
                                    </Button>
                                    <Link className="secondary-link" to="/compte/creation">Pas encore client ? Créer un compte</Link>
                                 </div>
                                 <div className="cart-page__summary-or">ou</div>
                                 <p>
                                    Remplissez les champs suivants (mode invité)
                                 </p>
                              </div>
                           )}

                           <Form onSubmit={(e) => checkInfo(e)}>
                              {user ? (
                                 <>
                                    <Form.Control
                                       type="text"
                                       placeholder="Nom *"
                                       value={
                                          cartUserInfos.lastName &&
                                          `${cartUserInfos.lastName} ${cartUserInfos.firstName}`
                                       }
                                       disabled={cartUserInfos.lastName}
                                       readOnly
                                    />
                                    <Form.Control
                                       type="text"
                                       placeholder="Adresse e-mail *"
                                       value={cartUserInfos.email}
                                       disabled={cartUserInfos.email}
                                       readOnly
                                    />
                                    <Form.Control
                                       type="text"
                                       placeholder="Téléphone *"
                                       value={cartUserInfos.phone}
                                       disabled={cartUserInfos.phone}
                                       readOnly
                                    />
                                 </>
                              ) : (
                                 <>
                                    <Form.Control
                                       type="text"
                                       placeholder="Nom *"
                                       required
                                       value={cartUserInfos.lastName}
                                       onChange={(e) => {
                                          setCartUserInfos((prevState) => ({
                                             ...prevState,
                                             lastName: e.target.value,
                                          }));
                                          setIsErrorUserData(false);
                                       }}
                                       autoComplete="name"
                                    />
                                    <Form.Control
                                       type="email"
                                       title="Par exemple : utilisateur@domaine.com"
                                       pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                       required
                                       placeholder="Adresse e-mail *"
                                       value={cartUserInfos.email}
                                       onChange={(e) => {
                                          setCartUserInfos((prevState) => ({
                                             ...prevState,
                                             email: e.target.value,
                                          }));
                                          setIsErrorUserData(false);
                                       }}
                                       autoComplete="email"
                                    />
                                    <Form.Control
                                       type="tel"
                                       placeholder="Téléphone *"
                                       required
                                       pattern="^((\+|00)33\s?|0)[1-9]([\s\.]?\d{2}){4}$"
                                       value={cartUserInfos.phone}
                                       onChange={(e) => {
                                          setCartUserInfos((prevState) => ({
                                             ...prevState,
                                             phone: e.target.value,
                                          }));
                                          setIsErrorUserData(false);
                                       }}
                                       autoComplete="tel"
                                    />
                                 </>
                              )}

                              <Form.Control
                                 as="textarea"
                                 rows={3}
                                 placeholder="Message facultatif"
                                 value={cartUserInfos.userMessage}
                                 onChange={(e) =>
                                    setCartUserInfos((prevState) => ({
                                       ...prevState,
                                       userMessage: e.target.value,
                                    }))
                                 }
                              />

                              {user && (
                                 <Link className="secondary-link cart-page__summary-account" to="/compte">
                                    Modifier mes coordonnées
                                 </Link>
                              )}

                              {isErrorUserData && (
                                 <ErrorMessage isBordered={false}>
                                    Veuillez compléter les champs obligatoires (*)
                                 </ErrorMessage>
                              )}
                              <Button type="submit" className="btn-rounded cart-page__summary-btn">
                                 Valider mes coordonnées
                              </Button>
                           </Form>
                        </>
                     )}
                     {selectedTab === 2 && (
                        <>
                           <div className="cart-page__summary-header">
                              <div className="cart-page__summary-arrow">
                                 <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    onClick={() => {
                                       if (isLoading) {
                                          return;
                                       }
                                       setSelectedTab(1);
                                       scrollTabToTop();
                                    }}
                                 />
                              </div>
                              <h2 className="cart-page__summary-title">Récapitulatif</h2>
                              <div className="cart-page__summary-arrow"></div>
                           </div>

                           <div>
                              <div className="two-columns cart-page__summary-line">
                                 <p>Total</p>
                                 <p className="cart-page__summary-amount">
                                    {formatPrice(cartAmount)}
                                 </p>
                              </div>
                              <div className="two-columns cart-page__summary-line">
                                 <p>E-mail</p>
                                 <p>{cartUserInfos.email}</p>
                              </div>
                              {someMeatCart && (
                                 <div className="two-columns cart-page__summary-line">
                                    <p>Retrait{!onlyMeatCart && " viande bovine"}</p>
                                    <p>
                                       {meatCartDeliveryDate &&
                                          formatJsonDate(meatCartDeliveryDate, true, false, true)}
                                    </p>
                                 </div>
                              )}
                              {!onlyMeatCart && (
                                 <div className="two-columns cart-page__summary-line">
                                    <p>Retrait{someMeatCart && " autres"}</p>
                                    <p>
                                       {cartDeliveryDate &&
                                          formatJsonDate(cartDeliveryDate, true, false, true)}
                                       {" à "}
                                       {cartDeliveryHour &&
                                          formatJsonTime("2023-01-01T" + cartDeliveryHour)}
                                    </p>
                                 </div>
                              )}
                              <Form.Check
                                 className="cart-page__summary-checkbox"
                                 type="checkbox"
                                 id="checkbox-info"
                                 ref={infoCheckBox}
                                 label="Je reconnais que ma réservation ne deviendra définitive qu'après validation par l'équipe de la Ferme du Trèfle Blanc."
                                 disabled={isLoading}
                              />

                              <Form.Check // prettier-ignore
                                 className="cart-page__summary-checkbox"
                                 type="checkbox"
                                 id="checkbox-cgv"
                                 ref={cgvCheckBox}
                                 label={
                                    <span>
                                       J'accepte les{" "}
                                       <Link onClick={() => setShowPolicyModal(true)}>
                                          conditions générales
                                       </Link>{" "}
                                       de réservation.
                                    </span>
                                 }
                                 disabled={isLoading}
                              />
                           </div>
                           {isErrorCheckboxes && (
                              <ErrorMessage isBordered={false}>
                                 Veuillez accepter les conditions ci-dessus.
                              </ErrorMessage>
                           )}

                           {isLoading ? (
                              <Loader />
                           ) : (
                              <Button
                                 className="btn-rounded cart-page__summary-btn"
                                 onClick={() => checkReservation()}
                              >
                                 Envoyer ma demande
                              </Button>
                           )}
                        </>
                     )}
                  </aside>
               </div>
            </>
         )}
         {cart.length === 0 && !reservationSent && (
            <div className="cart-page__empty">
               <p>Aucun produit dans le panier</p>
               <Button
                  onClick={() => {
                     navigate("/produits");
                  }}
                  variant="primary"
                  className="cart-page__start-shopping btn-rounded"
               >
                  <FontAwesomeIcon icon={faBasketShopping} />
                  Commencer mes achats
               </Button>
            </div>
         )}
         {reservationSent && (
            <div className="cart-page__confirmation">
               <div className="cart-page__confirmation-title">
                  <FontAwesomeIcon className="cart-page__confirmation-icon" icon={faCircleCheck} />
                  <h2>Nous avons bien reçu votre demande.</h2>
               </div>
               <p>
                  Vous allez recevoir un e-mail récapitulatif de votre demande de réservation.
                  <br />
                  Notre équipe validera votre demande dans les meilleurs délais et reviendra vers
                  vous.
               </p>
               <OrderWarning />
               <div className="cart-page__confirmation-btn">
                  {user && (
                     <Button
                        className="btn-rounded"
                        variant="secondary"
                        onClick={() => navigate("/compte")}
                     >
                        Accéder à mon compte
                     </Button>
                  )}
               </div>
            </div>
         )}
      </PageLayout>
   );
}

export default Cart;
