import { useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../contexts/userContext";

function UserConnect() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const navigate = useNavigate();
   const [queryParameters] = useSearchParams();
   const { loginUser } = useContext(UserContext);

   // Traite l'envoi du formulaire
   async function handleSubmit(e) {
      // Empèche le rechargement de la page à l'envoi du formulaire
      e.preventDefault();

      const error = await loginUser(email, password);
      if (error) {
         setErrorMessage(error.message);
      } else {
         const redirection = queryParameters.get("redirect");
         if (redirection) {
            navigate(redirection);
         }
      }
   }

   return (
      <div className="user-connect">
         {errorMessage.length > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
         <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Control
               type="email"
               title="Par exemple : utilisateur@domaine.com"
               pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
               placeholder="Adresse e-mail *"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               aria-labelledby="email"
               id="email"
               autoComplete="username"
               required
            />
            <Form.Control
               type="password"
               placeholder="Mot de passe *"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               aria-labelledby="password"
               id="password"
               autoComplete="current-password"
               required
            />

            <Button className="btn-rounded" type="submit">
               Connexion
            </Button>
         </Form>
      </div>
   );
}

export default UserConnect;
