import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function CloseButton({ className, onClick }) {
    return (
        <button className={`close-button ${className ?? ""}`} onClick={onClick}>
            <FontAwesomeIcon icon={faCircleXmark} />
        </button>
    );
}

export default CloseButton;