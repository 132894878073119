import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ErrorMessage from "../../components/ErrorMessage";
import PasswordStrength from "../../components/PasswordStrength";
import PageLayout from "../../layouts/PageLayout";
import UserAccountModal from "../../components/UserAccountModal";
import { calculatePasswordStrength } from "../../helpers";

function AccountCreation() {
   const pageTitle = "Créer un compte";
   const [errorMessage, setErrorMessage] = useState("");
   const [success, setSuccess] = useState(false);
   const [lastName, setLastName] = useState("");
   const [firstName, setFirstName] = useState("");
   const [phone, setPhone] = useState("");
   const [address, setAddress] = useState("");
   const [postalCode, setPostalCode] = useState("");
   const [city, setCity] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [passwordCheck, setPasswordCheck] = useState("");
   const [passwordStrength, setPasswordStrength] = useState(0);
   const [acceptMailing, setAcceptMailing] = useState(false);

   const checkPassword = (e) => {
      // Empêche la saisie d'un espace vide
      if (/\s/.test(e.target.value) === false) {
         switch (e.target.id) {
            case "password":
               setPassword(e.target.value);
               break;
            case "passwordCheck":
               setPasswordCheck(e.target.value);
               break;
            default:
               break;
         }
      }
      setErrorMessage("");
   };

   useEffect(() => {
      // Met à jour l'indcateur de mot de passe
      const strength = calculatePasswordStrength(password);
      setPasswordStrength(strength);
   }, [password]);

   // Traite l'envoi du formulaire
   async function handleSubmit(e) {
      // Empèche le rechargement de la page à l'envoi du formulaire
      e.preventDefault();
      // Affiche un message d'erreur si le MDP ou l'email n'est pas renseigné
      if (
         firstName.length === 0 ||
         lastName.length === 0 ||
         phone.length === 0 ||
         email.length === 0 ||
         password.length === 0 ||
         passwordCheck.length === 0
      ) {
         setErrorMessage("Merci de renseigner tous les champs obligatoires (*)");
         return;
      }
      // Affiche un message d'erreur si les MDP ne correspondent pas
      if (password !== passwordCheck) {
         setErrorMessage("Les mots de passe ne correspondent pas.");
         return;
      }
      // Affiche un message d'erreur si le mot de passe contient un espace
      if (passwordStrength <= 2) {
         setErrorMessage(
            "Veuillez choisir un mot de passe plus complexe (en ajoutant des majuscules, des caractères spéciaux, des nombres...).",
         );
         return;
      }

      const user = { firstName, lastName, phone, email, password, acceptMailing };

      if (address.length > 0) {
         user.address = address;
      }

      if (postalCode.length > 0) {
         user.postalCode = postalCode;
      }

      if (city.length > 0) {
         user.city = city;
      }

      try {
         // Vérifie si les identifiants sont corrects
         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(user),
         });

         const data = await response.json();

         if (response.status === 201) {
            setSuccess(true);
            window.scroll(0, 0);
         } else {
            setErrorMessage(data.message ?? "Impossible de créer le compte. Réessayez plus tard.");
         }
      } catch (error) {
         setErrorMessage(error);
      }
   }

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="account-creation"
         breadCrumbItems={[{ title: "Compte", url: "/compte" }, { title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>
         <div className="account-creation__content">
            {!success ? (
               <div className="white-block">
                  <p className="account-creation__group">
                     Pour créer votre compte, veuillez remplir tous les champs obligatoires (*).
                  </p>
                  <Form onSubmit={(e) => handleSubmit(e)}>
                     <div className="account-creation__group">
                        <h2>Mes informations</h2>
                        <Form.Control
                           type="text"
                           placeholder="Nom *"
                           value={lastName}
                           onChange={(e) => {
                              setLastName(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Nom"
                           id="lastName"
                           autoComplete="family-name"
                           required
                        />
                        <Form.Control
                           type="text"
                           placeholder="Prénom *"
                           value={firstName}
                           onChange={(e) => {
                              setFirstName(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Prénom"
                           autoComplete="given-name"
                           id="firstName"
                           required
                        />
                        <Form.Control
                           type="tel"
                           placeholder="Téléphone *"
                           pattern="^((\+|00)33\s?|0)[1-9]([\s\.]?\d{2}){4}$"
                           value={phone}
                           onChange={(e) => {
                              setPhone(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Téléphone"
                           autoComplete="tel"
                           id="phone"
                           required
                        />
                        <Form.Control
                           type="text"
                           placeholder="Adresse"
                           value={address}
                           onChange={(e) => {
                              setAddress(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Adresse"
                           id="address"
                           autoComplete="street-address"
                        />
                        <Form.Control
                           type="text"
                           placeholder="Code postal"
                           pattern="[0-9]{5}"
                           value={postalCode}
                           onChange={(e) => {
                              setPostalCode(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Code postal"
                           id="postalCode"
                           autoComplete="postal-code"
                        />
                        <Form.Control
                           type="text"
                           placeholder="Ville"
                           value={city}
                           onChange={(e) => {
                              setCity(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="Ville"
                           id="city"
                           autoComplete="home city"
                        />
                     </div>
                     <div className="account-creation__group">
                        <h2>Mes identifiants</h2>
                        <Form.Control
                           type="email"
                           title="Par exemple : utilisateur@domaine.com"
                           pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                           placeholder="Adresse e-mail *"
                           value={email}
                           onChange={(e) => {
                              setEmail(e.target.value);
                              setErrorMessage("");
                           }}
                           aria-label="E-mail"
                           id="email"
                           autoComplete="username"
                           required
                        />
                        <Form.Control
                           type="password"
                           placeholder="Mot de passe *"
                           value={password}
                           onChange={(e) => checkPassword(e)}
                           aria-labelledby="password"
                           id="password"
                           autoComplete="new-password"
                           required
                        />

                        <Form.Control
                           type="password"
                           placeholder="Confirmer votre mot de passe *"
                           value={passwordCheck}
                           onChange={(e) => checkPassword(e)}
                           aria-labelledby="passwordCheck"
                           id="passwordCheck"
                           autoComplete="new-password"
                           required
                        />

                        <PasswordStrength strength={passwordStrength} />
                     </div>
                     <div className="account-creation__group">
                        <h2>Le Trèfle Blanc et moi</h2>
                        <Form.Check
                           checked={acceptMailing}
                           onChange={(e) => {
                              setAcceptMailing(e.target.checked);
                           }}
                           label="Je souhaite recevoir des informations et offres sur les produits. Vous pouvez vous désinscrire à tout moment."
                           id="acceptMailing"
                           type="checkbox"
                        />
                     </div>
                     {errorMessage && errorMessage.length > 0 && (
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                     )}
                     <Button className="btn-rounded" type="submit">
                        Créer mon compte
                     </Button>
                  </Form>
                  <p className="account-creation__footer">
                     Vos données personnelles sont collectées pour assurer la gestion de vos
                     réservations et vous faire bénéficier d’offres et avantages sur les produits et
                     services proposés par le magasin du Trèfle Blanc. À aucun moment, vos données
                     ne seront utilisées ou revendues à un tiers.
                  </p>
               </div>
            ) : (
               <>
                  <div className="white-block">
                     <h2>Veuillez confirmer votre adresse e-mail.</h2>
                     <p>
                        Nous venons de vous envoyer un message sur votre adresse e-mail, merci de
                        cliquer sur le lien à l'intérieur du message pour confirmer la création de
                        votre compte.
                        <br />
                        <br />
                        <strong>
                           Note : pensez à bien vérifier le dossier "indésirables" de votre
                           messagerie.{" "}
                        </strong>
                     </p>
                  </div>
                  <UserAccountModal forgottenMode={false} />
               </>
            )}
         </div>
      </PageLayout>
   );
}

export default AccountCreation;
