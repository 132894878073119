import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
   const newsPerPage = 5;

   const [news, setNews] = useState([]);
   const [allCount, setAllCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [categoriesSelected, setCategoriesSelected] = useState([]);

   const pagesNumber = useMemo(() => {
      return Math.ceil(allCount / newsPerPage);
   }, [allCount]);

   const previousPage = useCallback(() => {
      setCurrentPage(Math.max(currentPage - 1, 1));
   }, [currentPage]);

   const nextPage = useCallback(() => {
      setCurrentPage(Math.min(currentPage + 1, pagesNumber));
   }, [currentPage, pagesNumber]);

   useEffect(() => {
      setCurrentPage(1);
   }, [categoriesSelected]);

   const loadNewsData = useCallback(async () => {
      try {
         const offset = (currentPage - 1) * newsPerPage;
         const response = await fetch(
            `${
               process.env.REACT_APP_API_URL
            }/api/news/?offset=${offset}&limit=${newsPerPage}&categories=${categoriesSelected.join(
               ",",
            )}`,
         );
         if (!response.ok) throw new Error(response.statusText);
         const data = await response.json();
         setIsLoading(false);
         setNews(data.news);
         setAllCount(data.all_count);
         setError(null);
      } catch (error) {
         setError(`${error} Could not Fetch Data `);
         setIsLoading(false);
      }
   }, [currentPage, categoriesSelected]);

   useEffect(() => {
      loadNewsData();
   }, [loadNewsData, currentPage]);

   return (
      <NewsContext.Provider
         value={{
            news,
            pagesNumber,
            error,
            isLoading,
            categoriesSelected,
            setCategoriesSelected,
            loadNewsData,
            previousPage,
            nextPage,
            currentPage,
            setCurrentPage,
         }}
      >
         {children}
      </NewsContext.Provider>
   );
};

export const NewsContextLayout = () => {
   return (
      <NewsProvider>
         <Outlet />
      </NewsProvider>
   );
};
