import { useContext } from "react";
import { Link } from "react-router-dom";
import { SettingsContext } from "../../contexts/settingsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faCow, faLeaf } from "@fortawesome/free-solid-svg-icons";
import OpeningHours from "../OpeningHours";

function Footer() {
   const { categories, aboutPages } = useContext(SettingsContext);

   return (
      <footer className="footer">
         <div className="footer__container page-container">
            <div className="footer__blocks">
               <div className="footer__block">
                  <FontAwesomeIcon icon={faCalendarCheck} className="footer__fa" />
                  <p>Un savoir faire depuis 1997</p>
               </div>
               <div className="footer__block">
                  <FontAwesomeIcon icon={faLeaf} className="footer__fa" />
                  <p>Certification biologique</p>
               </div>
               <div className="footer__block">
                  <FontAwesomeIcon icon={faCow} className="footer__fa" />
                  <p>Un troupeau de 80 vaches</p>
               </div>
            </div>
            <div className="footer__main">
               <div className="footer__company">
                  <h4>MAGASIN DU TRÈFLE BLANC</h4>
                  <p className="footer__address">
                     6 route du Saz - 44240 La Chapelle-sur-Erdre <br />
                     Téléphone : 02 55 11 94 66
                  </p>

                  <h4>HORAIRES D'OUVERTURE</h4>
                  <OpeningHours />
               </div>
               <div className="footer__pages">
                  <div>
                     <h4>PRODUITS</h4>
                     <div className="footer__links">
                        {categories.map((category, index) => (
                           <Link
                              key={`category-footer-${index}`}
                              to={category.url}
                              onClick={() => window.scroll(0, 0)}
                           >
                              {category.title}
                           </Link>
                        ))}
                        <Link to="/panier" onClick={() => window.scroll(0, 0)}>
                           Mon panier
                        </Link>
                     </div>
                  </div>
                  <div>
                     <h4>QUI SOMMES-NOUS</h4>
                     <div className="footer__links">
                        {aboutPages.map((page, index) => (
                           <Link
                              to={page.url}
                              key={`about-${index}`}
                              onClick={() => window.scroll(0, 0)}
                           >
                              {page.title}
                           </Link>
                        ))}
                        <Link to="/actualites" onClick={() => window.scroll(0, 0)}>
                           Actualités
                        </Link>
                     </div>
                  </div>
                  <div>
                     <h4>CONTACT</h4>
                     <div className="footer__links">
                        <Link to="/compte" onClick={() => window.scroll(0, 0)}>
                           Mon compte
                        </Link>
                        <Link to="/contact" onClick={() => window.scroll(0, 0)}>
                           Nous contacter
                        </Link>
                        <Link to="/conditions-generales" onClick={() => window.scroll(0, 0)}>
                           Conditions générales
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="footer__separator"></div>
         <div className="footer__bottom page-container ">
            <div className="two-columns two-columns--vertical-centered">
               <p>© {new Date().getFullYear()} Tous droits réservés, Ferme du Trèfle Blanc</p>
               <p>|</p>
               <Link to="/mentions-legales">Mentions légales</Link>
            </div>
            <div className="footer__social-network">
               <Link
                  to="https://www.facebook.com/FermeduTrefleBlanc/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <img
                     src="/assets/icons/facebook.png"
                     srcSet="/assets/icons/facebook@2x.png 2x"
                     alt="Facebook"
                     width="30"
                     height="34"
                  />
               </Link>
               <Link
                  to="https://www.instagram.com/fermedutrefleblanc/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <img
                     src="/assets/icons/insta.png"
                     srcSet="/assets/icons/insta@2x.png 2x"
                     alt="Instagram"
                     width="30"
                     height="34"
                  />
               </Link>
            </div>
         </div>
      </footer>
   );
}

export default Footer;
