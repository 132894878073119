import { createContext, useCallback, useEffect, useState } from "react";
import { sendDataAPI } from "../helpers";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
   const [cartModalIsOpen, setCartModalIsOpen] = useState(false);
   const [cart, setCart] = useState([]);
   const [cartAmount, setCartAmount] = useState(0);
   const [cartItemsNumber, setCartItemsNumber] = useState(0);
   const [meatCartDeliveryDate, setMeatCartDeliveryDate] = useState("");
   const [cartDeliveryDate, setCartDeliveryDate] = useState("");
   const [cartDeliveryHour, setCartDeliveryHour] = useState("");
   const [cartUserInfos, setCartUserInfos] = useState({});

   const addToCart = (product, quantityToAdd, variant) => {
      const quantity = quantityToAdd ?? 1;
      let newCart = Array.from(cart);

      // Recherche du produit dans le panier en cours
      const foundProduct = newCart.find((item) => {
         if (variant) {
            return (
               item.id === product.id &&
               item.measurementValue === variant.measurementValue &&
               item.measurementUnit === variant.measurementUnit
            );
         }
         return item.id === product.id;
      });

      if (foundProduct) {
         // Ajout d'une quantité
         foundProduct.quantity += quantity;
      } else {
         const newProduct = { ...product, quantity: quantity };
         if (variant) {
            // Remplace le poid et prix par celui de la variante
            newProduct.price = variant.price;
            newProduct.measurementValue = variant.measurementValue;
            newProduct.measurementUnit = variant.measurementUnit;
         }
         newCart.push(newProduct);
      }

      // enregistre le panier entier dans le useState
      setCart(newCart);
      // enregistre le panier entier dans le LocalStorage pour une éventuelle réstauration
      window.localStorage.setItem("cart", JSON.stringify(newCart));
      setModified(true);
   };

   const removeFromCart = (product, quantityToRemove, variant) => {
      let newCart = Array.from(cart);
      const productIndex = newCart.findIndex((itemCart) => {
         if (variant) {
            return (
               itemCart.id === product.id &&
               itemCart.measurementValue === variant.measurementValue &&
               itemCart.measurementUnit === variant.measurementUnit
            );
         }
         return itemCart.id === product.id;
      });

      // le produit cherché n'existe plus
      if (productIndex === -1) {
         return;
      }

      if (!quantityToRemove || newCart[productIndex].quantity <= quantityToRemove) {
         // Suppression du produit du panier
         newCart.splice(productIndex, 1);
      } else {
         newCart[productIndex].quantity -= quantityToRemove;
      }

      // enregistre le panier entier dans le useState
      setCart(newCart);
      // enregistre le panier entier dans le LocalStorage pour une éventuelle réstauration
      window.localStorage.setItem("cart", JSON.stringify(newCart));
      setModified();
   };

   const resetCart = () => {
      setCart([]);
      setMeatCartDeliveryDate("");
      setCartDeliveryDate("");
      setCartDeliveryHour("");
      setCartUserInfos({});
      // enregistre le panier entier dans le LocalStorage pour une éventuelle réstauration
      window.localStorage.removeItem("cart");
      window.localStorage.removeItem("cartModified");
   };

   const setModified = () => {
      const now = new Date();
      window.localStorage.setItem("cartModified", JSON.stringify(now));
   }

   const checkCart = useCallback(async (products) => {
      let newCart = Array.from(products);
      let productIds = newCart.map((product) => product.id);
      const { success, data } = await sendDataAPI("products/check", "POST", { productIds: productIds });
      if (success) {
         for (let i = newCart.length - 1; i >= 0; i--) {
            const found = data.find(item => item.id === newCart[i].id);
            if (found) {
               if (!found.enabled || found.onlyInStore) {
                  newCart.splice(i, 1);
               } else {
                  newCart[i].title = found.title;
               }
            }
         }
         setCart(newCart);
         setModified();
      }
   }, [])

   useEffect(() => {
      const previousCart = window.localStorage.getItem("cart");
      if (previousCart) {
         const parsed = JSON.parse(previousCart);
         const dateString = window.localStorage.getItem("cartModified");
         if (dateString) {
            const date = new Date(JSON.parse(dateString));
            const diff = Math.round((new Date() - date) / 86400000);
            if (diff > 7) {
               resetCart();
            } else {
               setCart(parsed);
            }
         } else {
            setCart(parsed);
         }
         checkCart(parsed);
      }
   }, [checkCart]);

   useEffect(() => {
      let totalPrice = 0;
      let totalItems = 0;
      for (let item of cart) {
         totalPrice += item.price * item.quantity;
         totalItems += item.quantity;
      }
      setCartAmount(totalPrice);
      setCartItemsNumber(totalItems);
   }, [cart]);

   useEffect(() => {
      document.body.style.overflow = cartModalIsOpen ? "hidden" : "auto";
   }, [cartModalIsOpen]);

   return (
      <CartContext.Provider
         value={{
            cartModalIsOpen,
            setCartModalIsOpen,
            cart,
            addToCart,
            removeFromCart,
            cartAmount,
            cartItemsNumber,
            resetCart,
            cartDeliveryDate,
            cartDeliveryHour,
            setCartDeliveryDate,
            setCartDeliveryHour,
            meatCartDeliveryDate,
            setMeatCartDeliveryDate,
            cartUserInfos,
            setCartUserInfos,
         }}
      >
         {children}
      </CartContext.Provider>
   );
};
