import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { UserContext } from "../../contexts/userContext";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { formatJsonDate } from "../../helpers";
import Loader from "../../components/Loader";
import PageLayout from "../../layouts/PageLayout";
import ReactMarkdown from "react-markdown";
import EditNewsModal from "../../components/EditNewsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import ErrorBlock from "../../components/ErrorBlock";
import { NewsContext } from "../../contexts/newsContext";
import Tag from "../../components/Tag";
import removeMarkdown from "remove-markdown";
import { truncateString } from "../../helpers";

function NewsArticle() {
   const { newsId } = useParams();
   const [data, isLoading, error, reloadData] = useFetch(`news/${newsId.split("-").pop()}`);
   const [showEditModal, setShowEditModal] = useState(false);
   const { user } = useContext(UserContext);
   const { loadNewsData } = useContext(NewsContext);

   const shareTo = (socialMedia) => {
      // https://twitter.com/intent/tweet?url=${escapedUrl}&text=${text}`;
      // https://www.facebook.com/sharer.php?u=https:example.com?imageurl=${this.state.imageUrl}}
      const escapedUrl = encodeURIComponent(window.location.href);
      switch (socialMedia) {
         case "facebook":
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${escapedUrl}`, "_blank");
            break;
         case "twitter":
            const md = removeMarkdown(data.text);
            const trunc = truncateString(md, 150);
            window.open(`https://twitter.com/intent/tweet?url=${escapedUrl}&text=${encodeURI(trunc)}`, "_blank");
            break;
         default:
            break;
      }
   };

   if (isLoading || !data) {
      return (
         <PageLayout pageTitle="Chargement..." className="news-article">
            <Loader variant="full" />
         </PageLayout>
      );
   } else if (error) {
      return (
         <PageLayout
            pageTitle="Erreur 404"
            className="news-article"
            breadCrumbItems={[{ title: "Actualités", url: "/actualites" }]}
         >
            <ErrorBlock code={404} />
         </PageLayout>
      );
   }

   return (
      <PageLayout
         pageTitle={data.title}
         className="news-article"
         breadCrumbItems={[{ title: "Actualités", url: "/actualites" }, { title: data.title }]}
      >
         <div className="news-article__banner">
            <picture>
               {data.imageUrl
                  .filter((item) => !item.thumbnail)
                  .map((image, index) =>
                     image.fallback ? (
                        <img
                           key={`banner-image-${index}`}
                           className="d-block w-100 object-fit-cover"
                           src={image.url}
                           alt=""
                        />
                     ) : (
                        <source
                           key={`banner-image-${index}`}
                           srcSet={image.url}
                           type={`image/${image.type}`}
                        />
                     ),
                  )}
            </picture>
         </div>
         <div className="news-article__header">
            {user?.isAdmin && (
               <div
                  className="edit-button"
                  onClick={() => {
                     setShowEditModal(true);
                  }}
               >
                  <FontAwesomeIcon icon={faPenToSquare} />
               </div>
            )}
            <h1 className="page-title">{data.title}</h1>
            {data.eventDate && (
               <h2 className="news-article__event-date">
                  <FontAwesomeIcon icon={faCalendarDays} />{" "}
                  {formatJsonDate(data.eventDate, false, data.showTime)}
               </h2>
            )}
         </div>
         <div className="news-article__text markdown">
            <ReactMarkdown>{data.text}</ReactMarkdown>
         </div>
         <div className="news-article__share">
            <DropdownButton align="end" id="dropdown-share" title="Partager">
               <Dropdown.Item onClick={() => shareTo("facebook")}>Facebook</Dropdown.Item>
               <Dropdown.Item onClick={() => shareTo("twitter")}>Twitter / X</Dropdown.Item>
            </DropdownButton>
         </div>
         <div className="news-article__footer">
            <p className="news-article__date">Publié le {formatJsonDate(data.createdAt)}</p>
            <Tag title={data.NewsCategory.title} />
         </div>
         {user?.isAdmin && (
            <EditNewsModal
               show={showEditModal}
               onClose={() => {
                  setShowEditModal(false);
                  reloadData();
                  loadNewsData();
               }}
               news={data}
            />
         )}
      </PageLayout>
   );
}

export default NewsArticle;
