import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useMemo } from "react";
import Loader from "../../components/Loader";
import Button from "react-bootstrap/Button";
import { CartContext } from "../../contexts/cartContext";
import { SettingsContext } from "../../contexts/settingsContext";
import { formatPrice, formatMeasurement, formatPricePerUnit, formatJsonDate } from "../../helpers";
import PageLayout from "../../layouts/PageLayout";
import ErrorPage from "../ErrorPage";
import Tag from "../../components/Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping, faCircleInfo, faUtensils } from "@fortawesome/free-solid-svg-icons";

function ProductInfo() {
   const { productId } = useParams();
   const [isError, setIsError] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [product, setProduct] = useState();
   const { cart, addToCart, removeFromCart } = useContext(CartContext);
   const { nextMeatSale } = useContext(SettingsContext);

   useEffect(() => {
      async function loadData() {
         try {
            const productIdSplitted = productId.split("-").pop();
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}/api/products/${productIdSplitted}`,
            );
            const data = await response.json();
            if (data.error) {
               setIsError(true);
            } else {
               setProduct(data);
            }
         } catch (err) {
            setIsError(true);
         }
         setIsLoading(false);
      }
      loadData();
   }, [productId]);

   // Reconstitue les variantes du produit
   const productVariants = useMemo(() => {
      let variants = [];
      if (product) {
         const productIdSplitted = productId.split("-").pop();
         if (product.ProductVariants.length === 0) {
            const cartItem = cart.find((item) => item.id === parseInt(productIdSplitted));
            variants.push({
               measurementValue: product.measurementValue,
               measurementUnit: product.measurementUnit,
               price: product.price,
               quantity: cartItem ? cartItem.quantity : 0,
            });
         } else {
            for (let variant of product.ProductVariants) {
               const cartItem = cart.find(
                  (item) =>
                     item.id === parseInt(productIdSplitted) &&
                     item.measurementValue === variant.measurementValue &&
                     item.measurementUnit === variant.measurementUnit,
               );
               variants.push({ ...variant, quantity: cartItem ? cartItem.quantity : 0 });
            }
            const cartItem = cart.find(
               (item) =>
                  item.id === parseInt(productIdSplitted) &&
                  item.measurementValue === product.measurementValue &&
                  item.measurementUnit === product.measurementUnit,
            );
            variants.push({
               measurementValue: product.measurementValue,
               measurementUnit: product.measurementUnit,
               price: product.price,
               quantity: cartItem ? cartItem.quantity : 0,
            });
         }
         variants.sort((a, b) => a.measurementValue - b.measurementValue);
      }
      return variants;
   }, [product, cart, productId]);

   if (isError) {
      return <ErrorPage />;
   } else if (isLoading) {
      return (
         <PageLayout pageTitle="Chargement..." className="product-info">
            <Loader variant="full" />
         </PageLayout>
      );
   }

   return (
      <PageLayout
         pageTitle={product.title}
         className="product-info"
         breadCrumbItems={[
            { title: "Produits", url: "/produits" },
            { title: product.ProductCategory.title, url: product.ProductCategory.url },
            { title: product.title },
         ]}
      >
         <div className="product-info__content">
            <div className="product-info__image">
               {product.highlighted && <div className="product-info__highlighted">Nouveauté</div>}
               <picture>
                  {product.organic && (
                     <img
                        className="product-info__organic-logo"
                        src="/assets/logos/bio.png"
                        srcSet="/assets/logos/bio@2x.png 2x"
                        alt="Biologique"
                     />
                  )}
                  {product.imageUrl
                     .filter((item) => !item.thumbnail)
                     .map((image, index) =>
                        image.fallback ? (
                           <img
                              key={`source-${index}`}
                              className="product-info__photo"
                              src={image.url}
                              alt={product.title}
                           />
                        ) : (
                           <source
                              key={`source-${index}`}
                              srcSet={image.url}
                              type={`image/${image.type}`}
                           />
                        ),
                     )}
               </picture>
            </div>
            <div className="product-info__details">
               <h1 className="product-info__title">{product.title}</h1>
               {nextMeatSale && product.ProductCategory.type === "meat" && (
                  <Tag
                     variant="danger"
                     title={`Retrait à partir du ${formatJsonDate(nextMeatSale, true, true)}`}
                  />
               )}
               <p className="product-info__description">{product.description}</p>

               {productVariants.map((variant, index) => (
                  <div className="product-info__price-quantity" key={`price-${index}`}>
                     {productVariants.length > 1 && (
                        <div className="product-info__variant">
                           {product.approximativeMeasurement && (
                              <span className="product-info__approximative">ENVIRON</span>
                           )}
                           <span>
                              {formatMeasurement(variant.measurementValue, variant.measurementUnit)}
                           </span>
                        </div>
                     )}
                     <div className="product-info__price">
                        <span>
                           {formatPricePerUnit(
                              variant.price,
                              variant.measurementValue,
                              variant.measurementUnit,
                           )}
                        </span>
                        <span>{formatPrice(variant.price)}</span>
                     </div>
                     {product.ProductCategory.type === "meat" && !nextMeatSale ? (
                        <span className="product-info__not-bookable">
                           Non réservable pour le moment.
                           <br />
                           Disponible en magasin selon stock.
                        </span>
                     ) : product.onlyInStore ? (
                        <span className="product-info__not-bookable">
                           Ce produit ne peut pas être réservé.
                           <br />
                           Disponible en magasin selon stock.
                        </span>
                     ) : (
                        <div className="product-info__quantity">
                           {variant.quantity > 0 && (
                              <div className="quantity-selector">
                                 <button
                                    onClick={() => {
                                       if (productVariants.length > 0) {
                                          removeFromCart(product, 1, variant);
                                       } else {
                                          removeFromCart(product, 1);
                                       }
                                    }}
                                 >
                                    –
                                 </button>
                                 <span>
                                    {variant.quantity}
                                 </span>
                                 <button
                                    onClick={() => {
                                       if (productVariants.length > 0) {
                                          addToCart(product, 1, variant);
                                       } else {
                                          addToCart(product, 1);
                                       }
                                    }}
                                 >
                                    +
                                 </button>
                              </div>
                           )}

                           {variant.quantity === 0 && (
                              <Button
                                 className="product-info__add btn-rounded"
                                 variant="primary"
                                 onClick={() => {
                                    if (productVariants.length > 0) {
                                       addToCart(product, 1, variant);
                                    } else {
                                       addToCart(product, 1);
                                    }
                                 }}
                              >
                                 <span>Ajouter au panier</span>
                                 <FontAwesomeIcon icon={faBasketShopping} />
                              </Button>
                           )}
                        </div>
                     )}
                  </div>
               ))}

               <h2><FontAwesomeIcon icon={faCircleInfo} /> Informations sur le produit</h2>
               {productVariants.length === 1 && (
                  <p>
                     {product.measurementUnit === "g" ? "Poids net" : "Volume net"} :{" "}
                     {product.approximativeMeasurement && "environ "}
                     {formatMeasurement(product.measurementValue, product.measurementUnit)}
                  </p>
               )}
               {product.Ingredients.length > 0 && (
                  <p>
                     Ingrédients :{" "}
                     {product.Ingredients.map((ingredient) => ingredient.title).join(", ")}
                  </p>
               )}
               {product.Allergens.length > 0 && (
                  <p>
                     Allergènes : {product.Allergens.map((allergen) => allergen.title).join(", ")}
                  </p>
               )}
               {product.organic && <p>Produit certifié Agriculture Biologique</p>}
               {product.recipe && (
                  <>
                     <h2><FontAwesomeIcon icon={faUtensils} /> Idée recette</h2>
                     <p>{product.recipe}</p>
                  </>
               )}
            </div>
         </div>
      </PageLayout>
   );
}

export default ProductInfo;
