import { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import PageLayout from "../../layouts/PageLayout";
import Button from "react-bootstrap/Button";
import UserInfo from "../../components/UserInfo";
import UserOrders from "../../components/UserOrders";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import UserConnect from "../../components/UserConnect";
import UserAccountModal from "../../components/UserAccountModal";
import { CartContext } from "../../contexts/cartContext";

/**
 * Composant affichant la page "mon compte"
 * @returns JSX.element
 */
function Account() {
   const pageTitle = "Compte";
   const [errorMessage, setErrorMessage] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [queryParameters] = useSearchParams();
   const navigate = useNavigate();
   const { user, disconnectUser, loadUserData } = useContext(UserContext);
   const { setCartUserInfos } = useContext(CartContext);

   // À chaque changement du useState "user", on récupère les infos de l'utilisateur depuis l'API
   useEffect(() => {
      setErrorMessage("");
      setIsLoading(true);
      const fetchData = async () => {
         await loadUserData();
         setIsLoading(false);
      };
      fetchData();
   }, [loadUserData]);

   useEffect(() => {
      const redirection = queryParameters.get("redirect");
      if (user && redirection) {
         navigate(redirection);
      }
   }, [user, navigate, queryParameters]);


   if (queryParameters.get("deleted")) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="account"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <div className="account__header">
               <h1 className="page-title">Suppression de compte</h1>
               <p>Votre compte a bien été supprimé.</p>
            </div>
         </PageLayout>
      );
   }


   return (
      <PageLayout
         pageTitle={pageTitle}
         className="account"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         {user ? (
            <>
               <div className="account__header">
                  <h1 className="page-title">Mon compte</h1>
                  {!isLoading && (
                     <Button
                        className="btn-rounded"
                        onClick={() => {
                           disconnectUser();
                           setCartUserInfos({});
                           window.scroll(0, 0);
                        }}
                        variant="secondary"
                     >
                        Se déconnecter
                     </Button>
                  )}
                  {errorMessage.length > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
               </div>

               {isLoading && <Loader variant="full" />}
               {!isLoading && (
                  <div className="account__content two-columns">
                     <UserOrders className="account__orders" />
                     <UserInfo className="account__infos" />
                  </div>
               )}
            </>
         ) : (
            <>
               <h1 className="page-title">S'identifier</h1>
               <div className="account__identification">
                  <div className="account__connect white-block">
                     <h2>J'ai déjà un compte</h2>
                     <UserConnect />
                  </div>

                  <div className="account__separator">
                     <div></div>
                     <div>OU</div>
                     <div></div>
                  </div>
                  <div className="account__create white-block">
                     <h2>Je crée mon compte</h2>
                     <p>
                        Créez votre compte maintenant pour gagner du temps lors de vos prochaines
                        réservations et retrouver votre historique.
                     </p>
                     <Button className="btn-rounded" onClick={() => navigate("/compte/creation")}>
                        Créer mon compte
                     </Button>
                  </div>
                  <div className="account__actions">
                     <UserAccountModal forgottenMode={true} />
                     <UserAccountModal forgottenMode={false} />
                  </div>
               </div>
            </>
         )}
      </PageLayout>
   );
}

export default Account;
