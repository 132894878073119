import { SettingsContext } from "../../contexts/settingsContext";
import { CartContext } from "../../contexts/cartContext";
import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { formatPrice, formatMeasurement, groupedBy } from "../../helpers";
import Button from "react-bootstrap/Button";
import CloseButton from "../CloseButton";

/**
 * Modale latérale affichange le contenu du panier simplifié
 * @return JSX.element
 */

function CartModal() {
   const { categoriesNames } = useContext(SettingsContext);
   const { cartModalIsOpen, setCartModalIsOpen, cart, addToCart, removeFromCart, cartAmount } =
      useContext(CartContext);
   const navigate = useNavigate();

   const groupedCart = groupedBy(cart, "ProductCategoryId");

   return (
      <aside
         className={`menu cart-modal${cartModalIsOpen ? "" : " cart-modal--isHidden"}`}
         onClick={() => setCartModalIsOpen(false)}
      >
         <div className={`menu__content ${cartModalIsOpen ? "visible" : "invisible"}`} onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setCartModalIsOpen(false)} />
            <h3 className="cart-modal__title">Panier de réservation</h3>
            <div
               className={`cart-modal__main ${cart.length === 0 ? "cart-modal__main--empty" : ""}`}
            >
               {cart.length === 0 && (
                  <>
                     <p>Aucun produit dans le panier</p>
                     <Button
                        onClick={() => {
                           setCartModalIsOpen(false);
                           navigate("/produits");
                        }}
                        variant="primary"
                        className="cart-modal__start-shopping btn-rounded"
                     >
                        <FontAwesomeIcon icon={faBasketShopping} />
                        Commencer mes achats
                     </Button>
                  </>
               )}

               {cart.length > 0 &&
                  Object.keys(groupedCart).map((groupKey, index) => (
                     <div className="cart-modal__group" key={`category-cart-${index}`}>
                        <h4 className="cart-modal__group-title">{categoriesNames[groupKey]}</h4>
                        <div className="cart-modal__group-items">
                           {groupedCart[groupKey].map((product, index) => (
                              <div className="cart-modal__item" key={`product-cart-${index}`}>
                                 <p className="cart-modal__item-title">
                                    <Link
                                       to={`${product.ProductCategory.url}/${product.id}`}
                                       onClick={() => setCartModalIsOpen(false)}
                                    >
                                       {product.title}{" "}
                                       {(product.showMeasurement ||
                                          product.ProductVariants.length > 0) && (
                                          <>
                                             <span>
                                                {" "}
                                                •{" "}
                                                {formatMeasurement(
                                                   product.measurementValue,
                                                   product.measurementUnit,
                                                )}
                                             </span>
                                             {product.approximativeMeasurement && (
                                                <span> environ</span>
                                             )}
                                          </>
                                       )}
                                    </Link>
                                 </p>
                                 <Button
                                    className="btn-rounded"
                                    onClick={() =>
                                       removeFromCart(product, 1, {
                                          price: product.price,
                                          measurementValue: product.measurementValue,
                                          measurementUnit: product.measurementUnit,
                                       })
                                    }
                                    variant="secondary"
                                    size="sm"
                                 >
                                    -
                                 </Button>
                                 <p className="cart-modal__item-quantity">{product.quantity}</p>
                                 <Button
                                    className="btn-rounded"
                                    onClick={() =>
                                       addToCart(product, 1, {
                                          price: product.price,
                                          measurementValue: product.measurementValue,
                                          measurementUnit: product.measurementUnit,
                                       })
                                    }
                                    variant="secondary"
                                    size="sm"
                                 >
                                    +
                                 </Button>
                                 <FontAwesomeIcon
                                    className="cart-modal__item-trash"
                                    onClick={() =>
                                       removeFromCart(product, undefined, {
                                          price: product.price,
                                          measurementValue: product.measurementValue,
                                          measurementUnit: product.measurementUnit,
                                       })
                                    }
                                    icon={faTrash}
                                 />
                              </div>
                           ))}
                        </div>
                     </div>
                  ))}
            </div>
            {cart.length > 0 && (
               <Button
                  onClick={() => {
                     setCartModalIsOpen(false);
                     navigate("/panier");
                  }}
                  variant="primary"
                  className="cart-modal__show-cart btn-rounded"
               >
                  Voir mon panier ({formatPrice(cartAmount)})
               </Button>
            )}
         </div>
      </aside>
   );
}

export default CartModal;
