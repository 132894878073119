import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import MarkdownEditor from "../MarkdownEditor";
function Hero({ title, subtitle, text, buttons, imageUrl, imageAlt }) {
   const [isEditMode, setIsEditMode] = useState(false);
   const navigate = useNavigate();
   const { user } = useContext(UserContext);
   const { modifySettings } = useContext(SettingsContext);

   if (isEditMode) {
      return (
         <section className="hero">
            <div className="page-container">
               <HeroEdition
                  onSave={(newText) => {
                     modifySettings({ homeHero: newText });
                     setIsEditMode(false);
                  }}
                  onCancel={() => setIsEditMode(false)}
                  initialText={text}
               />
            </div>
         </section>
      );
   }

   return (
      <section className="hero">
         <div className="page-container">
            {user?.isAdmin && (
               <div className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
                  <FontAwesomeIcon icon={faPenToSquare} />
               </div>
            )}

            {title && <h1 className="hero__title">{title}</h1>}
            {subtitle && <h2 className="hero__subtitle">{subtitle}</h2>}

            {text && (
               <div className="hero__text">
                  <ReactMarkdown>{text}</ReactMarkdown>
               </div>
            )}
            {buttons && (
               <div className="hero__buttons">
                  {buttons.map((button, index) => (
                     <Button
                        onClick={() => navigate(button.url)}
                        variant={button.variant ?? "primary"}
                        key={`bouton-${index}`}
                        className="btn-rounded"
                     >
                        {button.title}
                     </Button>
                  ))}
               </div>
            )}
            {imageUrl && <img className="hero__image" src={imageUrl} alt={imageAlt} />}
         </div>
      </section>
   );
}

Hero.propTypes = {
   title: PropTypes.string,
   subtitle: PropTypes.string,
   text: PropTypes.string,
   buttons: PropTypes.array,
   imageUrl: PropTypes.string,
   imageAlt: PropTypes.string,
};

function HeroEdition({ onCancel, onSave, initialText }) {
   const [text, setText] = useState("");

   useEffect(() => {
      setText(initialText);
   }, [initialText]);

   return (
      <div>
         <MarkdownEditor markdown={initialText} onChange={(newText) => setText(newText)} />
         <div className="hero__buttons">
            <Button variant="secondary" onClick={onCancel}>
               Annuler
            </Button>
            <Button variant="dark" onClick={() => onSave(text)}>
               Enregistrer
            </Button>
         </div>
      </div>
   );
}

export default Hero;
