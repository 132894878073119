import { useEffect, useContext, useState, useCallback, useMemo, Fragment } from "react";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import PageLayout from "../../layouts/PageLayout";
import OrderAccordion from "../../components/OrderAccordion";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { formatJsonDate, groupedBy, formatJsonTime, formatMeasurement } from "../../helpers";
import { Link, useNavigate } from "react-router-dom";
import { faTriangleExclamation, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ErrorBlock from "../../components/ErrorBlock";
import { sendDataAPI } from "../../helpers";

function AdminOrders() {
   const pageTitle = "Réservations";
   const [isLoadingData, setIsLoadingData] = useState(true);
   const [isErrorData, setIsErrorData] = useState(false);
   const [orders, setOrders] = useState({ pending: [], others: [], others_count: 0 });
   const [offsetOthers, setOffsetOthers] = useState(0);
   const [selectedViewIndex, setSelectedViewIndex] = useState(0);
   const [showSearch, setShowSearch] = useState(false);
   const [searchInput, setSearchInput] = useState("");
   const { user } = useContext(UserContext);
   const navigate = useNavigate();

   const limitOthers = 10;

   const loadOrders = useCallback(async () => {
      try {
         if (!user) {
            return;
         }
         const { success, data } = await sendDataAPI(`orders/?offset=${offsetOthers}&limit=${limitOthers}`, "GET", null, true);
         if (success) {
            let existingOrders = { ...orders };
            const newOthers = existingOrders.others.concat(data.others);
            existingOrders.others = newOthers;
            existingOrders.pending = data.pending;
            existingOrders.others_count = data.others_count;
            setOrders(existingOrders);
         } else {
            setIsErrorData(true);
         }
      } catch (error) {
         setIsErrorData(true);
      } finally {
         setIsLoadingData(false);
      }
   }, [user, offsetOthers]);

   useEffect(() => {
      loadOrders();
   }, [loadOrders]);

   const handleSearch = (e) => {
      e.preventDefault();
      const parsed = parseInt(searchInput);
      if (parsed) {
         navigate(`/admin/reservations/${parsed}`);
      }
   };

   if ((user && !user.isAdmin) || user === null) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="admin-orders"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <ErrorBlock code={403} />
         </PageLayout>
      );
   } else if (isErrorData) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="admin-orders"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <h1 className="page-title">{pageTitle}</h1>
            <center>Erreur de chargement des données.</center>
         </PageLayout>
      );
   } else if (isLoadingData) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="admin-orders"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <h1 className="page-title">{pageTitle}</h1>
            <center>
               <Loader />
            </center>
         </PageLayout>
      );
   }

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="admin-orders"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>

         <div className="admin-orders__toggle">
            <div></div>
            <div>
               <ButtonGroup aria-label="Sélecteur de vue">
                  <Button
                     variant={selectedViewIndex === 0 ? "dark" : "outline-dark"}
                     onClick={() => setSelectedViewIndex(0)}
                  >
                     Réservations par statut
                  </Button>
                  <Button
                     variant={selectedViewIndex === 1 ? "dark" : "outline-dark"}
                     onClick={() => setSelectedViewIndex(1)}
                  >
                     Produits par jour de retrait
                  </Button>
               </ButtonGroup>
            </div>
            <div>
               {showSearch ? (
                  <Form onSubmit={handleSearch}>
                     <Form.Control
                        type="text"
                        placeholder="N° de réservation"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                     />
                     <Button variant="dark" type="submit">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                     </Button>
                  </Form>
               ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => setShowSearch(true)} />
               )}
            </div>
         </div>
         {selectedViewIndex === 0 ? (
            <>
               <section className="white-block">
                  <h2>À traiter ({orders.pending.length})</h2>

                  <div className="user-orders__table">
                     <div className="user-orders__header">
                        <div>N°</div>
                        <div>Date</div>
                        <div>Total</div>
                        <div>Cat.</div>
                        <div>Client</div>
                        <div>État</div>
                        <div></div>
                     </div>

                     {orders.pending.length > 0 &&
                        orders.pending.map((order, index) => (
                           <OrderAccordion key={`order-${index}`} order={order} isAdmin={user?.isAdmin ?? false} />
                        ))}
                  </div>

                  {orders.pending.length === 0 && (
                     <p className="user-orders__no-order">Aucune réservation à traiter</p>
                  )}
               </section>
               <section className="white-block">
                  <h2>
                     Archivées ({orders.others.length} / {orders.others_count})
                  </h2>
                  <div className="user-orders__table">
                     <div className="user-orders__header">
                        <div>N°</div>
                        <div>Date</div>
                        <div>Total</div>
                        <div>Cat.</div>
                        <div>Client</div>
                        <div>État</div>
                        <div></div>
                     </div>
                     {orders.others.length > 0 &&
                        orders.others.map((order, index) => (
                           <OrderAccordion key={`order-${index}`} order={order} isAdmin={user?.isAdmin ?? false} />
                        ))}
                  </div>

                  {orders.others.length === 0 && (
                     <p className="user-orders__no-order">Aucune réservation archivée</p>
                  )}
                  {orders.others_count > limitOthers &&
                     orders.others.length < 200 &&
                     orders.others.length < orders.others_count && (
                        <Button
                           variant="secondary"
                           size="sm"
                           onClick={() => setOffsetOthers(offsetOthers + limitOthers)}
                        >
                           Afficher plus
                        </Button>
                     )}
               </section>
            </>
         ) : (
            <AdminProductList />
         )}
      </PageLayout>
   );
}

function AdminProductList() {
   const [products, setProducts] = useState();
   const [isError, setIsError] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const { categories } = useContext(SettingsContext);

   const loadData = useCallback(async () => {
      try {
         const { success, data } = await sendDataAPI(`orders/products`, "GET", null, true);
         if (success) {
            setProducts(data);
         } else {
            setIsError(false);
         }
      } catch (error) {
         setIsError(true);
      } finally {
         setIsLoading(false);
      }
   }, []);

   useEffect(() => {
      loadData();
   }, [loadData]);

   const groupedProducts = useMemo(() => {
      if (!products) {
         return {};
      }

      for (let product of products) {
         if (product.ProductCategory.type === "meat" && product.Order.meatDeliveryDate) {
            const splitted = product.Order.meatDeliveryDate.split("T");
            const deliveryDay = splitted[0];
            product.deliveryDay = deliveryDay;
            product.deliveryHour = "00:00:00";
         } else {
            const splitted = product.Order.deliveryDate.split("T");
            const deliveryDay = splitted[0];
            const deliveryHour = product.Order.deliveryDate;
            product.deliveryDay = deliveryDay;
            product.deliveryHour = deliveryHour;
         }
         product.orderId = product.Order.id;
      }

      const productsGroupedByDays = groupedBy(products, "deliveryDay");

      for (let groupByDay of Object.keys(productsGroupedByDays)) {
         const array = productsGroupedByDays[groupByDay];
         const productsGroupedByHours = groupedBy(array, "deliveryHour");
         for (let groupByHour of Object.keys(productsGroupedByHours)) {
            const hourValue = productsGroupedByHours[groupByHour];
            productsGroupedByHours[groupByHour] = hourValue.sort((a, b) => a.orderId - b.orderId);
         }
         productsGroupedByDays[groupByDay] = productsGroupedByHours;
      }
      return productsGroupedByDays;
   }, [products]);

   if (isError) {
      return <center>Erreur de chargement des données.</center>;
   } else if (isLoading) {
      return <Loader variant="full" />;
   } else if (!products || products.length === 0) {
      return <center>Pas de réservation pour le moment.</center>;
   }

   return (
      <div className="admin-product-list">
         {Object.keys(groupedProducts)
            .sort()
            .map((groupKey, index) => (
               <section key={`group-key-${index}`} className="white-block">
                  <h2>{formatJsonDate(groupKey, false, false, true)}</h2>

                  <table>
                     <thead>
                        <tr>
                           <th>N°</th>
                           <th>Client</th>
                           <th>Cat.</th>
                           <th>Produit</th>
                           <th>Qté</th>
                        </tr>
                     </thead>
                     <tbody>
                        {Object.keys(groupedProducts[groupKey]).map((hourKey, indexHour) => (
                           <Fragment key={`hour-group-${indexHour}`}>
                              <tr className="admin-product-list__hour-group">
                                 <td colSpan={6}>
                                    {hourKey === "00:00:00"
                                       ? "Retrait de viande bovine"
                                       : `Retrait à partir de ${formatJsonTime(hourKey)}`}
                                 </td>
                              </tr>

                              {groupedProducts[groupKey][hourKey].map((product, indexProduct) => (
                                 <tr key={`product-${indexProduct}`}>
                                    <td>
                                       <Link to={`/admin/reservations/${product.orderId}`}>
                                          {product.orderId}
                                       </Link>
                                    </td>
                                    <td>
                                       {product.Order.lastName} {product.Order.firstName}
                                    </td>
                                    <td>
                                       <picture>
                                          {categories
                                             .find(
                                                (category) =>
                                                   category.id === product.ProductCategoryId,
                                             )
                                             .iconUrl.map((icon, index) =>
                                                icon.fallback ? (
                                                   <img
                                                      key={`picto-image-${index}`}
                                                      src={icon.url}
                                                      alt=""
                                                      className="admin-product-list__picto"
                                                   />
                                                ) : (
                                                   <source
                                                      key={`picto-image-${index}`}
                                                      srcSet={icon.url}
                                                      type={`image/${icon.type}`}
                                                   />
                                                ),
                                             )}
                                       </picture>
                                    </td>
                                    <td>
                                       {product.title}
                                       {product.measurementValue &&
                                          ` ● ${formatMeasurement(
                                             product.measurementValue,
                                             product.measurementUnit,
                                          )}`}
                                    </td>
                                    <td>
                                       {product.status === 2 && (
                                          <OverlayTrigger
                                             overlay={<Tooltip id="1">{product.comment}</Tooltip>}
                                          >
                                             <FontAwesomeIcon icon={faTriangleExclamation} />
                                          </OverlayTrigger>
                                       )}{" "}
                                       {product.quantity}
                                    </td>
                                 </tr>
                              ))}
                           </Fragment>
                        ))}
                     </tbody>
                  </table>
               </section>
            ))}
      </div>
   );
}

export default AdminOrders;
