import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import "./scss/main.scss";
import { CartProvider } from "./contexts/cartContext";
import { SettingsProvider } from "./contexts/settingsContext";
import { UserProvider } from "./contexts/userContext";
import { SearchProvider } from "./contexts/searchContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <UserProvider>
         <SettingsProvider>
            <SearchProvider>
               <CartProvider>
                  <App />
               </CartProvider>
            </SearchProvider>
         </SettingsProvider>
      </UserProvider>

   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
