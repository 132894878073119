import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
   CAN_REDO_COMMAND,
   CAN_UNDO_COMMAND,
   REDO_COMMAND,
   UNDO_COMMAND,
   SELECTION_CHANGE_COMMAND,
   FORMAT_TEXT_COMMAND,
   /*FORMAT_ELEMENT_COMMAND,*/
   $getSelection,
   $isRangeSelection,
   $createParagraphNode,
   $getNodeByKey,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { /*$isParentElementRTL,*/ $wrapNodes, $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
   INSERT_ORDERED_LIST_COMMAND,
   INSERT_UNORDERED_LIST_COMMAND,
   REMOVE_LIST_COMMAND,
   $isListNode,
   ListNode,
} from "@lexical/list";
import { createPortal } from "react-dom";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import {
   /*$createCodeNode,*/
   $isCodeNode,
   getDefaultCodeLanguage,
   getCodeLanguages,
} from "@lexical/code";

const LowPriority = 1;

const supportedBlockTypes = new Set(["paragraph", "quote", "h1", "h2", "h3", "ul", "ol"]);

const blockTypeToBlockName = {
   h1: "Titre 1",
   h2: "Titre 2",
   h3: "Titre 3",
   ul: "Liste à puces",
   ol: "Liste numérotée",
   paragraph: "Normal",
   quote: "Citation",
};

function Divider() {
   return <div className="divider" />;
}

function positionEditorElement(editor, rect) {
   if (rect === null) {
      editor.style.opacity = "0";
      editor.style.top = "-1000px";
      editor.style.left = "-1000px";
   } else {
      editor.style.opacity = "1";
      editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
      editor.style.left = `${
         rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
      }px`;
   }
}

function FloatingLinkEditor({ editor }) {
   const editorRef = useRef(null);
   const inputRef = useRef(null);
   const mouseDownRef = useRef(false);
   const [linkUrl, setLinkUrl] = useState("");
   const [isEditMode, setEditMode] = useState(false);
   const [lastSelection, setLastSelection] = useState(null);

   const updateLinkEditor = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
         const node = getSelectedNode(selection);
         const parent = node.getParent();
         if ($isLinkNode(parent)) {
            setLinkUrl(parent.getURL());
         } else if ($isLinkNode(node)) {
            setLinkUrl(node.getURL());
         } else {
            setLinkUrl("");
         }
      }
      const editorElem = editorRef.current;
      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;

      if (editorElem === null) {
         return;
      }

      const rootElement = editor.getRootElement();
      if (
         selection !== null &&
         !nativeSelection.isCollapsed &&
         rootElement !== null &&
         rootElement.contains(nativeSelection.anchorNode)
      ) {
         const domRange = nativeSelection.getRangeAt(0);
         let rect;
         if (nativeSelection.anchorNode === rootElement) {
            let inner = rootElement;
            while (inner.firstElementChild != null) {
               inner = inner.firstElementChild;
            }
            rect = inner.getBoundingClientRect();
         } else {
            rect = domRange.getBoundingClientRect();
         }

         if (!mouseDownRef.current) {
            positionEditorElement(editorElem, rect);
         }
         setLastSelection(selection);
      } else if (!activeElement || activeElement.className !== "link-input") {
         positionEditorElement(editorElem, null);
         setLastSelection(null);
         setEditMode(false);
         setLinkUrl("");
      }

      return true;
   }, [editor]);

   useEffect(() => {
      return mergeRegister(
         editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
               updateLinkEditor();
            });
         }),

         editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            () => {
               updateLinkEditor();
               return true;
            },
            LowPriority,
         ),
      );
   }, [editor, updateLinkEditor]);

   useEffect(() => {
      editor.getEditorState().read(() => {
         updateLinkEditor();
      });
   }, [editor, updateLinkEditor]);

   useEffect(() => {
      if (isEditMode && inputRef.current) {
         inputRef.current.focus();
      }
   }, [isEditMode]);


   const monitorInputInteraction = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleLinkSubmission();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        setEditMode(false);
      }
   };

   const handleLinkSubmission = () => {
      if (lastSelection !== null) {
         if (linkUrl !== "") {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
         }
         setEditMode(false);
      }
   };

   return (
      <div ref={editorRef} className="link-editor">
         {isEditMode ? (
            <>
            <input
               ref={inputRef}
               className="link-input"
               value={linkUrl}
               onChange={(event) => {
                  setLinkUrl(event.target.value);
               }}
               onKeyDown={(event) => {
                  monitorInputInteraction(event);
               }}
               /*onKeyDown={(event) => {
                  if (event.key === "Enter") {
                     event.preventDefault();
                     if (lastSelection !== null) {
                        if (linkUrl !== "") {
                           editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                        }
                        setEditMode(false);
                     }
                  } else if (event.key === "Escape") {
                     event.preventDefault();
                     setEditMode(false);
                  }
               }}*/
            />
            <div className="link-input">
            <div
              className="link-cancel"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
               setEditMode(false);
              }}
            />
            <div
              className="link-confirm"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={handleLinkSubmission}
            />
          </div>
          </>
         ) : (
            <>
               <div className="link-input">
                  <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                     {linkUrl}
                  </a>
                  <div
                     className="link-edit"
                     role="button"
                     tabIndex={0}
                     onMouseDown={(event) => event.preventDefault()}
                     onClick={() => {
                        setEditMode(true);
                     }}
                  />
               </div>
            </>
         )}
      </div>
   );
}

function Select({ onChange, className, options, value }) {
   return (
      <select className={className} onChange={onChange} value={value}>
         <option hidden={true} value="" />
         {options.map((option) => (
            <option key={option} value={option}>
               {option}
            </option>
         ))}
      </select>
   );
}

function getSelectedNode(selection) {
   const anchor = selection.anchor;
   const focus = selection.focus;
   const anchorNode = selection.anchor.getNode();
   const focusNode = selection.focus.getNode();
   if (anchorNode === focusNode) {
      return anchorNode;
   }
   const isBackward = selection.isBackward();
   if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
   } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
   }
}

function BlockOptionsDropdownList({
   headings,
   editor,
   blockType,
   toolbarRef,
   setShowBlockOptionsDropDown,
}) {
   const dropDownRef = useRef(null);

   useEffect(() => {
      const toolbar = toolbarRef.current;
      const dropDown = dropDownRef.current;

      if (toolbar !== null && dropDown !== null) {
         const { top, left } = toolbar.getBoundingClientRect();
         dropDown.style.top = `${top + window.scrollY + 50}px`;
         dropDown.style.left = `${left + window.scrollX + 290}px`;
         //dropDown.style.position = "absolute";
      }
   }, [dropDownRef, toolbarRef]);

   useEffect(() => {
      const dropDown = dropDownRef.current;
      const toolbar = toolbarRef.current;

      if (dropDown !== null && toolbar !== null) {
         const handle = (event) => {
            const target = event.target;

            if (!dropDown.contains(target) && !toolbar.contains(target)) {
               setShowBlockOptionsDropDown(false);
            }
         };
         document.addEventListener("click", handle);

         return () => {
            document.removeEventListener("click", handle);
         };
      }
   }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

   const formatParagraph = () => {
      if (blockType !== "paragraph") {
         editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createParagraphNode());
            }
         });
      }
      setShowBlockOptionsDropDown(false);
   };

   const formatHeading = (type) => {
      if (blockType !== type) {
         editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createHeadingNode(type));
            }
         });
      }
      setShowBlockOptionsDropDown(false);
   };

   const formatBulletList = () => {
      if (blockType !== "ul") {
         editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
      } else {
         editor.dispatchCommand(REMOVE_LIST_COMMAND);
      }
      setShowBlockOptionsDropDown(false);
   };

   const formatNumberedList = () => {
      if (blockType !== "ol") {
         editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
      } else {
         editor.dispatchCommand(REMOVE_LIST_COMMAND);
      }
      setShowBlockOptionsDropDown(false);
   };

   const formatQuote = () => {
      if (blockType !== "quote") {
         editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
               $wrapNodes(selection, () => $createQuoteNode());
            }
         });
      }
      setShowBlockOptionsDropDown(false);
   };

   return (
      <div className="markdown-editor-dropdown" ref={dropDownRef}>
         <button className="markdown-editor-dropdown-item" onClick={formatParagraph}>
            <i className="format paragraph"></i>
            <span className="text">Normal</span>
            {blockType === "paragraph" && <span className="active" />}
         </button>
         <button
            disabled={!headings.includes("h1")}
            className="markdown-editor-dropdown-item"
            onClick={() => formatHeading("h1")}
         >
            <i className="format h1"></i>
            <span className="text">Titre 1</span>
            {blockType === "h1" && <span className="active" />}
         </button>
         <button
            disabled={!headings.includes("h2")}
            className="markdown-editor-dropdown-item"
            onClick={() => formatHeading("h2")}
         >
            <i className="format h2"></i>
            <span className="text">Titre 2</span>
            {blockType === "h2" && <span className="active" />}
         </button>
         <button
            disabled={!headings.includes("h3")}
            className="markdown-editor-dropdown-item"
            onClick={() => formatHeading("h3")}
         >
            <i className="format h3"></i>
            <span className="text">Titre 3</span>
            {blockType === "h3" && <span className="active" />}
         </button>
         <button className="markdown-editor-dropdown-item" onClick={formatBulletList}>
            <i className="format ul"></i>
            <span className="text">Liste à puces</span>
            {blockType === "ul" && <span className="active" />}
         </button>
         <button className="markdown-editor-dropdown-item" onClick={formatNumberedList}>
            <i className="format ol"></i>
            <span className="text">Liste numérotée</span>
            {blockType === "ol" && <span className="active" />}
         </button>
         <button className="markdown-editor-dropdown-item" onClick={formatQuote}>
            <i className="format quote"></i>
            <span className="text">Citation</span>
            {blockType === "quote" && <span className="active" />}
         </button>
      </div>
   );
}

export default function ToolbarPlugin({ headings }) {
   const [editor] = useLexicalComposerContext();
   const toolbarRef = useRef(null);
   const [canUndo, setCanUndo] = useState(false);
   const [canRedo, setCanRedo] = useState(false);
   const [blockType, setBlockType] = useState("paragraph");
   const [selectedElementKey, setSelectedElementKey] = useState(null);
   const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
   const [codeLanguage, setCodeLanguage] = useState("");
   //const [isRTL, setIsRTL] = useState(false);
   const [isLink, setIsLink] = useState(false);
   const [isBold, setIsBold] = useState(false);
   const [isItalic, setIsItalic] = useState(false);
   const [isUnderline, setIsUnderline] = useState(false);
   const [isStrikethrough, setIsStrikethrough] = useState(false);
   //const [isCode, setIsCode] = useState(false);

   const updateToolbar = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
         const anchorNode = selection.anchor.getNode();
         const element =
            anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
         const elementKey = element.getKey();
         const elementDOM = editor.getElementByKey(elementKey);
         if (elementDOM !== null) {
            setSelectedElementKey(elementKey);
            if ($isListNode(element)) {
               const parentList = $getNearestNodeOfType(anchorNode, ListNode);
               const type = parentList ? parentList.getTag() : element.getTag();
               setBlockType(type);
            } else {
               const type = $isHeadingNode(element) ? element.getTag() : element.getType();
               setBlockType(type);
               if ($isCodeNode(element)) {
                  setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
               }
            }
         }
         // Update text format
         setIsBold(selection.hasFormat("bold"));
         setIsItalic(selection.hasFormat("italic"));
         setIsUnderline(selection.hasFormat("underline"));
         setIsStrikethrough(selection.hasFormat("strikethrough"));
         //setIsCode(selection.hasFormat("code"));
         //setIsRTL($isParentElementRTL(selection));

         // Update links
         const node = getSelectedNode(selection);
         const parent = node.getParent();
         if ($isLinkNode(parent) || $isLinkNode(node)) {
            setIsLink(true);
         } else {
            setIsLink(false);
         }
      }
   }, [editor]);

   useEffect(() => {
      return mergeRegister(
         editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
               updateToolbar();
            });
         }),
         editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
               updateToolbar();
               return false;
            },
            LowPriority,
         ),
         editor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
               setCanUndo(payload);
               return false;
            },
            LowPriority,
         ),
         editor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
               setCanRedo(payload);
               return false;
            },
            LowPriority,
         ),
      );
   }, [editor, updateToolbar]);

   const codeLanguges = useMemo(() => getCodeLanguages(), []);
   const onCodeLanguageSelect = useCallback(
      (e) => {
         editor.update(() => {
            if (selectedElementKey !== null) {
               const node = $getNodeByKey(selectedElementKey);
               if ($isCodeNode(node)) {
                  node.setLanguage(e.target.value);
               }
            }
         });
      },
      [editor, selectedElementKey],
   );

   const insertLink = useCallback((e) => {
      e.preventDefault();
      if (!isLink) {
         editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
      } else {
         editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
   }, [editor, isLink]);

   return (
      <div className="toolbar" ref={toolbarRef}>
         <button
            disabled={!canUndo}
            onClick={(e) => {
               e.preventDefault();
               editor.dispatchCommand(UNDO_COMMAND);
            }}
            className="toolbar-item"
            aria-label="Annuler"
         >
            <i className="format undo" />
         </button>
         <button
            disabled={!canRedo}
            onClick={(e) => {
               e.preventDefault();
               editor.dispatchCommand(REDO_COMMAND);
            }}
            className="toolbar-item"
            aria-label="Refaire"
         >
            <i className="format redo" />
         </button>
         <Divider />
         {blockType === "code" ? (
            <>
               <Select
                  className="toolbar-item code-language"
                  onChange={onCodeLanguageSelect}
                  options={codeLanguges}
                  value={codeLanguage}
               />
               <i className="chevron-down inside" />
            </>
         ) : (
            <>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                  }}
                  className={"toolbar-item " + (isBold ? "active" : "")}
                  aria-label="Gras"
               >
                  <i className="format bold" />
               </button>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                  }}
                  className={"toolbar-item " + (isItalic ? "active" : "")}
                  aria-label="Italique"
               >
                  <i className="format italic" />
               </button>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                  }}
                  className={"toolbar-item " + (isUnderline ? "active" : "")}
                  aria-label="Souligné"
               >
                  <i className="format underline" />
               </button>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                  }}
                  className={"toolbar-item " + (isStrikethrough ? "active" : "")}
                  aria-label="Barré"
               >
                  <i className="format strikethrough" />
               </button>
               <Divider />
               {supportedBlockTypes.has(blockType) && (
                  <>
                     <button
                        className="toolbar-item"
                        onClick={(e) => {
                           e.preventDefault();
                           setShowBlockOptionsDropDown(!showBlockOptionsDropDown);
                        }}
                        aria-label="Options de formattage"
                     >
                        <i className={"format " + blockType} />
                        <span className="text">{blockTypeToBlockName[blockType]}</span>
                        <i className="chevron-down" />
                     </button>
                     {showBlockOptionsDropDown &&
                        createPortal(
                           <BlockOptionsDropdownList
                              headings={headings}
                              editor={editor}
                              blockType={blockType}
                              toolbarRef={toolbarRef}
                              setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                           />,
                           document.body,
                        )}
                     <Divider />
                  </>
               )}
               <button
                  onClick={e => insertLink(e)}
                  className={"toolbar-item " + (isLink ? "active" : "")}
                  aria-label="Insérer un lien"
               >
                  <i className="format link" />
               </button>
               {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
            </>
         )}
      </div>
   );
}
