import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const errorMessages = {
   401: "L'accès à cette page n'est pas autorisé.",
   403: "L'accès à cette page n'est pas autorisé.",
   404: "Oups! La page que vous demandez n'existe pas."
};

/**
 * Affiche un message d'erreur avec un code paramétrable
 * @return JSX.element
 */
function ErrorBlock({ code = 404 }) {
   return (
      <div className="error-block">
         <p className="error-block__type">{code}</p>
         <p className="error-block__description">{errorMessages[code]}</p>
         <Link to="/" className="error-block__link">
            Retourner sur la page d'accueil
         </Link>
      </div>
   );
}

ErrorBlock.propTypes = {
   code: PropTypes.number,
};

/*ErrorBlock.defaultProps = {
   code: 404,
};*/

export default ErrorBlock;
