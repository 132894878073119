import PropTypes from "prop-types";
import { useContext, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../../contexts/cartContext";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import { formatPrice, formatMeasurement, normalizeName, formatPricePerUnit } from "../../helpers";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck, faSquare, faPenToSquare } from "@fortawesome/free-regular-svg-icons";

/**
 * Carte affichant une produit
 * @return JSX.element
 */

function ProductCard({ selected, product, onSelect, onDeselect, onEdit }) {
   const { addToCart, removeFromCart, cart } = useContext(CartContext);
   const { user } = useContext(UserContext);
   const { nextMeatSale } = useContext(SettingsContext);
   const [selectedVariant, setSelectedVariant] = useState(0);
   const navigate = useNavigate();

   // Reconstitue les variantes du produit
   const productVariants = useMemo(() => {
      let variants = [];
      if (product.ProductVariants.length > 0) {
         variants = [...product.ProductVariants];
         variants.push({
            measurementValue: product.measurementValue,
            measurementUnit: product.measurementUnit,
            price: product.price,
         });
         variants.sort(
            (a, b) => a.quantity - b.quantity || a.measurementValue - b.measurementValue,
         );
      } else if (product.showMeasurement) {
         variants.push({
            measurementValue: product.measurementValue,
            measurementUnit: product.measurementUnit,
            price: product.price,
         });
      }
      return variants;
   }, [product]);

   const changeSelectedState = (isNowSelected) => {
      if (isNowSelected) {
         onSelect(product.id);
      } else {
         onDeselect(product.id);
      }
   };

   const productQuantity = useMemo(() => {
      //console.log("cart has changed", cart);
      const foundProduct = cart.find((item) => {
         if (productVariants.length > 0) {
            const variant = productVariants[selectedVariant];
            return (
               item.id === product.id &&
               item.measurementValue === variant.measurementValue &&
               item.measurementUnit === variant.measurementUnit
            );
         }
         return item.id === product.id;
      });
      return foundProduct?.quantity ?? null;
   }, [product, cart, productVariants, selectedVariant])

   return (
      <article
         className={`generic-card product-card${product.highlighted ? " product-card--highlighted" : ""
            }`}
      >
         {!product.enabled && <div className="product-card__disabled">DÉSACTIVÉ</div>}
         {user?.isAdmin && (
            <div className="product-card__edit">
               <div
                  className={`edit-button product-card__edit-checkbox ${selected && "product-card__edit-checkbox--selected"
                     }`}
                  onClick={() => changeSelectedState(!selected)}
               >
                  <FontAwesomeIcon icon={selected ? faSquareCheck : faSquare} />
               </div>
               <div
                  className="edit-button product-card__edit-button"
                  onClick={() => onEdit(product)}
               >
                  <FontAwesomeIcon icon={faPenToSquare} />
               </div>
            </div>
         )}
         {product.organic && (
            <img
               className="product-card__organic-logo"
               src="/assets/logos/bio_little.png"
               srcSet="/assets/logos/bio_little@2x.png 2x"
               alt="Biologique"
            />
         )}
         {product.highlighted && (
            <div
               className="product-card__highlighted-box"
               onClick={() =>
                  navigate(
                     `${product.ProductCategory.url}/${normalizeName(product.title)}-${product.id}`,
                  )
               }
            >
               Nouveauté
            </div>
         )}
         <picture>
            {product.imageUrl
               .filter((item) => item.thumbnail)
               .map((image, index) =>
                  image.fallback ? (
                     <img
                        key={`source-${index}`}
                        className="product-card__image"
                        src={image.url}
                        alt={product.title}
                        onClick={() =>
                           navigate(
                              `${product.ProductCategory.url}/${normalizeName(product.title)}-${product.id
                              }`,
                           )
                        }
                     />
                  ) : (
                     <source
                        key={`source-${index}`}
                        srcSet={image.url}
                        type={`image/${image.type}`}
                     />
                  ),
               )}
         </picture>

         <div className="product-card__container">
            <h3 className="product-card__title">
               <Link
                  to={`${product.ProductCategory.url}/${normalizeName(product.title)}-${product.id
                     }`}
               >
                  {product.title}
               </Link>
            </h3>
            <div className="product-card__details">
               {productVariants.length > 0 && (
                  <>
                     {product.approximativeMeasurement && (
                        <div className="product-card__approximative">ENVIRON</div>
                     )}
                     <ButtonGroup aria-label="Variante de poids">
                        {productVariants.map((variant, index) => (
                           <Button
                              onClick={() => setSelectedVariant(index)}
                              key={`product-variant-${index}`}
                              variant={selectedVariant === index ? "primary" : "outline-primary"}
                           >
                              {formatMeasurement(variant.measurementValue, variant.measurementUnit)}
                           </Button>
                        ))}
                     </ButtonGroup>
                  </>
               )}

               <div className="product-card__bottom">
                  <div className="product-card__price">
                     <span className="product-card__price-per-kg">
                        {productVariants.length === 0
                           ? formatPricePerUnit(
                              product.price,
                              product.measurementValue,
                              product.measurementUnit,
                           )
                           : formatPricePerUnit(
                              productVariants[selectedVariant].price,
                              productVariants[selectedVariant].measurementValue,
                              productVariants[selectedVariant].measurementUnit,
                           )}
                     </span>
                     {productVariants.length === 0
                        ? formatPrice(product.price)
                        : formatPrice(productVariants[selectedVariant].price)}
                     <br />
                  </div>
                  {product.ProductCategory.type === "meat" && !nextMeatSale ? (
                     <span className="product-card__not-bookable">Prochainement<br />disponible</span>
                  ) : product.onlyInStore ? (
                     <span className="product-card__not-bookable">
                        Disponible en<br />magasin selon stock
                     </span>
                  ) : (
                     productQuantity ? (
                           <div className="quantity-selector">
                              <button onClick={() => {
                              if (productVariants.length > 0) {
                                 removeFromCart(product, 1, productVariants[selectedVariant])
                              } else {
                                 removeFromCart(product, 1)
                              }
                           }
                        }>–</button>
                        <span>{productQuantity}</span>
                        <button onClick={() => {
                           if (productVariants.length > 0) {
                              addToCart(product, 1, productVariants[selectedVariant]);
                           } else {
                              addToCart(product);
                           }
                        }
                     }>+</button></div> 
                        ) : (
                        <AnimatableButton
                           className="product-card__add"
                           onClick={() => {
                              if (productVariants.length > 0) {
                                 addToCart(product, 1, productVariants[selectedVariant]);
                              } else {
                                 addToCart(product);
                              }
                           }}
                        />
                     )
                  )}
               </div>
            </div>
         </div>
      </article>
   );
}

function AnimatableButton({ className, onClick }) {
   const [clicked, setClicked] = useState(false);
   return (
      <Button
         className={`${className} btn-rounded ${clicked ? "btn--animated" : ""}`}
         variant={clicked ? "outline-primary" : "primary"}
         onClick={() => {
            setClicked(true);
            onClick();
         }}
         onAnimationEnd={() => setClicked(false)}
      >
         <FontAwesomeIcon icon={clicked ? faCheck : faBasketShopping} />
      </Button>
   );
}

ProductCard.propTypes = {
   selected: PropTypes.bool,
   product: PropTypes.object.isRequired,
   onSelect: PropTypes.func,
   onDeselect: PropTypes.func,
   onEdit: PropTypes.func,
};

export default ProductCard;
