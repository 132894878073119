import { useParams, useLocation } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import { Fragment, useContext, useEffect, useState, useMemo, useCallback } from "react";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import Loader from "../../components/Loader";
import Tag from "../../components/Tag";
import {
   formatJsonDate,
   groupedBy,
   formatMeasurement,
   formatPrice,
   shortenedJsonDate,
} from "../../helpers";
import { Form, Button } from "react-bootstrap";
import ErrorMessage from "../../components/ErrorMessage";
import AlertBox from "../../components/AlertBox";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faCircleExclamation,
   faEnvelope,
   faHourglassHalf,
   faCircleCheck,
   faTriangleExclamation,
   faCircleXmark,
   faArrowTurnUp,
   faUserTag,
   faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import ErrorBlock from "../../components/ErrorBlock";
import { sendDataAPI } from "../../helpers";
import { statusOrder } from "../../constants";

function OrderDetails() {
   const { orderId } = useParams();
   const { user } = useContext(UserContext);
   const { categories } = useContext(SettingsContext);
   const [order, setOrder] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [errorCode, setErrorCode] = useState(200);
   const [errorMessage, setErrorMessage] = useState();
   const [productComments, setProductComments] = useState({});
   const [productCommentsEdit, setProductCommentsEdit] = useState({});
   const [showAlertBox, setShowAlertBox] = useState(false);
   const [modalComment, setModalComment] = useState("");
   const [isEditModeHour, setIsEditModeHour] = useState(false);
   const [isEditModeMeat, setIsEditModeMeat] = useState(false);
   const [deliveryHour, setDeliveryHour] = useState("2023-01-01T00:00");
   const [deliveryMeat, setDeliveryMeat] = useState("2023-01-01T00:00");
   const [showCancelModal, setShowCancelModal] = useState(false);
   const location = useLocation();

   const pageTitle = "Réservation n°" + orderId;

   const statusProduct = [
      { variant: "secondary", icon: faHourglassHalf, title: "En attente" },
      { variant: "primary", icon: faCircleCheck, title: "Validé" },
      { variant: "warning", icon: faTriangleExclamation, title: "Validé partiellement" },
      { variant: "danger", icon: faCircleXmark, title: "Annulé" },
   ];

   const breadCrumbItems = useMemo(() => {
      let items = []
      if (location.pathname.startsWith("/compte")) {
         items.push({ title: "Compte", url: "/compte" });
      } else {
         items.push({ title: "Réservations", url: "/admin/reservations" });
      }
      items.push({ title: pageTitle });
      return items;
   }, [location, pageTitle]);

   const orderCategoriesUnique = useMemo(() => {
      if (order && categories && categories.length > 0) {
         const orderCategories = order.OrderProducts.map((product) => product.ProductCategoryId);
         const unique = [...new Set(orderCategories)].sort();
         const pictures = {};
         unique.forEach((categoryId) => {
            const icons = categories.find((item) => item.id === categoryId);
            if (icons) {
               pictures[categoryId] = (
                  <picture key={`picto-cat-${categoryId}`}>
                     {icons.iconUrl.map((icon, iconIndex) =>
                        icon.fallback ? (
                           <img
                              key={`picto-image-${iconIndex}`}
                              src={icon.url}
                              alt=""
                              className="order-details__picto"
                           />
                        ) : (
                           <source
                              key={`picto-image-${iconIndex}`}
                              srcSet={icon.url}
                              type={`image/${icon.type}`}
                           />
                        ),
                     )}
                  </picture>
               );
            }
         });
         return pictures;
      }
      return [];
   }, [order, categories]);

   const loadData = useCallback(async () => {
      if (user === undefined || !orderId) {
         return;
      }
      const { success, code, data } = await sendDataAPI(`orders/${orderId}`, "GET", null, true);
      if (success) {
         setOrder(data);
      } else {
         setErrorCode(code);
      }
      setIsLoading(false);
   }, [orderId, user]);

   const sendConfirmation = useCallback(async () => {
      if (!user || !orderId) {
         return;
      }
      const { success, code } = await sendDataAPI(`orders/${orderId}/confirm`, "PUT", { comment: modalComment }, true);
      if (success) {
         loadData();
      } else {
         setErrorMessage(`Erreur d'envoi : ${code}`)
      }
      setShowAlertBox(false);
   }, [modalComment, orderId, user, loadData]);

   const cancelOrder = useCallback(async () => {
      const body = {
         user: { id: user.id, role: user.role },
      };
      const { success, code } = await sendDataAPI(`orders/${order.id}/cancel`, "PUT", body, true);
      if (success) {
         loadData();
      } else {
         setErrorMessage(`Erreur d'annulation : ${code}`)
      }
   }, [order, user, loadData]);

   const updateProduct = useCallback(async (productId, key, value) => {
      if (!user || !orderId) {
         return;
      }
      const { success, code } = await sendDataAPI(`orders/${orderId}/products/${productId}`, "PUT", { [key]: value }, true);
      if (success) {
         loadData();
      } else {
         setErrorMessage(`Erreur de mise à jour : ${code}`)
      }
   }, [user, orderId, loadData]);

   const updateOrder = useCallback(async (key, value) => {
      if (!user || !orderId) {
         return;
      }
      const { success, code } = await sendDataAPI(`orders/${orderId}`, "PUT", { [key]: value }, true);
      if (success) {
         loadData();
      } else {
         setErrorMessage(`Erreur de mise à jour : ${code}`)
      }
   }, [user, orderId, loadData]);

   useEffect(() => {
      loadData();
   }, [loadData]);

   if (errorCode !== 200) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="order-details"
            breadCrumbItems={breadCrumbItems}
         >
            <ErrorBlock code={errorCode} />
         </PageLayout>
      );
   } else if (isLoading || !order) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="order-details"
            breadCrumbItems={breadCrumbItems}
         >
            <h1 className="page-title">{pageTitle}</h1>
            <Loader variant="full" />
         </PageLayout>
      );
   }

   const groupedProducts = groupedBy(order.OrderProducts, "ProductCategoryId");
   const onlyMeatCart = order.OrderProducts.every(
      (product) => product.ProductCategory.type === "meat",
   );

   const changeAllProducts = (products) => {
      updateOrder("OrderProducts", products);
   };

   const updateProductComment = (productId, comment) => {
      const newProductComments = { ...productComments };
      newProductComments[productId] = comment;
      setProductComments(newProductComments);
   };

   const updateProductCommentEdit = (productId, isEdit) => {
      const newProductCommentsEdit = { ...productCommentsEdit };
      newProductCommentsEdit[productId] = isEdit;
      setProductCommentsEdit(newProductCommentsEdit);
   };

   const checkReservation = () => {
      const hasError = order.OrderProducts.some((product) => product.status === 0)
      if (hasError) {
         setErrorMessage("Certains produits ont toujours un statut en attente.");
      } else {
         setShowAlertBox(true);
      }
   };

   return (
      <PageLayout pageTitle={pageTitle} className="order-details" breadCrumbItems={breadCrumbItems}>
         <div className="order-details__header">
            <h1>{pageTitle}</h1>
            <Tag
               title={statusOrder[order.status].title}
               variant={statusOrder[order.status].variant}
            />
         </div>
         {user?.isAdmin &&
            order.OrderProducts.every((orderProduct) => orderProduct.status !== 0) &&
            !order.confirmationDate && (
               <ErrorMessage bigger={true}>
                  <FontAwesomeIcon icon={faCircleExclamation} /> Confirmation non envoyée au client
               </ErrorMessage>
            )}
         <div className="order-details__main">
            <div>
               <section className="white-block order-details__date">
                  <h2>Date de réservation</h2>
                  <div>{formatJsonDate(order.createdAt, false, true, true)}</div>
               </section>
               {!onlyMeatCart && (
                  <section className="white-block order-details__delivery-date">
                     <div className="two-columns">
                        <h2>Créneau de retrait</h2>
                        {user?.isAdmin && order.status === 0 && !isEditModeHour && (
                           <div
                              className="edit-button"
                              onClick={() => {
                                 setDeliveryHour(shortenedJsonDate(order.deliveryDate));
                                 setIsEditModeHour(!isEditModeHour);
                              }}
                           >
                              <FontAwesomeIcon icon={faPenToSquare} />
                           </div>
                        )}
                     </div>

                     {isEditModeHour ? (
                        <div className="order-details__delivery-date-edit">
                           <Form.Control
                              size="sm"
                              type="datetime-local"
                              value={deliveryHour}
                              onChange={(e) => setDeliveryHour(e.target.value)}
                           />
                           <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => setIsEditModeHour(!isEditModeHour)}
                           >
                              Annuler
                           </Button>
                           <Button
                              variant="dark"
                              size="sm"
                              onClick={() => {
                                 updateOrder("deliveryDate", deliveryHour);
                                 setIsEditModeHour(false);
                              }}
                           >
                              Enregistrer
                           </Button>
                        </div>
                     ) : (
                        <div>{formatJsonDate(order.deliveryDate, false, true, true)}</div>
                     )}
                  </section>
               )}
               {order.meatDeliveryDate && (
                  <section className="white-block order-details__meat-delivery-date">
                     <div className="two-columns">
                        <h2>Créneau viande bovine</h2>
                        {user?.isAdmin && order.status === 0 && !isEditModeMeat && (
                           <div
                              className="edit-button"
                              onClick={() => {
                                 setDeliveryMeat(shortenedJsonDate(order.meatDeliveryDate));
                                 setIsEditModeMeat((prevState) => !prevState);
                              }}
                           >
                              <FontAwesomeIcon icon={faPenToSquare} />
                           </div>
                        )}
                     </div>

                     {isEditModeMeat ? (
                        <div className="order-details__meat-delivery-date-edit">
                           <Form.Control
                              size="sm"
                              type="datetime-local"
                              value={deliveryMeat}
                              onChange={(e) => setDeliveryMeat(e.target.value)}
                           />
                           <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => setIsEditModeMeat((prevState) => !prevState)}
                           >
                              Annuler
                           </Button>
                           <Button
                              variant="dark"
                              size="sm"
                              onClick={() => {
                                 updateOrder("meatDeliveryDate", deliveryMeat);
                                 setIsEditModeMeat(false);
                              }}
                           >
                              Enregistrer
                           </Button>
                        </div>
                     ) : (
                        <div>
                           À partir du {formatJsonDate(order.meatDeliveryDate, false, true, true)}
                        </div>
                     )}
                  </section>
               )}
               <section className="white-block order-details__user-message">
                  <h2>{user?.isAdmin ? "Message du client" : "Mon message"}</h2>
                  <div className={order.userMessage ? "warning-text" : "disabled-text"}>
                     {order.userMessage ? order.userMessage : "Aucun message"}
                  </div>
               </section>
               {user?.isAdmin && (
                  <section className="white-block order-details__user">
                     <h2>Client</h2>
                     <div className="two-columns-grid">
                        <span>N° client</span>
                        {order.UserId ? (
                           <span>{order.UserId}</span>
                        ) : (
                           <span className="disabled-text">Invité</span>
                        )}
                        <span>Nom</span>
                        <span>
                           {order.lastName} {order.firstName}
                        </span>
                        <span>E-mail</span>
                        <span>{order.email}</span>
                        <span>Téléphone</span>
                        <span>{order.phone}</span>
                        <span>Adresse</span>
                        {order.address ? (
                           <span>{order.address}</span>
                        ) : (
                           <span className="disabled-text">Non renseignée</span>
                        )}
                        <span>Code postal</span>
                        {order.postalCode ? (
                           <span>{order.postalCode}</span>
                        ) : (
                           <span className="disabled-text">Non renseigné</span>
                        )}
                        <span>Ville</span>
                        {order.city ? (
                           <span>{order.city}</span>
                        ) : (
                           <span className="disabled-text">Non renseignée</span>
                        )}
                     </div>
                  </section>
               )}
            </div>

            <section className="white-block order-details__products">
               {Object.keys(groupedProducts).map((groupKey, index) => (
                  <div key={`category-${index}`} className="order-details__group">
                     <div className="two-columns">
                        <h2>
                           {orderCategoriesUnique[groupKey]}
                           {categories.length > 0 &&
                              categories.find((category) => category.id === parseInt(groupKey))
                                 .title}
                        </h2>
                        {user?.isAdmin && order.status === 0 && (
                           <div className="order-details__quickies">
                              <button
                                 onClick={() =>
                                    changeAllProducts(
                                       groupedProducts[groupKey].map((prod) => {
                                          return { id: prod.id, status: 1 };
                                       }),
                                    )
                                 }
                              >
                                 Tout valider
                              </button>{" "}
                              |{" "}
                              <button
                                 onClick={() =>
                                    changeAllProducts(
                                       groupedProducts[groupKey].map((prod) => {
                                          return { id: prod.id, status: 3 };
                                       }),
                                    )
                                 }
                              >
                                 Tout annuler
                              </button>
                           </div>
                        )}
                     </div>
                     <table>
                        <thead>
                           <tr>
                              <th>Qté</th>
                              <th>Produit</th>
                              <th>P.U.</th>
                              <th>Total</th>
                              <th>{user?.isAdmin && "Statut"}</th>
                              {user?.isAdmin && <th></th>}
                           </tr>
                        </thead>
                        <tbody>
                           {groupedProducts[groupKey].map((product, productIndex) => (
                              <Fragment key={`product-${productIndex}`}>
                                 <tr>
                                    <td>{product.quantity}</td>
                                    <td>
                                       {product.title}
                                       {product.measurementValue &&
                                          ` • ${formatMeasurement(
                                             product.measurementValue,
                                             product.measurementUnit,
                                          )}`}
                                    </td>
                                    <td>{formatPrice(product.price)}</td>
                                    <td>{formatPrice(product.quantity * product.price)}</td>
                                    <td className={user?.isAdmin ? "admin" : ""}>
                                       <div className="order-details__select">
                                          <OverlayTrigger
                                             overlay={
                                                <Tooltip id="1">
                                                   {
                                                      statusProduct[
                                                         !user?.isAdmin && order.status === 0
                                                            ? 0
                                                            : product.status
                                                      ].title
                                                   }
                                                </Tooltip>
                                             }
                                          >
                                             <FontAwesomeIcon
                                                icon={
                                                   statusProduct[
                                                      !user?.isAdmin && order.status === 0
                                                         ? 0
                                                         : product.status
                                                   ].icon
                                                }
                                                className={`icon--${statusProduct[
                                                   !user?.isAdmin && order.status === 0
                                                      ? 0
                                                      : product.status
                                                ].variant
                                                   }`}
                                             />
                                          </OverlayTrigger>

                                          {user?.isAdmin && (
                                             <Form.Select
                                                value={product.status}
                                                disabled={order.confirmationDate}
                                                size="sm"
                                                onChange={(e) => {
                                                   updateProduct(
                                                      product.id,
                                                      "status",
                                                      e.target.value,
                                                   );
                                                   setErrorMessage();
                                                }}
                                             >
                                                {statusOrder.map((status, index) => (
                                                   <option
                                                      key={`status-option-${index}`}
                                                      value={index}
                                                   >
                                                      {status.title}
                                                   </option>
                                                ))}
                                             </Form.Select>
                                          )}
                                       </div>
                                    </td>

                                    {user?.isAdmin && (
                                       <td>
                                          <OverlayTrigger
                                             overlay={
                                                <Tooltip id="1">
                                                   Modifié le{" "}
                                                   {formatJsonDate(
                                                      product.updatedAt,
                                                      true,
                                                      true,
                                                      false,
                                                   )}
                                                   {product.User && (
                                                      <>
                                                         <br />
                                                         par {product.User.firstName}{" "}
                                                         {product.User.lastName}{" "}
                                                      </>
                                                   )}
                                                </Tooltip>
                                             }
                                          >
                                             <FontAwesomeIcon icon={faUserTag} />
                                          </OverlayTrigger>
                                       </td>
                                    )}
                                 </tr>
                                 {(product.status === 2 || product.status === 3) &&
                                    ((!user?.isAdmin && product.comment && order.confirmationDate) ||
                                       (user?.isAdmin &&
                                          (product.comment || !order.confirmationDate))) && (
                                       <tr>
                                          <td colSpan={6} className="order-details__comment">
                                             <div>
                                                <FontAwesomeIcon icon={faArrowTurnUp} />{" "}
                                                {product.comment &&
                                                   !productCommentsEdit[product.id] ? (
                                                   <>
                                                      <span>{product.comment}</span>
                                                      {!order.confirmationDate && (
                                                         <Button
                                                            variant="warning"
                                                            size="sm"
                                                            onClick={() => {
                                                               updateProductComment(
                                                                  product.id,
                                                                  product.comment,
                                                               );
                                                               updateProductCommentEdit(
                                                                  product.id,
                                                                  true,
                                                               );
                                                            }}
                                                         >
                                                            Modifier
                                                         </Button>
                                                      )}
                                                   </>
                                                ) : (
                                                   <>
                                                      <Form.Control
                                                         type="text"
                                                         placeholder="Commentaire pour le client"
                                                         size="sm"
                                                         value={productComments[product.id] ?? ""}
                                                         onChange={(e) =>
                                                            updateProductComment(
                                                               product.id,
                                                               e.target.value,
                                                            )
                                                         }
                                                      />
                                                      <Button
                                                         variant="warning"
                                                         size="sm"
                                                         onClick={() => {
                                                            updateProductCommentEdit(
                                                               product.id,
                                                               false,
                                                            );
                                                            updateProduct(
                                                               product.id,
                                                               "comment",
                                                               productComments[product.id],
                                                            );
                                                            setErrorMessage();
                                                         }}
                                                      >
                                                         Enregistrer
                                                      </Button>
                                                   </>
                                                )}
                                             </div>
                                          </td>
                                       </tr>
                                    )}
                              </Fragment>
                           ))}
                        </tbody>
                     </table>
                  </div>
               ))}
               {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
               <div className="two-columns two-columns--vertical-centered">
                  <div className="order-details__actions">
                     {user?.isAdmin && order.confirmationDate && (
                        <div className="disabled-text">
                           <FontAwesomeIcon icon={faEnvelope} /> Confirmation envoyée le :{" "}
                           {formatJsonDate(order.confirmationDate, false, true, true)}
                           {order.comment && (
                              <>
                                 <br />
                                 COMMENTAIRE : {order.comment}
                              </>
                           )}
                        </div>
                     )}
                     {user?.isAdmin && !order.confirmationDate && (
                        <Button variant="dark" onClick={() => checkReservation()}>
                           Envoyer la confirmation
                        </Button>
                     )}
                     {!user?.isAdmin && order.comment && (
                        <div className="disabled-text">COMMENTAIRE : {order.comment}</div>
                     )}
                  </div>
                  <div className="order-details__total-price">
                     Total : {formatPrice(order.totalPrice)}
                  </div>
               </div>
            </section>
         </div>

         {!user?.isAdmin && order.status === 0 && (
            <div className="order-details__cancel">
               <Button
                  className="btn-rounded"
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                     setShowCancelModal(true);
                  }}
               >
                  Annuler ma réservation
               </Button>
               <AlertBox
                  title={`Annuler ma réservation n°${order.id}`}
                  text="Êtes-vous sûr de vouloir annuler votre réservation ? Attention : cette action est définitive."
                  show={showCancelModal}
                  validateColor="danger"
                  onCancel={() => setShowCancelModal(false)}
                  onValidate={() => {
                     setShowCancelModal(false);
                     window.scroll(0, 0);
                     cancelOrder();
                  }}
               />
            </div>
         )}

         {user?.isAdmin && (
            <AlertBox
               show={showAlertBox}
               size="lg"
               title="Envoyer la confirmation au client"
               text={
                  <>
                     <div>
                        {order.OrderProducts.some(
                           (product) =>
                              (product.status === 2 || product.status === 3) && !product.comment,
                        ) ? (
                           <>
                              <span className="warning-text">
                                 Au moins un produit "annulé" ou "validé partiellement" n'a pas de
                                 commentaire à destination du client. Il est recommandé d'ajouter un
                                 commentaire pour chaque produit ou d'écrire un message général
                                 ci-dessous.
                              </span>
                           </>
                        ) : (
                           "Vous pouvez ajouter un message complémentaire au récapitulatif envoyé par e-mail au client."
                        )}
                     </div>
                     <br />
                     <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder={
                           order.status === 3
                              ? "Raison de l'annulation *"
                              : "Message au client (facultatif)"
                        }
                        aria-label="message au client"
                        value={modalComment}
                        onChange={(e) => setModalComment(e.target.value)}
                     />
                  </>
               }
               onValidate={() => {
                  sendConfirmation();
               }}
               onCancel={() => setShowAlertBox(false)}
            />
         )}
      </PageLayout>
   );
}

export default OrderDetails;
