import PropTypes from "prop-types";
import { useContext } from "react";
import { UserContext } from "../../contexts/userContext";
import OrderAccordion from "../OrderAccordion";

function UserOrders({ className }) {
   const { userData } = useContext(UserContext);

   if (!userData) {
      return <></>;
   }

   return (
      <section
         className={className ? `user-orders white-block ${className}` : "user-orders white-block"}
      >
         <h2>Mes réservations</h2>

         <div className="user-orders__table">
            <div className="user-orders__header">
               <div>N°</div>
               <div>Date</div>
               <div>Total</div>
               <div>État</div>
               <div></div>
            </div>

            {userData.Orders &&
               userData.Orders.length > 0 &&
               userData.Orders.map((order, index) => (
                  <OrderAccordion key={`order-${index}`} order={order} />
               ))}
         </div>

         {userData.Orders && userData.Orders.length === 0 && (
            <p className="user-orders__no-order">Aucune réservation</p>
         )}
      </section>
   );
}

UserOrders.propTypes = {
   className: PropTypes.string,
};

export default UserOrders;
