import { useState, useEffect, useCallback } from "react";

export const useFetch = (url, method = "GET", body, token, start = true) => {
   const [data, setData] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);

   const fetchData = useCallback(async () => {
      if (!url) return;
      setIsLoading(true);
      try {
         const fullUrl = `${process.env.REACT_APP_API_URL}/api/${url}`;
         const headers = {};
         if (token) headers["Authorization"] = `Bearer ${token}`;
         if (body) headers["Content-Type"] = "application/json;charset=utf-8";
         const options = { method: method, headers: headers };
         if (body) {
            options.body = JSON.stringify(body);
         }
         const response = await fetch(fullUrl, options);
         if (!response.ok) throw new Error(response.statusText);
         const json = await response.json();
         setIsLoading(false);
         setData(json);
         setError(null);
      } catch (error) {
         setError(`${error} Could not Fetch Data `);
         setIsLoading(false);
      }
   }, [url, method, body, token]);

   const reloadData = () => fetchData();

   useEffect(() => {
      if (start) fetchData();
   }, [fetchData, start]);

   return [data, isLoading, error, reloadData];
};
