import { Button, Form } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { useCallback, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../contexts/settingsContext";
import { formatDate } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import HoursSelector from "../../components/HoursSelector";
import { UserContext } from "../../contexts/userContext";
import ErrorBlock from "../../components/ErrorBlock";

function AdminSettings() {
   const pageTitle = "Paramètres du site";
   const closingEndRef = useRef();
   const [closingStartDate, setClosingStartDate] = useState("");
   const [closingEndDate, setClosingEndDate] = useState("");

   const navigate = useNavigate();
   const { modifySettings, settings } = useContext(SettingsContext);
   const { user } = useContext(UserContext);

   const handleSubmit = useCallback(
      async (e) => {
         e.preventDefault();
         modifySettings({
            closingStart: closingStartDate + " 00:00:00",
            closingEnd: closingEndDate + " 23:59:00",
         });
      },
      [modifySettings, closingStartDate, closingEndDate],
   );

   const cancelClosing = useCallback(
      async (e) => {
         modifySettings({
            closingStart: null,
            closingEnd: null,
         });
      },
      [modifySettings],
   );

   if ((user && !user.isAdmin) || user === null) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="admin-orders"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <ErrorBlock code={403} />
         </PageLayout>
      );
   }

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="admin-settings"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>
         <div className="admin-settings__blocks">
            <div className="white-block  admin-settings__closing">
               <h2>Fermeture exceptionnelle</h2>
               <p className="disabled-text">
                  <strong>
                     Attention : pour indiquer des horaires exceptionnels sur une journée
                     particulière, veuillez utiliser la fonction "horaires du magasin".
                  </strong>
               </p>
               {settings.closingStart && settings.closingEnd ? (
                  <div className="two-columns">
                     <div className="admin-settings__closed">
                        <FontAwesomeIcon icon={faCircleExclamation} /> Programmée du{" "}
                        {formatDate(settings.closingStart)} au {formatDate(settings.closingEnd)}
                     </div>
                     <div>
                        <Button variant="dark" onClick={() => cancelClosing()}>
                           Annuler
                        </Button>
                     </div>
                  </div>
               ) : (
                  <>
                     <Form onSubmit={(e) => handleSubmit(e)}>
                        <Form.Group controlId="closing-start" className="two-columns">
                           <Form.Label>Du</Form.Label>
                           <Form.Control
                              type="date"
                              onChange={(e) => {
                                 setClosingStartDate(e.target.value);
                                 closingEndRef.current.min = e.target.value;
                              }}
                              value={closingStartDate}
                              required
                           />
                        </Form.Group>

                        <Form.Group controlId="closing-end" className="two-columns">
                           <Form.Label>au</Form.Label>
                           <Form.Control
                              type="date"
                              ref={closingEndRef}
                              onChange={(e) => {
                                 setClosingEndDate(e.target.value);
                              }}
                              value={closingEndDate}
                              required
                           />
                        </Form.Group>

                        <div>
                           <Button type="submit" variant="primary">
                              Enregistrer
                           </Button>
                        </div>
                     </Form>
                     <p className="disabled-text">
                        Exemple : fermeture pour congés à partir du samedi midi, indiquer le
                        lendemain (dimanche) jusqu'à la veille de la réouverture (mardi).
                     </p>
                  </>
               )}
            </div>
            <div className="white-block">
               <h2>Conditions générales</h2>
               <br />
               <Button variant="primary" onClick={() => navigate("/conditions-generales")}>
                  Modifier
               </Button>
            </div>
            <div className="white-block">
               <h2>Horaires d'ouverture du magasin</h2>
               <br />
               <HoursSelector
                  type={0}
                  initialValues={
                     settings.Hours ? settings.Hours.filter((item) => item.type === 0) : []
                  }
               />
            </div>
            <div className="white-block">
               <h2>Créneaux de réservation</h2>
               <br />
               <HoursSelector
                  type={1}
                  initialValues={
                     settings.Hours ? settings.Hours.filter((item) => item.type === 1) : []
                  }
               />
            </div>
         </div>
      </PageLayout>
   );
}

export default AdminSettings;
