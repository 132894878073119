import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import ImageSelector from "../ImageSelector";

function EditBannerModal({ show, onClose, images }) {
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState();
   const [successMessage, setSuccessMessage] = useState();
   const [changedFile1, setChangedFile1] = useState(false);
   const [changedFile2, setChangedFile2] = useState(false);
   const [changedFile3, setChangedFile3] = useState(false);
   const [selectedFile1, setSelectedFile1] = useState();
   const [selectedFile2, setSelectedFile2] = useState();
   const [selectedFile3, setSelectedFile3] = useState();

   useEffect(() => {
      setChangedFile1(false);
      setChangedFile2(false);
      setChangedFile3(false);
      setSelectedFile1();
      setSelectedFile2();
      setSelectedFile3();
      setErrorMessage();
      setSuccessMessage();
      setIsLoading(false);
   }, [show]);

   const submitFiles = async () => {
      const submitFile = async (index) => {
         try {
            let formData = new FormData();
            const data = { index: index };
            switch (index) {
               case 1:
                  if (selectedFile1) formData.append("image", selectedFile1);
                  else data.deleted = true;
                  break;
               case 2:
                  if (selectedFile2) formData.append("image", selectedFile2);
                  else data.deleted = true;
                  break;
               case 3:
                  if (selectedFile3) formData.append("image", selectedFile3);
                  else data.deleted = true;
                  break;
               default:
                  break;
            }
            formData.append("homeBanner", JSON.stringify(data));
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/banner`, {
               method: "PUT",
               body: formData,
               credentials: 'include',
            });
            return response.ok;
         } catch (error) {
            return false;
         }
      };

      let totalPhotos = 0;

      setIsLoading(true);

      let errors = 0;
      if (changedFile1) {
         const success = await submitFile(1, totalPhotos);
         errors += !success;
      }
      if (changedFile2) {
         const success = await submitFile(2, totalPhotos);
         errors += !success;
      }
      if (changedFile3) {
         const success = await submitFile(3, totalPhotos);
         errors += !success;
      }
      if (errors > 0) {
         setErrorMessage("Une erreur s'est produite");
      } else {
         setSuccessMessage("Images modifiées");
      }
      setIsLoading(false);
   };

   return (
      <Modal
         size="lg"
         show={show}
         fullscreen="sm-down"
         aria-labelledby="edit-modal-title"
         centered
         className="edit-modal edit-banner-modal"
      >
         <Modal.Body>
            <h2 id="edit-modal-title">Modifier la bannière</h2>

            {successMessage ? (
               <div className="edit-modal__success">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {successMessage}
               </div>
            ) : (
               <>
                  <div className="edit-modal__grid">
                     <ImageSelector
                        imageUrl={
                           images[0] &&
                           images[0].length > 0 &&
                           images[0].find((image) => image.fallback).url
                        }
                        onChange={(newFile) => {
                           setSelectedFile1(newFile);
                           setChangedFile1(true);
                           setErrorMessage();
                        }}
                        selectorId="input1"
                        isEnabled={!isLoading}
                     />
                     <ImageSelector
                        imageUrl={
                           images[1] &&
                           images[1].length > 0 &&
                           images[1].find((image) => image.fallback).url
                        }
                        onChange={(newFile) => {
                           setSelectedFile2(newFile);
                           setChangedFile2(true);
                           setErrorMessage();
                        }}
                        selectorId="input2"
                        isEnabled={!isLoading}
                     />
                     <ImageSelector
                        imageUrl={
                           images[2] &&
                           images[2].length > 0 &&
                           images[2].find((image) => image.fallback).url
                        }
                        onChange={(newFile) => {
                           setSelectedFile3(newFile);
                           setChangedFile3(true);
                           setErrorMessage();
                        }}
                        selectorId="input3"
                        isEnabled={!isLoading}
                     />
                  </div>
                  {errorMessage && errorMessage.length > 0 && (
                     <ErrorMessage>{errorMessage}</ErrorMessage>
                  )}
               </>
            )}
         </Modal.Body>
         <Modal.Footer>
            {isLoading ? (
               <Loader />
            ) : (
               <>
                  <Button className="btn-rounded" onClick={onClose} variant="secondary">
                     Fermer
                  </Button>

                  {!successMessage && (
                     <Button className="btn-rounded" variant="primary" onClick={submitFiles}>
                        Valider
                     </Button>
                  )}
               </>
            )}
         </Modal.Footer>
      </Modal>
   );
}

export default EditBannerModal;
