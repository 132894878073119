import PolicyContent from "../../components/PolicyContent";
import PageLayout from "../../layouts/PageLayout";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import { SettingsContext } from "../../contexts/settingsContext";
import MarkdownEditor from "../../components/MarkdownEditor";

function Policy() {
   const pageTitle = "Conditions générales";
   const [isEditMode, setIsEditMode] = useState(false);
   const [text, setText] = useState("");
   const { user } = useContext(UserContext);
   const { settings, modifySettings } = useContext(SettingsContext);

   const savePolicy = async () => {
      await modifySettings({ policy: text });
      setIsEditMode(false);
   };

   return (
      <PageLayout pageTitle={pageTitle} className="policy" breadCrumbItems={[{ title: pageTitle }]}>
         {user?.isAdmin && !isEditMode && (
            <div
               className="edit-button"
               onClick={() => {
                  if (!isEditMode) {
                     setText(settings.policy);
                  }
                  setIsEditMode(!isEditMode);
               }}
            >
               <FontAwesomeIcon icon={faPenToSquare} />
            </div>
         )}
         <h1 className="page-title">{pageTitle}</h1>
         {isEditMode ? (
            <div className="policy-edit">
               <MarkdownEditor
                  markdown={text}
                  headings={["h2", "h3"]}
                  onChange={(newText) => setText(newText)}
               />
               <div className="hero-edit__buttons">
                  <Button variant="secondary" onClick={() => setIsEditMode(false)}>
                     Annuler
                  </Button>
                  <Button variant="dark" onClick={() => savePolicy()}>
                     Enregistrer
                  </Button>
               </div>
            </div>
         ) : (
            <PolicyContent />
         )}
      </PageLayout>
   );
}

export default Policy;
