import { Button, Form } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { useCallback, useState } from "react";
import { OrderWarningAddress } from "../../components/OrderWarning";
import ErrorMessage from "../../components/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { sendDataAPI } from "../../helpers";

function Contact() {
   const pageTitle = "Contact";
   const navigate = useNavigate();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [subject, setSubject] = useState("");
   const [message, setMessage] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [success, setSuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const handleSubmit = useCallback(
      (e) => {
         e.preventDefault();
         const sendData = async () => {
            try {
               const { success } = await sendDataAPI("contact", "POST", {
                  name: name,
                  email: email,
                  phone: phone,
                  subject: subject,
                  message: message,
               });
               if (success) {
                  setSuccess(true);
                  window.scroll(0, 0);
               } else {
                  setErrorMessage(
                     "Votre message n'a pas pu être envoyé. Merci de contacter directement le magasin.",
                  );
               }
            } catch (error) {
               setErrorMessage(
                  "Votre message n'a pas pu être envoyé. Merci de contacter directement le magasin.",
               );
            } finally {
               setIsLoading(false);
            }
         };
         setIsLoading(true);
         sendData();
      },
      [name, email, phone, subject, message],
   );

   const resetForm = () => {
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");
      setErrorMessage("");
   };

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="contact"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>
         <div className="contact__content two-columns">
            <div>
               <div className="order-warning white-block">
                  <FontAwesomeIcon className="order-warning__logo" icon={faCircleExclamation} />
                  <p className="order-warning__text">
                     Pour effectuer une réservation, merci de parcourir nos différentes catégories
                     et d'ajouter des produits à votre panier.
                  </p>
                  <Button
                     className="btn-rounded"
                     variant="secondary"
                     size="sm"
                     onClick={() => navigate("/produits")}
                  >
                     Commencer mes achats
                  </Button>
               </div>
               <div className="white-block">
                  <h2>Formulaire de contact</h2>
                  {success ? (
                     <div className="contact__success">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <h3>Votre message a bien été envoyé.</h3>
                        <p>L'équipe du Trèfle Blanc y répondra dans les meilleurs délais.</p>
                     </div>
                  ) : (
                     <Form onSubmit={handleSubmit}>
                        <Form.Control
                           type="text"
                           placeholder="Nom et prénom *"
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           required
                           disabled={isLoading}
                           autoComplete="name"
                        />
                        <Form.Control
                           type="email"
                           title="Par exemple : utilisateur@domaine.com"
                           pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                           placeholder="Email *"
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           required
                           disabled={isLoading}
                           autoComplete="email"
                        />
                        <Form.Control
                           type="text"
                           placeholder="Téléphone"
                           value={phone}
                           onChange={(e) => setPhone(e.target.value)}
                           disabled={isLoading}
                           autoComplete="tel"
                        />
                        <Form.Select
                           value={subject}
                           onChange={(e) => {
                              setSubject(e.target.value);
                           }}
                           required
                           disabled={isLoading}
                        >
                           <option value="">Objet du message... *</option>
                           <option value="produits">Les produits</option>
                           <option value="réservation">Une réservation</option>
                           <option value="compte">Mon compte</option>
                           <option value="magasin">Le magasin</option>
                           <option value="ferme">La Ferme du Trèfle Blanc</option>
                           <option value="autre">Autre demande</option>
                        </Form.Select>
                        <Form.Control
                           as="textarea"
                           rows={5}
                           placeholder="Message *"
                           value={message}
                           onChange={(e) => setMessage(e.target.value)}
                           required
                           disabled={isLoading}
                        />
                        {errorMessage.length !== 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}

                        <div className="two-columns">
                           <Button
                              className="btn-rounded"
                              type="reset"
                              variant="secondary"
                              disabled={isLoading}
                              onClick={() => resetForm()}
                           >
                              Réinitialiser
                           </Button>
                           <div className="two-columns two-columns--vertical-centered">
                              {isLoading && <Loader />}
                              <Button className="btn-rounded" type="submit" disabled={isLoading}>
                                 Envoyer
                              </Button>
                           </div>
                        </div>
                     </Form>
                  )}
               </div>
            </div>

            <div className="white-block">
               <h2>Coordonnées</h2>
               <OrderWarningAddress />
            </div>
         </div>
      </PageLayout>
   );
}

export default Contact;
