import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

/**
 * Message d'erreur générique
 * @return JSX.element
 */

function ErrorMessage({ children, isBordered = true, bigger = false, variant, onClose }) {
   return (
      <div
         className={`error-message ${onClose ? "error-message--left" : ""} ${variant ? `error-message--${variant}` : ""} ${bigger ? "error-message--bigger" : ""} ${isBordered ? "error-message--bordered" : ""}`}
      >
         {children}
         {onClose && <div className="error-message__close"><FontAwesomeIcon icon={faCircleXmark} onClick={onClose} /></div>}
      </div>
   );
}

ErrorMessage.propTypes = {
   isBordered: PropTypes.bool,
   bigger: PropTypes.bool,
   variant: PropTypes.string,
   onClose: PropTypes.func,
};

/*
ErrorMessage.defaultProps = {
   isBordered: true,
   bigger: false,
};*/

export default ErrorMessage;
