import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import PasswordStrength from "../../components/PasswordStrength";
import ErrorMessage from "../ErrorMessage";
import { useRef } from "react";
import { calculatePasswordStrength } from "../../helpers";

const initialUserInfo = {
   firstName: "",
   lastName: "",
   email: "",
   phone: "",
   address: "",
   postalCode: "",
   city: "",
   acceptMailing: "",
   oldPassword: "",
   newPassword: "",
   confirmPassword: "",
};

function UserInfo({ className }) {
   const { userData, deleteUserAccount, modifyUserAccount } = useContext(UserContext);
   const navigate = useNavigate();
   const [
      {
         firstName,
         lastName,
         email,
         phone,
         address,
         postalCode,
         city,
         acceptMailing,
         oldPassword,
         newPassword,
         confirmPassword,
      },
      setState,
   ] = useState(initialUserInfo);
   const [errorInfosMessage, setErrorInfosMessage] = useState();
   const [errorPasswordMessage, setErrorPasswordMessage] = useState();
   const [passwordStrength, setPasswordStrength] = useState(0);
   const [isEditingInfos, setIsEditingInfos] = useState(false);
   const [isEditingPassword, setIsEditingPassword] = useState(false);
   const [successPasswordChange, setSuccessPasswordChange] = useState(false);
   const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
   const [deleteAccountError, setDeleteAccountError] = useState(null);
   const confirmDeleteRef = useRef();

   useEffect(() => {
      setPasswordStrength(0);
      setErrorPasswordMessage();
      setState((prevState) => ({
         ...prevState,
         oldPassword: "",
         newPassword: "",
         confirmPassword: "",
      }));
   }, [isEditingPassword]);

   useEffect(() => {
      setErrorInfosMessage();
      if (userData) {
         setState((prevState) => ({
            ...prevState,
            firstName: userData.firstName ?? "",
            lastName: userData.lastName ?? "",
            email: userData.email ?? "",
            phone: userData.phone ?? "",
            address: userData.address ?? "",
            postalCode: userData.postalCode ?? "",
            city: userData.city ?? "",
            acceptMailing: userData.acceptMailing ?? "",
         }));
      }
   }, [userData, isEditingInfos]);

   useEffect(() => {
      // Met à jour l'indcateur de mot de passe
      const strength = calculatePasswordStrength(newPassword);
      setPasswordStrength(strength);
   }, [newPassword]);

   const checkPassword = (e) => {
      // Empêche la saisie d'un espace vide
      if (/\s/.test(e.target.value) === false) {
         onChange(e);
      }
      setErrorPasswordMessage("");
   };

   const handleSubmitInfos = useCallback(
      async (e) => {
         e.preventDefault();

         const userInfo = {
            firstName,
            lastName,
            phone,
            email,
            acceptMailing,
            address,
            postalCode,
            city,
         };
         const code = await modifyUserAccount(userInfo);

         if (code === 200) {
            setIsEditingInfos(false);
         } else {
            setErrorInfosMessage("Une erreur est survenue");
         }
      },
      [
         modifyUserAccount,
         firstName,
         lastName,
         phone,
         email,
         acceptMailing,
         address,
         postalCode,
         city,
      ],
   );

   const handleSubmitPassword = useCallback(
      async (e) => {
         e.preventDefault();

         if (newPassword !== confirmPassword) {
            setErrorPasswordMessage("Les nouveaux mots de passe ne correspondent pas.");
            return;
         }

         if (oldPassword === newPassword) {
            setErrorPasswordMessage("Le nouveau mot de passe doit être différent de l'ancien.");
            return;
         }

         if (passwordStrength <= 2) {
            setErrorPasswordMessage(
               "Veuillez choisir un mot de passe plus complexe (en ajoutant des majuscules, des caractères spéciaux, des nombres...).",
            );
            return;
         }

         const userInfo = {
            oldPassword,
            newPassword,
         };
         const code = await modifyUserAccount(userInfo);
         if (code === 200) {
            setSuccessPasswordChange(true)
            setIsEditingPassword(false);
         } else if (code === 401) {
            setErrorPasswordMessage("Ancien mot de passe incorrect");
         } else {
            setErrorPasswordMessage("Une erreur est survenue");
         }
      },
      [oldPassword, newPassword, confirmPassword, passwordStrength, modifyUserAccount],
   );

   if (!userData) {
      return <></>;
   }

   const onChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
   };

   return (
      <div className={`user-info ${className ?? ""}`}>
         <section className="white-block">
            <h2>Mes informations</h2>
            <Form onSubmit={handleSubmitInfos}>
               <div className="two-columns-grid">
                  <span>N° client</span>
                  <span>{userData.id}</span>
                  <span>Nom</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="text"
                        value={lastName}
                        name="lastName"
                        onChange={onChange}
                        placeholder="Obligatoire *"
                        size="sm"
                        required
                        autoComplete="family-name"
                     />
                  ) : (
                     <span>{userData.lastName}</span>
                  )}
                  <span>Prénom</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="text"
                        value={firstName}
                        name="firstName"
                        onChange={onChange}
                        placeholder="Obligatoire *"
                        size="sm"
                        required
                        autoComplete="given-name"
                     />
                  ) : (
                     <span>{userData.firstName}</span>
                  )}
                  <span>E-mail</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="email"
                        value={email}
                        name="email"
                        onChange={onChange}
                        placeholder="Obligatoire *"
                        size="sm"
                        required
                        title="Par exemple : utilisateur@domaine.com"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        autoComplete="email"
                     />
                  ) : (
                     <span className="breakable-text">{userData.email}</span>
                  )}
                  <span>Téléphone</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="tel"
                        value={phone}
                        pattern="^((\+|00)33\s?|0)[1-9]([\s\.]?\d{2}){4}$"
                        name="phone"
                        onChange={onChange}
                        placeholder="Obligatoire *"
                        size="sm"
                        required
                        autoComplete="tel"
                     />
                  ) : (
                     <span>{userData.phone}</span>
                  )}

                  <span>Adresse</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="text"
                        value={address}
                        name="address"
                        onChange={onChange}
                        size="sm"
                        autoComplete="street-address"
                     />
                  ) : userData.address ? (
                     <span>{userData.address}</span>
                  ) : (
                     <span className="disabled-text">Non renseignée</span>
                  )}

                  <span>Code postal</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="text"
                        pattern="[0-9]{5}"
                        value={postalCode}
                        name="postalCode"
                        onChange={onChange}
                        size="sm"
                        autoComplete="postal-code"
                     />
                  ) : userData.postalCode ? (
                     <span>{userData.postalCode}</span>
                  ) : (
                     <span className="disabled-text">Non renseigné</span>
                  )}
                  <span>Ville</span>
                  {isEditingInfos ? (
                     <Form.Control
                        type="text"
                        value={city}
                        name="city"
                        onChange={onChange}
                        size="sm"
                        autoComplete="home city"
                     />
                  ) : userData.city ? (
                     <span>{userData.city}</span>
                  ) : (
                     <span className="disabled-text">Non renseignée</span>
                  )}
               </div>
               <Form.Check
                  type="checkbox"
                  className="user-info__accept-mailing"
                  disabled={!isEditingInfos}
                  label={`${acceptMailing ? "Je souhaite" : "Je ne souhaite pas"
                     } recevoir des offres et informations sur les produits`}
                  checked={acceptMailing}
                  name="acceptMailing"
                  onChange={(e) =>
                     setState((prevState) => ({ ...prevState, acceptMailing: e.target.checked }))
                  }
               />

               {isEditingInfos ? (
                  <>
                     {errorInfosMessage && <ErrorMessage>{errorInfosMessage}</ErrorMessage>}
                     <Button className="btn-rounded" size="sm" type="submit">
                        Enregistrer
                     </Button>{" "}
                     <Button
                        size="sm"
                        className="btn-rounded"
                        variant="secondary"
                        onClick={() => setIsEditingInfos(false)}
                     >
                        Annuler
                     </Button>
                  </>
               ) : (
                  <Button
                     className="btn-rounded"
                     variant="primary"
                     size="sm"
                     onClick={() => setIsEditingInfos(true)}
                  >
                     Modifier
                  </Button>
               )}
            </Form>
         </section>
         <section className="white-block">
            <h2>Mon mot de passe</h2>
            <div>
               {successPasswordChange && (
                  <>
                     <ErrorMessage variant="success" onClose={() => setSuccessPasswordChange(false)}>Mot de passe correctement modifié</ErrorMessage>
                  </>
               )}
               {!successPasswordChange && !isEditingPassword && (
                  <Button
                     className="btn-rounded"
                     variant="primary"
                     size="sm"
                     onClick={() => setIsEditingPassword(true)}
                  >
                     Modifier
                  </Button>
               )}
               {!successPasswordChange && isEditingPassword && (
                  <Form onSubmit={handleSubmitPassword}>
                     <div className="two-columns-grid">
                        <span>Ancien</span>
                        <Form.Control
                           type="password"
                           value={oldPassword}
                           name="oldPassword"
                           onChange={onChange}
                           autoComplete="current-password"
                           size="sm"
                           required
                        />
                        <span>Nouveau</span>
                        <Form.Control
                           type="password"
                           value={newPassword}
                           name="newPassword"
                           onChange={checkPassword}
                           autoComplete="new-password"
                           size="sm"
                           required
                        />
                        <span>Confirmer</span>
                        <Form.Control
                           type="password"
                           value={confirmPassword}
                           name="confirmPassword"
                           onChange={checkPassword}
                           autoComplete="new-password"
                           size="sm"
                           required
                        />
                     </div>
                     <PasswordStrength strength={passwordStrength} />
                     {errorPasswordMessage && <ErrorMessage>{errorPasswordMessage}</ErrorMessage>}

                     <Button className="btn-rounded" size="sm" type="submit">
                        Enregistrer
                     </Button>{" "}
                     <Button
                        size="sm"
                        className="btn-rounded"
                        variant="secondary"
                        onClick={() => setIsEditingPassword(false)}
                     >
                        Annuler
                     </Button>
                  </Form>
               )}
            </div>
         </section>
         <div className="user-info__delete">
            <Link onClick={() => { setDeleteAccountError(null); setShowDeleteAccountModal(true) }}>Supprimer mon compte</Link>
         </div>
         <AlertBox
            title="Supprimer definitivement mon compte"
            text={
               <>
                  <p>
                     Êtes-vous sûr de vouloir supprimer definitivement votre compte ? Attention,
                     toutes vos données personnelles seront supprimées y compris vos éventuelles
                     réservations en cours.
                  </p>
                  <div className="user-info__delete-checkbox">
                     <Form.Check
                        type="checkbox"
                        id="confirm-delete"
                        label="Je confirme la suppression définitive de mon compte"
                        ref={confirmDeleteRef}
                     />
                  </div>
                  {deleteAccountError && <ErrorMessage>{deleteAccountError}</ErrorMessage>}
               </>
            }
            show={showDeleteAccountModal}
            validateColor="danger"
            onCancel={() => setShowDeleteAccountModal(false)}
            onValidate={async () => {
               if (confirmDeleteRef.current.checked) {
                  const success = await deleteUserAccount();
                  if (success) {
                     navigate("/compte?deleted=true")
                  } else {
                     setDeleteAccountError("Suppression impossible de votre compte, merci de nous contacter.");
                  }
               } else {
                  setDeleteAccountError("Veuillez cocher la case ci-dessus.");
               }
            }}
         />
      </div>
   );
}

UserInfo.propTypes = {
   className: PropTypes.string,
};

export default UserInfo;
