import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { formatJsonDate, formatPrice } from "../../helpers";
import Tag from "../Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { SettingsContext } from "../../contexts/settingsContext";
import { statusOrder } from "../../constants";

/**
 * Infos de réservation sous forme de ligne
 * @return JSX.element
 */

function OrderAccordion({ order, isAdmin = false }) {
   const { categories } = useContext(SettingsContext);
   const navigate = useNavigate();

   const orderCategoriesUnique = useMemo(() => {
      if (order && categories && categories.length > 0) {
         const orderCategories = order.OrderProducts.map((product) => product.ProductCategoryId);
         const unique = [...new Set(orderCategories)].sort();
         const pictures = {};
         unique.forEach((categoryId) => {
            const icons = categories.find((item) => item.id === categoryId);
            if (icons) {
               pictures[categoryId] = (
                  <picture key={`picto-cat-${categoryId}`}>
                     {icons.iconUrl.map((icon, iconIndex) =>
                        icon.fallback ? (
                           <img
                              key={`picto-image-${iconIndex}`}
                              src={icon.url}
                              alt=""
                              className="order-accordion__picto"
                           />
                        ) : (
                           <source
                              key={`picto-image-${iconIndex}`}
                              srcSet={icon.url}
                              type={`image/${icon.type}`}
                           />
                        ),
                     )}
                  </picture>
               );
            }
         });
         return pictures;
      }
      return [];
   }, [order, categories]);

   const isWarning =
      !order.confirmationDate && order.OrderProducts.every((product) => product.status !== 0);

   return (
      <div
         className={`${isAdmin ? "order-accordion__line-admin" : "order-accordion__line"} ${
            isAdmin && isWarning ? "order-accordion__line--warning" : ""
         }`}
         onClick={() =>
            navigate(
               isAdmin ? `/admin/reservations/${order.id}` : `/compte/reservations/${order.id}`,
            )
         }
      >
         <div>{order.id}</div>
         <div>{formatJsonDate(order.createdAt, true)}</div>
         <div>{formatPrice(order.totalPrice)}</div>
         {isAdmin && (
            <>
               <div>
                  {Object.keys(orderCategoriesUnique).map((catID) => orderCategoriesUnique[catID])}
               </div>
               <div>{order.lastName}</div>
            </>
         )}
         <div>
            <div className="two-columns two-columns--vertical-centered">
               <Tag
                  title={statusOrder[order.status].title}
                  variant={statusOrder[order.status].variant}
               />
               {isAdmin && isWarning && (
                  <p className="warning-text">
                     <FontAwesomeIcon icon={faCircleExclamation} /> Confirmation non envoyée
                  </p>
               )}
            </div>
         </div>
         <div>
            <FontAwesomeIcon icon={faChevronRight} />
         </div>
      </div>
   );
}

OrderAccordion.propTypes = {
   order: PropTypes.object.isRequired,
   isAdmin: PropTypes.bool,
};

/*OrderAccordion.defaultProps = {
   isAdmin: false,
};*/

export default OrderAccordion;
