import { Button, Form } from "react-bootstrap";
import { useState, useContext, useEffect, Fragment } from "react";
import { SettingsContext } from "../../contexts/settingsContext";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp, faTrash } from "@fortawesome/free-solid-svg-icons";

function HoursSelector({ type, initialValues }) {
   const [hoursList, setHoursList] = useState([]);
   const { modifySettings, isLoadingSettings } = useContext(SettingsContext);

   useEffect(() => {
      if (initialValues) {
         setHoursList(initialValues);
      }
   }, [initialValues]);

   const addLine = () => {
      const newLine = {
         type: type,
         weekDay: "-1",
         startTime: "00:00",
         endTime: "00:00",
         temporary: false,
      };
      const newArray = Array.from(hoursList);
      newArray.push(newLine);
      setHoursList(newArray);
   };

   const removeLine = (index) => {
      const newArray = Array.from(hoursList);
      newArray.splice(index, 1);
      setHoursList(newArray);
   };

   const modifyLine = (index, key, value) => {
      const newArray = Array.from(hoursList);
      const lineToModify = newArray[index];
      lineToModify[key] = value;
      setHoursList(newArray);
   };

   return (
      <div className="hours-selector">
         <p className="hours-selector__header">
            <FontAwesomeIcon icon={faArrowTurnUp} />
            {type === 0 ? "Horaire" : "Créneau"} exceptionnel
         </p>
         <Form
            onSubmit={(e) => {
               e.preventDefault();
               modifySettings({ hours: hoursList });
            }}
         >
            <div className="hours-selector__grid">
               {hoursList.length > 0 &&
                  hoursList.map((line, index) => (
                     <Fragment key={`hour-selector-${index}`}>
                        <Form.Check
                           type="checkbox"
                           checked={line.temporary}
                           onChange={(e) => modifyLine(index, "temporary", e.target.checked)}
                        />
                        {line.temporary ? (
                           <Form.Control
                              className={line.temporary ? "hours-selector__temporary" : ""}
                              type="date"
                              value={line.temporaryDate ?? ""}
                              onChange={(e) => modifyLine(index, "temporaryDate", e.target.value)}
                              required
                           />
                        ) : (
                           <Form.Select
                              className={line.temporary ? "hours-selector__temporary" : ""}
                              value={line.weekDay}
                              onChange={(e) => modifyLine(index, "weekDay", e.target.value)}
                              disabled={isLoadingSettings}
                              required
                           >
                              <option value="">Choisir un jour...</option>
                              <option value="1">Lundi</option>
                              <option value="2">Mardi</option>
                              <option value="3">Mercredi</option>
                              <option value="4">Jeudi</option>
                              <option value="5">Vendredi</option>
                              <option value="6">Samedi</option>
                              <option value="7">Dimanche</option>
                           </Form.Select>
                        )}
                        <Form.Control
                           className={line.temporary ? "hours-selector__temporary" : ""}
                           type="time"
                           value={line.startTime}
                           aria-labelledby="Heure de début"
                           onChange={(e) => modifyLine(index, "startTime", e.target.value)}
                           disabled={isLoadingSettings}
                        />
                        <Form.Control
                           className={line.temporary ? "hours-selector__temporary" : ""}
                           type="time"
                           value={line.endTime}
                           aria-labelledby="Heure de fin"
                           onChange={(e) => modifyLine(index, "endTime", e.target.value)}
                           disabled={isLoadingSettings}
                        />
                        <Button
                           variant="dark"
                           onClick={() => removeLine(index)}
                           disabled={isLoadingSettings}
                        >
                           <FontAwesomeIcon icon={faTrash} />
                        </Button>
                     </Fragment>
                  ))}
            </div>
            <div className="hours-selector__buttons">
               <Button variant="dark" onClick={() => addLine()} disabled={isLoadingSettings}>
                  Ajouter un horaire
               </Button>
               {hoursList.length > 0 && !isLoadingSettings && (
                  <Button variant="primary" type="submit">
                     Enregistrer
                  </Button>
               )}
               {isLoadingSettings && <Loader />}
            </div>
         </Form>
      </div>
   );
}

export default HoursSelector;
