import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { SettingsContext } from "../../contexts/settingsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import EditBannerModal from "../EditBannerModal";

/**
 * Bannière avec un slider d'images
 * @return JSX.element
 */
function Banner({ images, showBioLogo = false }) {
   const { user } = useContext(UserContext);
   const { loadSettings } = useContext(SettingsContext);
   const [showEditModal, setShowEditModal] = useState(false);

   if (!images) {
      return <div className="banner"></div>;
   }

   return (
      <div className="banner">
         {showBioLogo && (
            <img
               className="banner__logo-bio"
               src="/assets/logos/bio.png"
               srcSet="/assets/logos/bio@2x.png 2x"
               alt="logo de certification biologique"
            />
         )}
         {user?.isAdmin && (
            <div className="edit-button" onClick={() => setShowEditModal(true)}>
               <FontAwesomeIcon icon={faPenToSquare} />
            </div>
         )}
         {images.length === 1 && (
            <picture>
               {images[0].map((image, index) =>
                  image.fallback ? (
                     <img
                        key={`banner-image-${index}`}
                        className="d-block w-100 object-fit-cover"
                        src={image.url}
                        alt=""
                     />
                  ) : (
                     <source
                        key={`banner-image-${index}`}
                        srcSet={image.url}
                        type={`image/${image.type}`}
                     />
                  ),
               )}
            </picture>
         )}
         {images.length > 1 && (
            <Carousel>
               {images.map((image, index) =>
                  image.length > 0 ? (
                     <Carousel.Item key={`banner-item-${index}}`}>
                        <picture>
                           {image.map((item, index1) =>
                              item.fallback ? (
                                 <img
                                    key={`banner-item-${index1}`}
                                    className="d-block w-100 object-fit-cover"
                                    src={item.url}
                                    alt=""
                                 />
                              ) : (
                                 <source
                                    key={`banner-item-${index1}`}
                                    srcSet={item.url}
                                    type={`image/${item.type}`}
                                 />
                              ),
                           )}
                        </picture>
                     </Carousel.Item>
                  ) : (
                     ""
                  ),
               )}
            </Carousel>
         )}
         {user?.isAdmin && (
            <EditBannerModal
               show={showEditModal}
               images={images}
               onClose={() => {
                  setShowEditModal(false);
                  loadSettings(["settings"]);
               }}
            />
         )}
      </div>
   );
}

Banner.propTypes = {
   images: PropTypes.array,
   showBioLogo: PropTypes.bool,
};

/*
Banner.defaultProps = {
   showBioLogo: false,
};*/

export default Banner;
