import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import PageLayout from "../../layouts/PageLayout";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function AccountConfirmation() {
   const pageTitle = "Confirmation de compte";
   const { token } = useParams();
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(true);
   const [isConfirmed, setIsConfirmed] = useState(false);
   const [errorMessage, setErrorMessage] = useState();

   useEffect(() => {
      const confirmAccount = async () => {
         if (!token) return;
         try {
            const response = await fetch(
               `${process.env.REACT_APP_API_URL}/api/users/confirmation`,
               {
                  method: "POST",
                  headers: {
                     Authorization: `Bearer ${token}`,
                  },
               },
            );
            if (response.status === 200) {
               setIsConfirmed(true);
            } else {
               setErrorMessage("Une erreur s'est produite");
            }
         } catch (error) {
            setErrorMessage("Une erreur s'est produite");
         } finally {
            setIsLoading(false);
         }
      };

      confirmAccount();
   }, [token]);

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="account-confirmation"
         breadCrumbItems={[{ title: "Compte", url: "/compte" }, { title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>
         <div className="white-block account-confirmation__content">
            {isLoading && (
               <>
                  <h2>Veuillez patienter...</h2>
                  <Loader />
               </>
            )}
            {isConfirmed && (
               <>
                  <h2>Votre compte est confirmé</h2>
                  <Button className="btn-rounded" onClick={() => navigate("/compte")}>
                     Se connecter
                  </Button>
               </>
            )}
            {!isConfirmed && errorMessage && (
               <>
                  <h2>{errorMessage}</h2>
                  <Button className="btn-rounded" onClick={() => navigate("/contact")}>
                     Nous contacter
                  </Button>
               </>
            )}
         </div>
      </PageLayout>
   );
}

export default AccountConfirmation;
