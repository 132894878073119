import { SettingsContext } from "../../contexts/settingsContext";
import { useContext, useMemo } from "react";
import { groupedBy } from "../../helpers";
import { formatJsonTime } from "../../helpers";

/**
 * Gestion d'horaires d'ouverture ou de créneaux
 * @return JSX.element
 */

function OpeningHours() {
   const { settings } = useContext(SettingsContext);

   const openingHours = useMemo(() => {
      const filtered = settings.Hours ? settings.Hours.filter((hour) => hour.type === 0 && !hour.temporary) : [];
      return groupedBy(filtered, "weekDay");
   }, [settings]);

   const weekDays = {
      1: "Lundi",
      2: "Mardi",
      3: "Mercredi",
      4: "Jeudi",
      5: "Vendredi",
      6: "Samedi",
      7: "Dimanche",
   };

   return (
      <div>
         {Object.keys(openingHours).map((keyItem, index) => (
            <p key={`opening-${index}`}>
               {weekDays[keyItem]} :{" "}
               {openingHours[keyItem].map((hour, indexHour) => (
                  <span key={`opening-${index}-${indexHour}`}>
                     {formatJsonTime("2023-01-01T" + hour.startTime)} -{" "}
                     {formatJsonTime("2023-01-01T" + hour.endTime)}
                     {openingHours[keyItem].length > 1 &&
                        indexHour < openingHours[keyItem].length - 1 &&
                        " et "}
                  </span>
               ))}
            </p>
         ))}
      </div>
   );
}

export default OpeningHours;
