import BreadCrumb from "../../components/BreadCrumb";
import Header from "../../components/Header";
import CartModal from "../../components/CartModal";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";

function PageLayout({ children, pageTitle, className, breadCrumbItems }) {
   return (
      <>
         <Helmet>
            <title>{pageTitle} - Ferme du Trèfle Blanc</title>
         </Helmet>
         <ScrollToTop />
         <Header />
         <main className={`${className} page`}>
            {breadCrumbItems && <BreadCrumb items={breadCrumbItems} />}
            <div className="page-container">{children}</div>
         </main>
         <CartModal />
         <Footer />
      </>
   );
}

export default PageLayout;
