import PropTypes from "prop-types";

/**
 * Composant affichant une animation de chargement
 * @return JSX.element
 */

function Loader({ variant = "normal", text }) {
   return (
      <div className={`loader ${variant ? `loader--${variant}` : ""}`}>
         { text && <div className="loader__text">{text}</div> }
         <div className="loader__dots">
            <span className={`loader__dot loader__dot${variant ? `--${variant}` : ""} loader__dot-1`}></span>
            <span className={`loader__dot loader__dot${variant ? `--${variant}` : ""} loader__dot-2`}></span>
            <span className={`loader__dot loader__dot${variant ? `--${variant}` : ""} loader__dot-3`}></span>
         </div>
      </div>
   );
}

Loader.propTypes = {
   variant: PropTypes.string,
   text: PropTypes.string,
};

export default Loader;
