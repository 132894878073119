import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import OpeningHours from "../OpeningHours";

/**
 * Coordonnées de la boutique
 * @return JSX.element
 */

function OrderWarningAddress() {
   return (
      <div className="order-warning-address">
         <div>
            <img src="/assets/map.jpg" alt="carte d'accès au magasin" />
         </div>
         <div>
            <p className="order-warning-address__block">
               <strong>Magasin du Trèfle Blanc</strong>
               <br />6 route du Saz
               <br />44240 La Chapelle-sur-Erdre
               <br />
               Téléphone : 02 55 11 94 66
            </p>
            <div className="order-warning-address__block">
               <p>
                  <strong>Horaires d'ouverture</strong>
               </p>
               <OpeningHours />
            </div>
            <p className="order-warning-address__block">
               <strong>Coordonnées GPS</strong>
               <br />
               47.3083748, -1.5756712
               <br />
               <a
                  href="https://www.google.com/maps/place/magasin+du+Trèfle+Blanc/@47.3083784,-1.5687189,17z/data=!3m1!4b1!4m6!3m5!1s0x4805f38ebc618bff:0x7c7a22bf46b9e6a1!8m2!3d47.3083748!4d-1.566144!16s%2Fg%2F11hfyf5m0f?hl=fr-FR&entry=ttu"
                  target="_blank"
                  rel="noreferrer"
               >
                  Ouvrir dans Google Maps
               </a>
            </p>
         </div>
      </div>
   );
}

/**
 * Block d'information au client sur l'adresse de retrait
 * @return JSX.element
 */

function OrderWarning() {
   const [showModal, setShowModal] = useState(false);

   return (
      <div className="order-warning white-block">
         <FontAwesomeIcon className="order-warning__logo" icon={faCircleExclamation} />
         <p className="order-warning__text">
            Les réservations sont à retirer et à payer : <br /> 6 route du Saz, 44240 LA
            CHAPELLE-SUR-ERDRE
         </p>
         <Button
            className="btn-rounded"
            variant="secondary"
            size="sm"
            onClick={() => setShowModal(true)}
         >
            Plus d'infos
         </Button>
         <AlertBox
            text={<OrderWarningAddress />}
            size="lg"
            show={showModal}
            onCancel={() => setShowModal(false)}
         />
      </div>
   );
}

export { OrderWarning, OrderWarningAddress };
