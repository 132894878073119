import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

/**
 * Bouton en forme de Card
 * @return JSX.element
 */

function ButtonCard({ title, onClick, variant }) {
   return (
      <article
         className={`generic-card button-card ${variant ? `button-card--${variant}` : ""}`}
         onClick={onClick}
      >
         <FontAwesomeIcon icon={faPlus} />
         <div className="button-card__title">{title}</div>
      </article>
   );
}

ButtonCard.propTypes = {
   title: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   variant: PropTypes.string,
};

export default ButtonCard;
