import { createContext, useCallback, useState } from "react";
import { sendDataAPI } from "../helpers";
import { normalizeName } from "../helpers";
import removeMarkdown from "remove-markdown";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [isErrorSearch, setIsErrorSearch] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const processSearch = useCallback(async (query) => {
        const cats = {products: "Produits", news: "Actualités", about: "Qui sommes-nous"};
        setSearchQuery(query)
        if (query.length === 0) {
            setSearchResults(null);
            return;
        }
        setIsLoadingSearch(true);
        try {
            const queryEnc = encodeURI(query);
            const { success, data } = await sendDataAPI(`search/?q=${queryEnc}`, "GET", null, true);
            if (!success) {
                setIsLoadingSearch(false);
                setIsErrorSearch(true);
            } else {
                let results = data.results;
                for (let result of results) {
                    if (result.category === "products") {
                        result.url += `/${normalizeName(result.title)}-${result.id}`
                    } else if (result.category === "news") {
                        result.url += `/${normalizeName(result.title)}-${result.id}`
                    } else if (result.category === "about") {
                        result.summary = removeMarkdown(result.summary);
                    }
                    result.category = cats[result.category] || result.category;
                }
                setSearchResults(results);
                setIsLoadingSearch(false);
                setIsErrorSearch(false);
            }
        } catch (err) {
            setIsLoadingSearch(false);
            setIsErrorSearch(true);
        }
    }, []);

    return (
        <SearchContext.Provider
           value={{
              searchResults,
              searchQuery,
              processSearch,
              isErrorSearch,
              isLoadingSearch,
           }}
        >
           {children}
        </SearchContext.Provider>
    );
};
