import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/userContext";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import ImageSelector from "../ImageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import MarkdownEditor from "../MarkdownEditor";

const initialAbout = {
   imagePosition: "right",
   imageSize: "large",
   imageZoom: "cover",
   text: "",
};

function EditAboutUsModal({ show, onClose, onRefresh, block, pageId, greatestPosition }) {
   const [{ imagePosition, imageSize, imageZoom, text }, setState] = useState(initialAbout);
   const [selectedFile, setSelectedFile] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState();
   const [successMessage, setSuccessMessage] = useState();

   const { user } = useContext(UserContext);

   const onChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
   };

   useEffect(() => {
      if (block && !block.newBlock) {
         setState({
            imagePosition: block.imagePosition,
            imageSize: block.imageSize,
            imageZoom: block.imageZoom,
            text: block.text,
         });
      } else {
         setState({ ...initialAbout });
      }
      setSelectedFile();
      setErrorMessage();
      setSuccessMessage();
   }, [block, show]);

   const handleSubmit = (e) => {
      e.preventDefault();

      //const text = mdRef.current.getMarkdown();

      if (!text || text.length < 5) {
         setErrorMessage("Merci de saisir un texte d'une longueur de plus de 50 caractères");
         return;
      }

      if (selectedFile && selectedFile.size > 10485760) {
         setErrorMessage("Merci de choisir une photo d'une taille inférieure à 10 Mo");
         return;
      }

      setIsLoading(true);
      const sendData = async () => {
         if (!user) {
            return;
         }
         try {
            let formData = new FormData();
            const blockData = {
               text: text,
               imagePosition: imagePosition,
               imageSize: imageSize,
               imageZoom: imageZoom,
            };
            if (selectedFile === null) {
               // L'image a été retirée
               blockData.deleteImage = true;
            }

            if (block.newBlock) {
               blockData.order = greatestPosition + 1;
            }

            formData.append("aboutBlock", JSON.stringify(blockData));
            if (selectedFile) {
               formData.append("image", selectedFile);
            }

            const url =
               process.env.REACT_APP_API_URL +
               (!block.newBlock
                  ? "/api/abouts/" + pageId + "/blocks/" + block.id
                  : "/api/abouts/" + pageId + "/blocks");
            const method = !block.newBlock ? "PUT" : "POST";

            const response = await fetch(url, {
               method: method,
               body: formData,
               credentials: 'include',
            });
            if (response.status === 201) {
               setSuccessMessage("Block bien créé");
               onRefresh();
            } else if (response.status === 200) {
               setSuccessMessage("Block bien modifié");
               onRefresh();
            } else {
               setErrorMessage(
                  `Erreur, le block ne peut être ${!block.newBlock ? "modifié" : "créé"}`,
               );
            }
         } catch (error) {
            setErrorMessage(error);
         } finally {
            setIsLoading(false);
         }
      };
      sendData();
   };

   return (
      <Modal
         size="xl"
         show={show}
         fullscreen="sm-down"
         aria-labelledby="edit-modal-title"
         centered
         className="edit-modal edit-aboutus-modal"
      >
         <Modal.Body>
            <h2 id="edit-modal-title">
               {block ? "Modifier un block" : "Ajouter un nouveau block"}
            </h2>

            {successMessage ? (
               <div className="edit-modal__success">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {successMessage}
               </div>
            ) : (
               <Form onSubmit={handleSubmit} id="edit-modal-form">
                  <div className="edit-modal__columns">
                     <div>
                        <h3>Informations générales</h3>
                        <div className="edit-modal__grid">
                           <MarkdownEditor
                              className="edit-modal__colspan"
                              markdown={text}
                              headings={["h2", "h3"]}
                              onChange={(newText) =>
                                 setState((prevState) => ({ ...prevState, text: newText }))
                              }
                           />
                        </div>
                     </div>
                     <div>
                        <h3>Photo</h3>
                        <Form.Select
                           className="edit-aboutus-modal__image-position"
                           value={imagePosition}
                           name="imagePosition"
                           onChange={onChange}
                        >
                           <option value="left">Positionnée à gauche</option>
                           <option value="right">Positionnée à droite</option>
                           <option value="top">Positionnée en haut</option>
                           <option value="bottom">Positionnée en bas</option>
                        </Form.Select>
                        <Form.Select
                           className="edit-aboutus-modal__image-position"
                           value={imageSize}
                           name="imageSize"
                           onChange={onChange}
                        >
                           <option value="tiny">Très petite</option>
                           <option value="small">Petite</option>
                           <option value="medium">Moyenne</option>
                           <option value="large">Grande</option>
                        </Form.Select>
                        <Form.Select
                           className="edit-aboutus-modal__image-position"
                           value={imageZoom}
                           name="imageZoom"
                           onChange={onChange}
                        >
                           <option value="contain">Non zoomée</option>
                           <option value="cover">Zoomée</option>
                        </Form.Select>
                        <ImageSelector
                           imageUrl={
                              block &&
                              block.imageUrl &&
                              block.imageUrl.length > 0 &&
                              block.imageUrl.find((image) => image.fallback).url
                           }
                           onChange={(newFile) => {
                              setSelectedFile(newFile);
                              setErrorMessage();
                           }}
                           selectorId="input1"
                        />
                     </div>
                  </div>
                  {errorMessage && errorMessage.length > 0 && (
                     <ErrorMessage>{errorMessage}</ErrorMessage>
                  )}
               </Form>
            )}
         </Modal.Body>
         <Modal.Footer>
            {isLoading ? (
               <Loader />
            ) : (
               <>
                  <Button className="btn-rounded" onClick={onClose} variant="secondary">
                     Fermer
                  </Button>

                  {!successMessage && (
                     <Button
                        className="btn-rounded"
                        variant="primary"
                        form="edit-modal-form"
                        type="submit"
                     >
                        Valider
                     </Button>
                  )}
               </>
            )}
         </Modal.Footer>
      </Modal>
   );
}

export default EditAboutUsModal;
