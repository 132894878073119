import { useEffect, useRef, useState } from "react";
import ButtonCard from "../ButtonCard";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function ImageSelector({ imageUrl, onChange, selectorId, isEnabled = true }) {
   const [imageSrc, setImageSrc] = useState(null);
   const inputFile = useRef();
   const inputFile2 = useRef();

   useEffect(() => {
      setImageSrc(imageUrl);
   }, [imageUrl]);

   const changeImage = (e) => {
      const firstFile = e.target.files[0];
      if (firstFile) {
         setImageSrc(URL.createObjectURL(firstFile));
         onChange(firstFile);
      }
   };

   const removeImage = (e) => {
      inputFile.current.value = null;
      inputFile2.current.value = null;
      setImageSrc(null);
      onChange(null);
   };

   return (
      <div className="image-selector">
         <div className="image-selector__add-photo">
            {imageSrc ? <img src={imageSrc} alt="" /> : <ButtonCard title="Ajouter une photo" />}
            <Form.Control
               accept="image/png, image/jpeg, image/webp"
               type="file"
               onChange={changeImage}
               ref={inputFile}
               disabled={!isEnabled}
            />
         </div>
         {imageSrc && (
            <div className="image-selector__actions">
               <label htmlFor={selectorId} className="btn btn-secondary">Modifier</label>
               <Form.Control
               accept="image/png, image/jpeg, image/webp"
               type="file"
               onChange={changeImage}
               ref={inputFile2}
               className="image-selector__input2"
               id={selectorId}
               disabled={!isEnabled}
               />
               <Button variant="secondary" size="sm" onClick={() => removeImage()} disabled={!isEnabled}>
                  <FontAwesomeIcon icon={faTrash} />
               </Button>
            </div>
         )}
      </div>
   );
}

ImageSelector.propTypes = {
   imageUrl: PropTypes.string,
   onChange: PropTypes.func,
   selectorId: PropTypes.string, 
   isEnabled: PropTypes.bool,  
};


export default ImageSelector;
