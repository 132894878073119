import { useCallback, useContext, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { UserContext } from "../../contexts/userContext";
import ErrorBlock from "../../components/ErrorBlock";
import { formatJsonDate, sendDataAPI } from "../../helpers";
import Loader from "../../components/Loader";
import { faMagnifyingGlass, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import AlertBox from "../../components/AlertBox";

function AdminCustomers() {
    const pageTitle = "Clients";
    const { user } = useContext(UserContext);
    const [users, setUsers] = useState(null);
    const [lastUsers, setLastUsers] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isErrorData, setIsErrorData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);


    const loadData = useCallback(async () => {
        if (!user) {
            return;
        }
        const { success, data } = await sendDataAPI('users', "GET", null, true);
        if (success) {
            setLastUsers(data);
        } else {
            setIsErrorData(true);
        }
        setIsLoadingData(false);
    }, [user]);

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!user) {
            return;
        }
        if (searchInput.length === 0) {
            setUsers(null);
            return;
        }
        const query = encodeURI(searchInput);
        const { success, data } = await sendDataAPI(`users/?search=${query}`, "GET", null, true);
        if (success) {
            setUsers(data);
        }
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    if ((user && !user.isAdmin) || user === null) {
        return (
            <PageLayout
                pageTitle={pageTitle}
                className="admin-customers"
                breadCrumbItems={[{ title: pageTitle }]}
            >
                <ErrorBlock code={403} />
            </PageLayout>
        );
    } else if (isErrorData) {
        return (
            <PageLayout
                pageTitle={pageTitle}
                className="admin-customers"
                breadCrumbItems={[{ title: pageTitle }]}
            >
                <h1 className="page-title">{pageTitle}</h1>
                <center>Erreur de chargement des données.</center>
            </PageLayout>
        );
    } else if (isLoadingData) {
        return (
            <PageLayout
                pageTitle={pageTitle}
                className="admin-customers"
                breadCrumbItems={[{ title: pageTitle }]}
            >
                <h1 className="page-title">{pageTitle}</h1>
                <center>
                    <Loader />
                </center>
            </PageLayout>
        );
    }

    return (
        <PageLayout
            pageTitle={pageTitle}
            className="admin-customers"
            breadCrumbItems={[{ title: pageTitle }]}
        >
            <h1 className="page-title">{pageTitle}</h1>
            <Form onSubmit={handleSearch}>
                <Form.Control
                    type="text"
                    placeholder="Rechercher par nom ou numéro"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="dark" type="submit">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
            </Form>
            {users && (
                <div className="white-block">
                    <h2>Résultats de recherche ({users.length})</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Client</th>
                                <th>Date création</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={`user-${index}`}>
                                    <td>{user.id}</td>
                                    <td><strong>{user.lastName} {user.firstName}</strong></td>
                                    <td>{formatJsonDate(user.createdAt, false, true)}{user.status === 0 && <span className="warning-text"><br />Non confirmé</span>}</td>
                                    <td>
                                        <Button size="sm" variant="dark" onClick={() => { setSelectedUser(user); setShowUserDetails(true); }}><FontAwesomeIcon icon={faInfo} /></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {users.length === 0 && <p className="message-text">Aucun résultat</p>}
                </div>
            )}
            <div className="white-block">
                <h2>Derniers clients inscrits</h2>
                <table>
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Client</th>
                            <th>Date création</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lastUsers.map((user, index) => (
                            <tr key={`user-${index}`}>
                                <td>{user.id}</td>
                                <td>
                                    <strong>{user.lastName} {user.firstName}</strong><br />
                                </td>
                                <td>{formatJsonDate(user.createdAt, false, true)}{user.status === 0 && <span className="warning-text"><br />Non confirmé</span>}</td>
                                <td>
                                    <Button size="sm" variant="dark" onClick={() => { setSelectedUser(user); setShowUserDetails(true); }}><FontAwesomeIcon icon={faInfo} /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <AlertBox
                show={showUserDetails}
                size="md" title={selectedUser && (selectedUser.lastName + " " + selectedUser.firstName)}
                text={selectedUser &&
                    <>
                        <div className="two-columns"><span>N° client</span><span>{selectedUser.id}</span></div>
                        <div className="two-columns"><span>E-mail</span><span>{selectedUser.email}</span></div>
                        <div className="two-columns"><span>Téléphone</span><span>{selectedUser.phone}</span></div>
                        <div className="two-columns"><span>Adresse</span><span>{selectedUser.address ?? "-"}</span></div>
                        <div className="two-columns"><span>Code postal</span><span>{selectedUser.postalCode ?? "-"}</span></div>
                        <div className="two-columns"><span>Ville</span><span>{selectedUser.city ?? "-"}</span></div>
                        <div className="two-columns"><span>Inscrit le</span><span>{formatJsonDate(selectedUser.createdAt, false, true)}</span></div>
                        <div className="two-columns"><span>Nb de réservations</span><span>{selectedUser.Orders.length}</span></div>
                        {!selectedUser.acceptMailing && <p className="warning-text">&nbsp;<br />Ne souhaite pas recevoir les offres et démarchage</p>}
                        {selectedUser.acceptMailing && <p className="disabled-text">&nbsp;<br />Souhaite recevoir les offres et démarchage</p>}
                    </>
                }
                onCancel={() => setShowUserDetails(false)} />
        </PageLayout>
    )

}

export default AdminCustomers;
