import { createContext, useState, useCallback } from "react";
import { sendDataAPI } from "../helpers";
import { getCookie } from "../helpers";

const getUser = () => {
   const userId = getCookie("userId");
   const userRole = getCookie("userRole");
   if (userId && userRole) {
      return { id: userId, role: userRole, isAdmin: userRole === 1 };
   }
   return null;
}

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
   const [user, setUser] = useState(() => getUser());
   const [userData, setUserData] = useState();

   const disconnectUser = async () => {
      const { success } = await sendDataAPI(`users/logout`, "POST", null, true);
      if (success) {
         setUser(null);
         setUserData(null);
      }
   };

   const loginUser = async (email, password) => {
      const { success, data } = await sendDataAPI('users/login', "POST", { email: email, password: password }, true);
      if (success) {
         setUser(getUser());
      } else {
         return new Error(data.message);
      }
   };

   const loadUserData = useCallback(async () => {
      try {
         if (!user || !user.id) {
            throw new Error("Aucun utilisateur connecté");
         }
         const { success, data } = await sendDataAPI(`users/${user.id}`, "GET", null, true);
         if (success) {
            setUserData(data);
         } else {
            setUser(null);
            setUserData(null);
         }
      } catch (error) {
         return error;
      }
   }, [user]);

   const deleteUserAccount = useCallback(async () => {
      if (!user || !user.id) {
         return false;
      }
      const { success } = await sendDataAPI(`users/${user.id}`, "DELETE", null, true);
      if (success) {
         await disconnectUser();
         return true;
      } else {
         return false;
      }
   }, [user]);

   const modifyUserAccount = useCallback(async (newData) => {
      if (!user || !user.id) {
         return 400;
      }
      try {
         const { success, code } = await sendDataAPI(`users/${user.id}`, "PUT", newData, true);
         if (success) {
            const newUserData = { ...userData };
            Object.keys(newData).forEach((key) => {
               newUserData[key] = newData[key];
            });
            setUserData(newUserData);
         }
         return code;
      } catch (error) {
         return 500;
      }
   },
      [user, userData],
   );

   return (
      <UserContext.Provider
         value={{
            user,
            userData,
            disconnectUser,
            loginUser,
            loadUserData,
            deleteUserAccount,
            modifyUserAccount,
         }}
      >
         {children}
      </UserContext.Provider>
   );
};
