import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { formatPrice } from "../../helpers";

/**
 * Boutons en ligne permettant une sélection multiple
 * @return JSX.element
 */

function Filter({ title, maxItems, buttons, selection, onSelect, onDeselect }) {

   const [isExpanded, setExpanded] = useState(false);

   function toggleButton(index, isSelected) {
      if (isSelected) onDeselect(index);
      else onSelect(index);
   }

   const finalButtons = useMemo(() => {
      if (!maxItems || isExpanded || buttons.length <= maxItems) {
         return buttons;
      }
      const first = buttons.slice(0, maxItems);
      const selected = buttons.filter((item, index) => index > maxItems - 1 && selection.includes(item.id))
      return first.concat(selected);
   }, [buttons, isExpanded, maxItems, selection]);

   return (
      <div className="filter">
         <span className="filter__title">{title}</span>
         {finalButtons.map((button, index) => (
            <Button
               className="btn-rounded"
               key={`filter-button-${index}`}
               variant={selection.includes(button.id) ? "primary" : "secondary"}
               onClick={() => toggleButton(button.id, selection.includes(button.id))}
            >
               {button.title}
            </Button>
         ))}
         {finalButtons.length < buttons.length && <Link className="filter__show-more" onClick={() => setExpanded(true)}>
            Afficher plus...
         </Link>}
      </div>
   );
}

Filter.propTypes = {
   title: PropTypes.string.isRequired,
   maxItems: PropTypes.number,
   buttons: PropTypes.array.isRequired,
   selection: PropTypes.array.isRequired,
   onSelect: PropTypes.func.isRequired,
   onDeselect: PropTypes.func.isRequired,
};

function PriceFilter({ title, priceMax, priceMaxSelected, onChange }) {
   //const [price, setPrice] = useState(priceMax);

   return (
      <div className="filter">
         <span className="filter__title">{title}</span>
         <div className="two-columns">
            <span>Prix max.</span>
            <span>{formatPrice(priceMaxSelected)}</span>
         </div>
         <Form.Range
            min={0}
            max={priceMax}
            step={0.5}
            value={priceMaxSelected}
            onChange={(e) => {
               //setPrice(e.target.value);
               onChange(e.target.value);
            }}
            id="price-range"
            aria-labelledby="price-range"
         />
      </div>
   );
}

PriceFilter.propTypes = {
   title: PropTypes.string.isRequired,
   priceMax: PropTypes.number.isRequired,
   onChange: PropTypes.func.isRequired,
};

export { Filter, PriceFilter };
