import Header from "../../components/Header";
import CartModal from "../../components/CartModal";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";

function MainLayout({ children, pageTitle, className }) {
   return (
      <>
         <Helmet>
            <title>Ferme du Trèfle Blanc - Produits fermiers bio et locaux près de Nantes</title>
         </Helmet>
         <ScrollToTop />
         <Header />
         <main className={className}>{children}</main>
         <CartModal />
         <Footer />
      </>
   );
}

export default MainLayout;
