import PropTypes from "prop-types";

function Tag({ title, variant = "primary" }) {
    return <span className={`tag tag--${variant}`}>{title}</span>;
}

Tag.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
};

/*Tag.defaultProps = {
    variant: "primary",
};*/

export default Tag;
