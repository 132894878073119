import PageLayout from "../../layouts/PageLayout";
import ErrorBlock from "../../components/ErrorBlock";

/**
 * Composant affichant un message d'erreur 404
 * @return JSX.element
 */
function ErrorPage() {
   const pageTitle = "Erreur 404";
   return (
      <PageLayout
         pageTitle={pageTitle}
         className="error-page"
         breadCrumbItems={[{ title: pageTitle }]}
      >
         <ErrorBlock code={404} />
      </PageLayout>
   );
}

export default ErrorPage;
