import PropTypes from "prop-types";

/**
 * Affiche une barre colorée symbolisant la force d'un mot de passe
 * @return JSX.element
 */

function PasswordStrength({ strength }) {
   const classes = ["empty", "low1", "low2", "medium1", "medium2", "high1", "high2"];
   return (
      <div className="password-strength">
         <p className="password-strength__title">Niveau de sécurité</p>

         <div className="password-strength__container">
            <div className={`password-strength__bar password-strength__bar--${classes[strength]}`}></div>
         </div>
      </div>
   );
}

PasswordStrength.propTypes = {
   strength: PropTypes.number.isRequired,
};

export default PasswordStrength;
