import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { formatJsonDate } from "../../helpers";
import Tag from "../Tag";


/**
 * Carte pour l'affichage de news ou catégories
 * @return JSX.element
 */

function Card({ variant, images, title, date, eventDate, showTime, text, tags, buttonTitle, buttonURL }) {
   const navigate = useNavigate();

   return (
      <article className={`generic-card card ${variant ? "card--" + variant : "card--top"}`}>
         {images && (
            <picture>
               {images
                  .filter((item) => item.thumbnail)
                  .map((image, index) =>
                     image.fallback ? (
                        <img
                           key={`source-${index}`}
                           className="card__image"
                           src={image.url}
                           alt={title}
                           onClick={() => navigate(buttonURL)}
                        />
                     ) : (
                        <source
                           key={`source-${index}`}
                           srcSet={image.url}
                           type={`image/${image.type}`}
                        />
                     ),
                  )}
            </picture>
         )}

         <div className="card__container">
            <h3 className="card__title">
               <Link to={buttonURL}>{title}</Link>
            </h3>
            {(eventDate || tags) &&
               <div className="card__tags">
                  {tags && tags.map((tag, index) => (
                     <Tag key={`tag-${index}`} title={tag} />
                  ))}
                  {eventDate && (
                     <Tag title={formatJsonDate(eventDate, false, showTime)} />
                  )}
               </div>
            }
            {text && <p className="card__text">{text}</p>}
            <div className="card__bottom">
               {date && <p className="card__date">Publié le {formatJsonDate(date, false, false)}</p>}
               {buttonTitle && (
                  <Button
                     onClick={() => navigate(buttonURL)}
                     variant="primary"
                     className="btn-rounded"
                  >
                     {buttonTitle}
                  </Button>
               )}
            </div>
         </div>
      </article>
   );
}

Card.propTypes = {
   variant: PropTypes.string,
   images: PropTypes.array,
   title: PropTypes.string.isRequired,
   date: PropTypes.string,
   showTime: PropTypes.bool,
   text: PropTypes.string,
   tags: PropTypes.array,
   buttonTitle: PropTypes.string,
   buttonURL: PropTypes.string,
};

export default Card;
