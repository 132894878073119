import PageLayout from "../../layouts/PageLayout";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useFetch } from "../../hooks/useFetch";
import { SettingsContext } from "../../contexts/settingsContext";
import Loader from "../../components/Loader";
import ReactMarkdown from "react-markdown";
import Card from "../../components/Card";
import ButtonCard from "../../components/ButtonCard";
import { UserContext } from "../../contexts/userContext";
import EditAboutUsModal from "../../components/EditAboutUsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ErrorBlock from "../../components/ErrorBlock";
import AlertBox from "../../components/AlertBox";
import { sendDataAPI } from "../../helpers";

function AboutUs() {
   const pageTitle = "Qui sommes-nous";
   const { aboutPageName } = useParams();
   const { aboutPages } = useContext(SettingsContext);
   const { user } = useContext(UserContext);
   const aboutPage = aboutPages.find((page) => page.url === `/qui-sommes-nous/${aboutPageName}`);

   const url = aboutPage && `abouts/${aboutPage.id}`;
   const [data, isLoading, error, reloadData] = useFetch(url);
   const [showEditModal, setShowEditModal] = useState(false);
   const [editBlock, setEditBlock] = useState();
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [deleteBlock, setDeleteBlock] = useState();

   useEffect(() => {
      if (editBlock) {
         setShowEditModal(true);
      }
   }, [editBlock]);

   useEffect(() => {
      if (deleteBlock) {
         setShowDeleteModal(true);
      } else {
         setShowDeleteModal(false);
      }
   }, [deleteBlock]);

   useEffect(() => {
      window.scroll(0, 0);
   }, [aboutPageName]);

   if (!aboutPageName) {
      return (
         <PageLayout
            pageTitle={pageTitle}
            className="about-us"
            breadCrumbItems={[{ title: pageTitle }]}
         >
            <h1 className="page-title">{pageTitle}</h1>
            <div className="about-us__pages">
               {aboutPages.map((item, index) => (
                  <Card
                     key={`item-${index}`}
                     title={item.title}
                     images={item.imageUrl}
                     buttonTitle="Afficher"
                     buttonURL={item.url}
                  />
               ))}
            </div>
         </PageLayout>
      );
   }

   if (isLoading || !data) {
      return (
         <PageLayout pageTitle="Chargement..." className="about-us">
            <Loader variant="full" />
         </PageLayout>
      );
   } else if (error) {
      return (
         <PageLayout
            pageTitle="Erreur 404"
            className="about-us"
            breadCrumbItems={[{ title: "Qui sommes-nous", url: "/qui-sommes-nous" }]}
         >
            <ErrorBlock code={404} />
         </PageLayout>
      );
   }

   const greatestPosition = data.AboutBlocks.reduce((accumulator, current) => {
      return accumulator.order > current.order ? accumulator : current;
   });

   const swapBlocks = async (block1, block2) => {
      if (!block1 || !block2) {
         return;
      }
      const { success } = await sendDataAPI(
         `abouts/${data.id}/blocks`,
         "PUT",
         [
            { id: block1.id, order: block2.order },
            { id: block2.id, order: block1.order },
         ],
         true,
      );
      if (success) {
         reloadData();
      }
   };

   const processDeleteBlock = async () => {
      if (!deleteBlock) return;
      const { success } = await sendDataAPI(
         `abouts/${deleteBlock.AboutId}/blocks/${deleteBlock.id}`,
         "DELETE",
         null,
         true,
      );
      if (success) {
         setDeleteBlock();
         reloadData();
      }
   };

   return (
      <PageLayout
         pageTitle={data.title}
         className="about-us"
         breadCrumbItems={[
            { title: "Qui sommes-nous", url: "/qui-sommes-nous" },
            { title: data.title },
         ]}
      >
         <h1 className="page-title">{data.title}</h1>
         {user?.isAdmin && (
            <ButtonCard
               title="Ajouter un nouveau block"
               variant="left"
               onClick={() => {
                  setEditBlock({ newBlock: true });
               }}
            />
         )}
         {data.AboutBlocks.map((block, index, array) => (
            <div className="two-columns about-us__block-container" key={`about-block-${index}`}>
               <div
                  className={`markdown white-block about-us__block ${block.imageUrl.length === 0 ? "about-us__block--no-image" : ""
                     } about-us__block--${block.imagePosition}`}
               >
                  {block.imageUrl.length > 0 && (
                     <div
                        className={`about-us__image about-us__image--${block.imagePosition} about-us__image--${block.imageSize} about-us__image--${block.imageZoom}`}
                     >
                        <picture>
                           {block.imageUrl
                              .filter((item) => !item.thumbnail)
                              .map((image, index) =>
                                 image.fallback ? (
                                    <img key={`source-${index}`} src={image.url} alt="" />
                                 ) : (
                                    <source
                                       key={`source-${index}`}
                                       srcSet={image.url}
                                       type={`image/${image.type}`}
                                    />
                                 ),
                              )}
                        </picture>
                     </div>
                  )}
                  <div>
                     <ReactMarkdown>{block.text}</ReactMarkdown>
                  </div>
               </div>
               {user?.isAdmin && (
                  <div className="about-us__edit-buttons">
                     <div
                        className="edit-button"
                        onClick={() => {
                           setEditBlock(block);
                        }}
                     >
                        <FontAwesomeIcon icon={faPenToSquare} />
                     </div>
                     <div
                        className="edit-button"
                        onClick={() => {
                           setDeleteBlock(block);
                        }}
                     >
                        <FontAwesomeIcon icon={faTrash} />
                     </div>
                     <div
                        className={`edit-button ${index === 0 ? "edit-button--disabled" : ""}`}
                        onClick={() => {
                           if (index !== 0) swapBlocks(block, array[index - 1]);
                        }}
                     >
                        <FontAwesomeIcon icon={faArrowUp} />
                     </div>
                     <div
                        className={`edit-button ${index === array.length - 1 ? "edit-button--disabled" : ""
                           }`}
                        onClick={() => {
                           if (index !== array.length - 1) swapBlocks(block, array[index + 1]);
                        }}
                     >
                        <FontAwesomeIcon icon={faArrowDown} />
                     </div>
                  </div>
               )}
            </div>
         ))}
         {user?.isAdmin && (
            <>
               <AlertBox
                  title="Suppression d'un block"
                  text="Êtes-vous sûr de vouloir supprimer le block ? Attention : cette action est définitive."
                  show={showDeleteModal}
                  onCancel={() => setDeleteBlock()}
                  onValidate={() => processDeleteBlock()}
               />
               <EditAboutUsModal
                  show={showEditModal}
                  onClose={() => {
                     setShowEditModal(false);
                     reloadData();
                  }}
                  block={editBlock}
                  pageId={data.id}
                  greatestPosition={greatestPosition ? greatestPosition.order : 0}
               />
            </>
         )}
      </PageLayout>
   );
}

export default AboutUs;
