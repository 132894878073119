import PageLayout from "../../layouts/PageLayout";
import { useContext } from "react";
import { SettingsContext } from "../../contexts/settingsContext";
import Card from "../../components/Card";

function ProductCategories() {
   const { categories } = useContext(SettingsContext);

   return (
      <PageLayout
         pageTitle="Produits"
         className="products"
         breadCrumbItems={[{ title: "Produits" }]}
      >
         <h1 className="page-title">Nos produits fermiers bio</h1>
         <div className="products__categories">
            {categories.map((category, index) => (
               <Card
                  key={`category-${index}`}
                  title={category.title}
                  images={category.imageUrl}
                  text={category.description}
                  buttonTitle="Afficher"
                  buttonURL={category.url}
               />
            ))}
         </div>
      </PageLayout>
   );
}

export default ProductCategories;
