import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import ProductCard from "../../components/ProductCard";
import { formatJsonDate, formatNumberProducts } from "../../helpers";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Filter, PriceFilter } from "../../components/Filter";
import { SettingsContext } from "../../contexts/settingsContext";
import { UserContext } from "../../contexts/userContext";
import PageLayout from "../../layouts/PageLayout";
import ButtonCard from "../../components/ButtonCard";
import EditProductModal from "../../components/EditProductModal";
import ErrorBlock from "../../components/ErrorBlock";
import Tag from "../../components/Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCow, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import { ProductContext } from "../../contexts/productContext";
import { sendDataAPI } from "../../helpers";
import CloseButton from "../../components/CloseButton";

function Products() {
   const { categoryName } = useParams();
   const { categories, loadSettings, nextMeatSale } = useContext(SettingsContext);
   const {
      error,
      isLoading,
      filteredProducts,
      setCategorySelected,
      subcategoriesSelected,
      ingredientsSelected,
      setSubcategoriesSelected,
      setIngredientsSelected,
      ingredients,
      subcategories,
      priceMax,
      sortKey,
      setSortKey,
      priceMaxSelected,
      setPriceMaxSelected,
      productsSelected,
      setProductsSelected,
      filterNumber,
      resetFilter,
      loadProductsData,
      addProductToSelection,
      removeProductFromSelection,
   } = useContext(ProductContext);
   const { user } = useContext(UserContext);
   const [showFiltersModal, setShowFiltersModal] = useState(false);
   const [isEditingCategory, setIsEditingCategory] = useState(false);
   const [categoryDescription, setCategoryDescription] = useState("");
   const [showEditModal, setShowEditModal] = useState(false);
   const [editProduct, setEditProduct] = useState();

   const category = useMemo(() => {
      if (categories.length === 0) {
         return { id: -1 };
      }
      return categories.find((category) => category.url === `/produits/${categoryName}`);
   }, [categories, categoryName]);

   const modifyProducts = useCallback(
      async (field, value) => {
         if (productsSelected.length === 0) {
            return;
         }
         try {
            const newValues = {};
            newValues[field] = value;
            const { success } = await sendDataAPI('products', "PUT", { newValues: newValues, productIds: productsSelected }, true);
            if (success) {
               loadProductsData();
               setProductsSelected([]);
            } else {
               //console.log("produits non modifiés");
            }
         } catch (err) {
            //console.log(err);
         }
      },
      [loadProductsData, productsSelected, setProductsSelected],
   );

   // Permet le chargement initial des données
   useEffect(() => {
      if (categoryName) {
         const eligible = categories.find(
            (category) => category.url === `/produits/${categoryName}`,
         );
         setCategorySelected(eligible ?? {});
         window.scroll(0, 0);
      }
   }, [categoryName, categories, setCategorySelected]);

   useEffect(() => {
      document.body.style.overflowY = showFiltersModal ? "hidden" : "auto";
   }, [showFiltersModal]);


   const handleSubmitDescription = useCallback(
      async (e) => {
         e.preventDefault();
         if (!user) return;
         try {
            const { success } = await sendDataAPI(`categories/${category.id}`, "PUT", { description: categoryDescription }, true);
            if (success) {
               setIsEditingCategory(false);
               loadSettings(["categories"]);
            }
         } catch (error) { }
      },
      [category, categoryDescription, user, loadSettings],
   );



   if (error) {
      return (
         <PageLayout
            pageTitle="Erreur 404"
            className="products"
            breadCrumbItems={[{ title: "Produits", url: "/produits" }, { title: categoryName }]}
         >
            <ErrorBlock code={404} />
         </PageLayout>
      );
   }

   return (
      <PageLayout
         pageTitle={category.title}
         className="products"
         breadCrumbItems={
            category && [{ title: "Produits", url: "/produits" }, { title: category.title }]
         }
      >
         <div className="products__container ">
            <aside
               className={`products__aside ${showFiltersModal ? "products__aside--visible" : "products__aside--hidden"
                  }`}
            >
               <CloseButton onClick={() => setShowFiltersModal(false)} />
               <h2 className="products__aside-title">Filtres</h2>
               <Filter
                  title="Catégories"
                  buttons={subcategories}
                  selection={subcategoriesSelected}
                  onSelect={(selectedIndex) => {
                     if (!subcategoriesSelected.includes(selectedIndex)) {
                        setSubcategoriesSelected((prevState) => [...prevState, selectedIndex]);
                     }
                  }}
                  onDeselect={(deselectedIndex) => {
                     if (subcategoriesSelected.includes(deselectedIndex)) {
                        setSubcategoriesSelected((prevState) =>
                           prevState.filter((item) => item !== deselectedIndex),
                        );
                     }
                  }}
               />
               <Filter
                  title="Ingrédients"
                  maxItems={10}
                  buttons={ingredients}
                  selection={ingredientsSelected}
                  onSelect={(selectedIndex) => {
                     if (!ingredientsSelected.includes(selectedIndex)) {
                        setIngredientsSelected((prevState) => [...prevState, selectedIndex]);
                     }
                  }}
                  onDeselect={(deselectedIndex) => {
                     if (ingredientsSelected.includes(deselectedIndex)) {
                        setIngredientsSelected((prevState) =>
                           prevState.filter((item) => item !== deselectedIndex),
                        );
                     }
                  }}
               />
               <PriceFilter
                  title="Prix"
                  priceMax={priceMax}
                  priceMaxSelected={priceMaxSelected}
                  onChange={(price) => setPriceMaxSelected(price)}
               />
               <Link className="products__reset-filter" onClick={() => resetFilter()}>
                  Réinitialiser
               </Link>
            </aside>
            <section className="products__main">
               <div className="products__title">
                  <h1>{category && category.title}</h1>
                  {category && category.type === "meat" && nextMeatSale && (
                     <Tag
                        variant="danger"
                        title={`Retrait à partir du ${formatJsonDate(nextMeatSale, true, true)}`}
                     />
                  )}
               </div>
               <div className="products__description two-columns">
                  {user?.isAdmin && isEditingCategory ? (
                     <Form className="products__edit" onSubmit={handleSubmitDescription}>
                        <Form.Control
                           as="textarea"
                           rows={2}
                           value={categoryDescription}
                           onChange={(e) => setCategoryDescription(e.target.value)}
                        />
                        <Button
                           size="sm"
                           variant="secondary"
                           onClick={() => setIsEditingCategory(false)}
                        >
                           Annuler
                        </Button>
                        <Button size="sm" type="submit">
                           Enregistrer
                        </Button>
                     </Form>
                  ) : (
                     <p>{category && category.description}</p>
                  )}
                  {user?.isAdmin && !isEditingCategory && (
                     <div
                        className="edit-button"
                        onClick={() => {
                           setCategoryDescription(category.description);
                           setIsEditingCategory(true);
                        }}
                     >
                        <FontAwesomeIcon icon={faPenToSquare} />
                     </div>
                  )}
               </div>
               <div className="products__grid-header">
                  <Button
                     className="products__filters-toggle btn-rounded"
                     onClick={() => setShowFiltersModal(true)}
                     size="sm"
                  >
                     Filtrer {filterNumber > 0 ? `(${filterNumber})` : ""}
                  </Button>
                  {isLoading && <Loader />}
                  {!isLoading && (
                     <p className="products__quantity">
                        {user?.isAdmin &&
                           formatNumberProducts(productsSelected, null, "séléctionné") + " / "}
                        {formatNumberProducts(filteredProducts, "produit", null)}
                     </p>
                  )}
                  <DropdownButton
                     align="end"
                     size="sm"
                     variant="primary"
                     id="dropdown-sort"
                     title="Trier par"
                     onSelect={(item) => setSortKey(item)}
                  >
                     <Dropdown.Item active={sortKey === "highlighted"} eventKey="highlighted">
                        Nouveautés
                     </Dropdown.Item>
                     <Dropdown.Item active={sortKey === "title"} eventKey="title">
                        Nom
                     </Dropdown.Item>
                     <Dropdown.Item active={sortKey === "price"} eventKey="price">
                        Prix
                     </Dropdown.Item>
                     <Dropdown.Item
                        active={sortKey === "measurementValue"}
                        eventKey="measurementValue"
                     >
                        Poids
                     </Dropdown.Item>

                     {user?.isAdmin && (
                        <>
                           <Dropdown.Divider />
                           <Dropdown.Item active={sortKey === "createdAt"} eventKey="createdAt">
                              Date d'ajout
                           </Dropdown.Item>
                           <Dropdown.Item active={sortKey === "enabled"} eventKey="enabled">
                              Activé
                           </Dropdown.Item>
                        </>
                     )}
                  </DropdownButton>
               </div>
               {user?.isAdmin && (
                  <div className="products__selection">
                     <DropdownButton
                        id="dropdown-actions"
                        title="Produits sélectionnés..."
                        size="sm"
                        variant="dark"
                     >
                        <Dropdown.Item onClick={() => modifyProducts("highlighted", true)}>
                           Mettre en avant
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => modifyProducts("highlighted", false)}>
                           Ne plus mettre en avant
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => modifyProducts("enabled", true)}>
                           Activer
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => modifyProducts("enabled", false)}>
                           Désactiver
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => modifyProducts("onlyInStore", true)}>
                           Disponible uniquement en magasin
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => modifyProducts("onlyInStore", false)}>
                           Réservable en ligne
                        </Dropdown.Item>
                     </DropdownButton>
                  </div>
               )}
               {filteredProducts && filteredProducts.length === 0 && !user?.isAdmin && (
                  <p className="products__cow"><FontAwesomeIcon icon={faCow} /></p>
               )}
               {!isLoading && <div className="products__grid">
                  {user?.isAdmin && (
                     <ButtonCard
                        title="Ajouter un nouveau produit"
                        onClick={() => {
                           setEditProduct();
                           setShowEditModal(true);
                        }}
                     />
                  )}
                  {filteredProducts &&
                     filteredProducts.map((product, index) => (
                        <ProductCard
                           key={`product-${index}`}
                           product={product}
                           selected={productsSelected.includes(product.id)}
                           onSelect={(productId) => addProductToSelection(productId)}
                           onDeselect={(productId) => removeProductFromSelection(productId)}
                           onEdit={(product) => {
                              setEditProduct(product);
                              setShowEditModal(true);
                           }}
                        />
                     ))}
               </div>}
            </section>
         </div>
         <EditProductModal
            show={showEditModal}
            onClose={() => {
               setShowEditModal(false);
               loadProductsData();
               loadSettings(["categories", "allergens", "ingredients"]);
            }}
            onRefresh={() => { }}
            product={editProduct}
            category={category}
         />
      </PageLayout>
   );
}

export default Products;
