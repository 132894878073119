import PageLayout from "../../layouts/PageLayout";
import { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import PasswordStrength from "../../components/PasswordStrength";
import ErrorMessage from "../../components/ErrorMessage";
import UserAccountModal from "../../components/UserAccountModal";

const initialReinitInfo = {
   newPassword: "",
   confirmPassword: "",
};

function AccountReinitialisation() {
   const pageTitle = "Réinitialiser mon mot de passe";
   const [{ newPassword, confirmPassword }, setState] = useState(initialReinitInfo);
   const [errorPasswordMessage, setErrorPasswordMessage] = useState();
   const [statusCode, setStatusCode] = useState();
   const [passwordStrength, setPasswordStrength] = useState(0);
   const { token } = useParams();
   const navigate = useNavigate();

   const onChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
   };

   const handleSubmit = useCallback(
      async (e) => {
         e.preventDefault();

         if (newPassword !== confirmPassword) {
            setErrorPasswordMessage("Les nouveaux mots de passe ne correspondent pas.");
            return;
         }

         if (passwordStrength <= 2) {
            setErrorPasswordMessage(
               "Veuillez choisir un mot de passe plus complexe (en ajoutant des majuscules, des caractères spéciaux, des nombres...).",
            );
            return;
         }

         try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/password`, {
               method: "PUT",
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json;charset=utf-8",
               },
               body: JSON.stringify({ newPassword }),
            });
            setStatusCode(response.status);
         } catch {
            setStatusCode(500);
         }
      },
      [token, newPassword, confirmPassword, passwordStrength],
   );

   useEffect(() => {
      // Met à jour l'indcateur de mot de passe
      let strength = 0;
      if (/[0-9]+/.test(newPassword)) {
         strength += 1;
      }
      if (/[A-Z]+/.test(newPassword)) {
         strength += 1;
      }
      if (/[!@£$%^&*()~<>?]+/.test(newPassword)) {
         strength += 1;
      }
      if (newPassword.length >= 1) {
         strength += 1;
      }
      if (newPassword.length >= 8) {
         strength += 1;
      }
      if (newPassword.length >= 20) {
         strength += 1;
      }
      setPasswordStrength(strength);
   }, [newPassword]);

   const checkPassword = (e) => {
      // Empêche la saisie d'un espace vide
      if (/\s/.test(e.target.value) === false) {
         onChange(e);
      }
      setErrorPasswordMessage("");
   };

   return (
      <PageLayout
         pageTitle={pageTitle}
         className="account-reinitialisation"
         breadCrumbItems={[{ title: "Compte", url: "/compte" }, { title: pageTitle }]}
      >
         <h1 className="page-title">{pageTitle}</h1>
         <div className="white-block account-reinitialisation__content">
            {statusCode && statusCode === 200 && (
               <>
                  <p>Mot de passe bien réinitialisé</p>
                  <Button className="btn-rounded" onClick={() => navigate("/compte")}>
                     Se connecter
                  </Button>
               </>
            )}
            {statusCode && statusCode === 401 && (
               <>
                  <p>Le lien de reinitialisation n'est plus valide.</p>
                  <UserAccountModal forgottenMode={true} />
               </>
            )}
            {statusCode && statusCode !== 401 && statusCode !== 200 && (
               <>
                  <p>Une erreur s'est produite. Merci de réessayer ultérieurement.</p>
               </>
            )}
            {!statusCode && (
               <Form onSubmit={handleSubmit}>
                  <div className="two-columns-grid">
                     <span>Nouveau mot de passe</span>
                     <Form.Control
                        type="password"
                        value={newPassword}
                        name="newPassword"
                        onChange={checkPassword}
                        autoComplete="new-password"
                        size="sm"
                        required
                     />
                     <span>Confirmer le mot de passe</span>
                     <Form.Control
                        type="password"
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={checkPassword}
                        autoComplete="new-password"
                        size="sm"
                        required
                     />
                  </div>
                  <PasswordStrength strength={passwordStrength} />
                  {errorPasswordMessage && <ErrorMessage>{errorPasswordMessage}</ErrorMessage>}
                  <Button className="btn-rounded" type="submit">
                     Valider
                  </Button>
               </Form>
            )}
         </div>
      </PageLayout>
   );
}

export default AccountReinitialisation;
