import { useContext } from "react";
import { NewsContext } from "../../contexts/newsContext";
import Loader from "../Loader";
import Card from "../Card";
import { normalizeName } from "../../helpers";
import removeMarkdown from "remove-markdown";

function LastNews() {
   const { news, isError, isLoading } = useContext(NewsContext);
   return (
      <section className="lastnews">
         <img
            className="lastnews__logo"
            src="/assets/logos/trefle.png"
            srcSet="/assets/logos/trefle@2x.png 2x"
            alt=""
         />
         <div className="lastnews__container">
            <h2 className="lastnews__title">Dernières actualités</h2>
            {isLoading && <Loader variant="full" />}
            {isError && <p>Erreur de chargement</p>}
            {!isLoading && !isError && news && (

               <div className="lastnews__articles page-container">
                  {news.slice(0, 3).map((oneNew, index) => (
                     <Card
                        eventDate={oneNew.eventDate}
                        showTime={oneNew.showTime}
                        images={oneNew.imageUrl}
                        title={oneNew.title}
                        text={removeMarkdown(oneNew.text)}
                        buttonTitle="Lire la suite"
                        buttonURL={`/actualites/${normalizeName(oneNew.title)}-${oneNew.id}`}
                        key={`new - ${index}`}
                     />
                  ))}
               </div>
            )}
         </div>
      </section>
   );
}

export default LastNews;
