import ReactMarkdown from "react-markdown";
import { SettingsContext } from "../../contexts/settingsContext";
import { useContext } from "react";

/**
 * Contenu des conditions générales au format markdown
 * @return JSX.element
 */

function PolicyContent() {
   const { settings } = useContext(SettingsContext);

   return (
      <div className="markdown" style={{ "textAlign": "left" }}>
         <ReactMarkdown>{settings.policy}</ReactMarkdown>
      </div>
   );
}

export default PolicyContent;
