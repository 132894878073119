import { useEffect } from "react";

import mainTheme from "./theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ToolbarPlugin from "./Plugins/ToolbarPlugin";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import {
   $convertFromMarkdownString,
   $convertToMarkdownString,
   TRANSFORMERS,
} from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

function OnChangePlugin({ onChange }) {
   const [editor] = useLexicalComposerContext();
   useEffect(() => {
      return editor.registerUpdateListener(({ editorState }) => {
         onChange(editorState);
      });
   }, [editor, onChange]);
}

function MarkdownEditor({ className, markdown, onChange, headings = ["h1", "h2", "h3"] }) {
   const onContentChange = (editorState) => {
      editorState.read(() => {
         const markdown = $convertToMarkdownString(TRANSFORMERS);
         onChange(markdown);
      });
   };

   const editorConfig = {
      // The editor theme
      theme: mainTheme,
      editorState: () => $convertFromMarkdownString(markdown, TRANSFORMERS), // Handling of errors during update
      onError(error) {
         throw error;
      },
      // Any custom nodes go here
      nodes: [
         HeadingNode,
         ListNode,
         ListItemNode,
         QuoteNode,
         TableNode,
         TableCellNode,
         TableRowNode,
         AutoLinkNode,
         LinkNode,
      ],
   };

   return (
      <div className={`markdown-editor ${className ?? ""}`}>
         <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
               <ToolbarPlugin headings={headings} />
               <div className="editor-inner">
                  <RichTextPlugin contentEditable={<ContentEditable className="editor-input" />} />
                  <HistoryPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <OnChangePlugin onChange={onContentChange} />
               </div>
            </div>
         </LexicalComposer>
      </div>
   );
}

export default MarkdownEditor;
